export type SeoDeafultType = {
  title: string
  description: string
  keywords: string
  imgUrl?: string
}
export const SEO_DEFAULTS: { [key: string]: SeoDeafultType } = {
  home: {
    title: 'Home',
    description: `Home page of the Maybelline Womens Lacrosse League with the best women's lacrosse players in the world.`,
    keywords: 'home',
  },
  schedule: {
    title: 'Schedule',
    description: 'View the Maybelline Womens Lacrosse League schedule.',
    keywords: 'schedule, tickets',
  },
  standings: {
    title: 'Standings',
    description: 'View the Maybelline Womens Lacrosse League standings and records',
    keywords: 'standings, records',
  },
  team: {
    title: 'Team Profile',
    description: 'View team profile, stats, and media.',
    keywords: 'stats, team profile',
  },
  roster: {
    title: 'Roster',
    description: 'View the Maybelline Womens Lacrosse League team roster.',
    keywords: 'roster',
  },
  player: {
    title: 'Player Profile',
    description: 'View player profile, stats, and media.',
    keywords: 'stats, player profile',
  },
}
