import React from 'react'
import { NAV_ITEMS } from '../../../Constants'
import { IconButton } from '../../Icons'
import { AccountCircleOutlined, KeyboardArrowDownIcon } from '../../LazyComponents'
import { colors } from '../../consts'
import { NavDropdownBtn } from '../../Buttons/NavDropdownBtn'
import { NavButtonCon } from './styles'
import { NAV_ACTION_ITEMS } from '../../../Constants/nav'
import { DropdownBtnInner } from '../../Buttons/styles'

interface DesktopNavProps {
  onTeamsClick: (event: React.MouseEvent<HTMLElement>) => void
  onNavigate: (path: string) => void
}

export const DesktopNav: React.FC<DesktopNavProps> = ({ onTeamsClick, onNavigate }) => {

  return (
    <>
      <div className="leftCol">
        <NavButtonCon withIcon={true}>
          <NavDropdownBtn
            aria-controls="teams-menu"
            aria-haspopup="true"
            ariaLabel="Teams Nav Dropdown"
            handleClick={onTeamsClick}
          >
            <DropdownBtnInner withIcon={true}>
              <p>Teams</p>
              <div className="endIcon">
                <KeyboardArrowDownIcon sx={{ color: colors.white, fontSize: 20 }} />
              </div>
            </DropdownBtnInner>
          </NavDropdownBtn>
        </NavButtonCon>
        {NAV_ITEMS.map((item) => (
          <NavButtonCon
            key={item.path}
            onClick={() => onNavigate(item.path)}
            withIcon={false}
          >
            <button>{item.label}</button>
          </NavButtonCon>
        ))}
      </div>
      <div className="rightCol">
        <div className="navKeyActions">
          {NAV_ACTION_ITEMS.map((item) => (
            <NavButtonCon
              key={item.path}
              onClick={() => onNavigate(item.path)}
              withIcon={false}
            >
              <button>{item.label}</button>
            </NavButtonCon>
          ))}
        </div>
        <IconButton color="inherit" onClick={() => onNavigate('/login')} sx={{ ml: 1 }}>
          <AccountCircleOutlined
            color="inherit"
            style={{ color: 'fff', fontSize: 40 }}
          />
        </IconButton>
      </div>
    </>
  )
}
