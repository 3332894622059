import { WLL_LINKS } from "../../../Config/config"

export type FooterSection = {
  title: string
  items: {
    label: string
    link: string
  }[]
}

export const footerSections: FooterSection[] = [
  {
    title: 'RESOURCES',
    items: [
      { label: 'Tickets', link: '/schedule' },
      { label: 'Shop', link: WLL_LINKS.shop },
      { label: 'Media Credentials', link: 'https://docs.google.com/forms/d/e/1FAIpQLSdMszHl0YIWOpgphfxTjI0ri-Ji167IpyhgLEOH9IC3ylGLRw/viewform?usp=sf_link' },
      { label: 'Contact Us', link: 'https://premierlacrosseleague.com/contact-page/' },
    ],
  },
  {
    title: 'LEGAL',
    items: [
      { label: 'Privacy Policy', link: 'https://premierlacrosseleague.com/privacy-policy/' },
      { label: 'Terms of Service', link: 'https://premierlacrosseleague.com/terms-of-service/' },
      { label: 'Ticket Terms & Conditions', link: 'https://premierlacrosseleague.com/ticket-terms-conditions/' },
      { label: 'Security Policy', link: 'https://premierlacrosseleague.com/security-policy/' },
      { label: 'State-Specific Rights & Disclosures', link: 'https://premierlacrosseleague.com/state-specific-rights-and-disclosures' },
      { label: 'Exclusive Content License & Submission Policy', link: 'https://premierlacrosseleague.com/content-license-submission-policy/' },
      { label: 'Do Not Share My Personal Information', link: 'https://pllform.typeform.com/to/whwXLT40?typeform-source=premierlacrosseleague.com' },
    ],
  },
]
