import React, { createContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { FeedItem, fetchHighlightsBySlug, getFeedBySlug } from '../../Api'
import { useVideoPlaylistUrlParams } from '../utils'
import { useQuery } from '../../Utils'

type VideoGroupContextType = {
  getVideosWithTag: (tag: string) => void
  onSelectVideo: (mediaId: string) => void
  mediaId: string | null
  mainVideo: FeedItem | null
  loading: boolean
  videoGroup: FeedItem[]
  videoIndex: number
  additionalVideos: FeedItem[]
  tag: string | null
}

export const VideoGroupContext = createContext<VideoGroupContextType | null>(null)

export const VideoGroupProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const navigate = useNavigate()
  let queryParams = useQuery()
  const mediaIdQuery = queryParams && queryParams.get('mediaId')
  const vidGroupParams = useVideoPlaylistUrlParams()
  const tag = vidGroupParams?.tagId
  const eventSlug = vidGroupParams?.eventSlug
  const [mediaId, setMediaId] = useState<string | null>(mediaIdQuery)
  const [videoGroup, setVideoGroup] = useState<FeedItem[]>([])
  const [mainVideo, setMainVideo] = useState<FeedItem | null>(null)
  const [additionalVideos, setAdditionalVideos] = useState<FeedItem[]>([])
  const [videoIndex, setVideoIndex] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (tag) {
      getVideosWithTag(tag)
    }
    if (eventSlug) {
      getHighlightVideos(eventSlug)
    }
  }, [tag, eventSlug])

  const getVideosWithTag = async (tag: string) => {
    const videosRes = await getFeedBySlug({
      tag,
      limit: 12,
      contentType: 'video',
    })
    if (videosRes && videosRes.length > 0) {
      setVideoGroup(videosRes)
      setupVideoData(videosRes)
    }
  }

  const getHighlightVideos = async (eventSlug: string) => {
    const videosRes = await fetchHighlightsBySlug(eventSlug)
    if (videosRes && videosRes.length > 0) {
      setVideoGroup(videosRes)
      setupVideoData(videosRes)
    }
  }

  const setupVideoData = (vidList: FeedItem[]) => {
    let mainVid: FeedItem | null = null
    if (mediaIdQuery) {
      const mid = findVideoIndex(vidList, mediaIdQuery)
      mainVid = vidList[mid]
      setMainVideo(mainVid)
      setupAdditionalPlaylist(vidList, mediaIdQuery)
      setLoading(false)
      return
    }
    mainVid = vidList[0]
    setMediaId(mainVid?.mediaId)
    setupAdditionalPlaylist(vidList, mainVid?.mediaId)
    setMainVideo(mainVid)
    setLoading(false)
    return
  }

  const setupAdditionalPlaylist = (videos: FeedItem[], mediaId: string | null) => {
    setLoading(true)
    const vids = [...videos]
    if (!mediaId) {
      setAdditionalVideos(vids)
      setLoading(false)
      return
    }
    let vidIdx = vids.findIndex((v) => v.mediaId === mediaId)
    let mainVideo = vids.find((v) => v.mediaId === mediaId)
    let addVideos = vids.filter((vid) => vid.mediaId !== mainVideo?.mediaId)
    if (addVideos.length > 0) {
      setAdditionalVideos(addVideos)
    }
    if (vidIdx !== -1) {
      setVideoIndex(vidIdx)
    }
    setLoading(false)
  }

  const onSelectVideo = (mediaId: string) => {
    if (tag) {
      navigate(`${window.location.origin}/videos/tag/${tag}?mediaId=${mediaId}`, {
        replace: true,
      })
    } else {
      navigate(`${window.location.origin}/videos/${mediaId}`, { replace: true })
    }
  }

  const findVideoIndex = (playlist: FeedItem[], mediaId: string) => {
    let vidIdx = playlist.findIndex((v) => v.mediaId === mediaId)
    if (vidIdx !== -1) {
      return vidIdx
    }
    return 0
  }

  return (
    <VideoGroupContext.Provider
      value={{
        loading,
        mediaId,
        mainVideo,
        videoGroup,
        videoIndex,
        additionalVideos,
        tag,
        getVideosWithTag,
        onSelectVideo,
      }}
    >
      {children}
    </VideoGroupContext.Provider>
  )
}
