import {
  getGameTime,
  getGameTimeLocal,
  getShortDate,
  timeStampToDate,
} from '../formatting'
import {
  PLL_LINKS,
  BROADCAST_IMG,
  REVERSE_BROADCAST_IMG,
  WLL_LINKS,
} from '../../Config/config'
import { isChampSeriesLive, STATS_YEAR } from '../../Config/League/league.config'
import type { EventGQL, GameLog, PlayerData } from '../../Api'
import { createTicketUTM } from '../../Routing'
type EventWeekParams = {
  week: string
  events: EventGQL[]
}
export type WeekTicketData = {
  week: string
  tickets: string
  dayTickets: { date: string; link: string }[]
  suitesId: string
  waitlistUrl: string
  waitlist: boolean
}

export type ClockTime = {
  period: string
  clockTime: string
}

export const allAddEventsId = 'ZO599017'

const GamePeriodMap: { [key: string]: string } = {
  '1': '1st',
  '2': '2nd',
  '3': '3rd',
  '4': '4th',
}

type DayTicketsType = {
  date: string
  ticketUrl: string
  ticketId: string
}

/**
 *
 * Check if the season has ended
 */
export const checkSeasonStatus = (events: EventGQL[]) => {
  const firstEventStatus = events[0].eventStatus
  const finalEventStatus = events[events.length - 1].eventStatus
  if (firstEventStatus === 0) {
    // Season has not started yet
    return 0
  }
  if (finalEventStatus > 1) {
    // Season has ended
    return 2
  }
  // Season is in progress
  return 1
}

/**
 *
 * Check if the season is in the postseason or not
 */
export const isPostseason = (events: EventGQL[]) => {
  if (events.length < 1) return false
  const finalEvent = events[events.length - 1]
  if (finalEvent.seasonSegment === 'post' && finalEvent.eventStatus > 1) {
    // Season has ended, consider it postseason
    const twoWeeksInSeconds = 1209600
    const now = new Date().getTime() / 1000
    if (now > twoWeeksInSeconds) {
      //Season ended, but past time to consider postseason
      return false
    }
    return true
  }
  const nextGame = events.find((ev) => ev.eventStatus === 0)
  if (
    nextGame &&
    nextGame.seasonSegment === 'post' &&
    (nextGame.awayTeam !== null || nextGame.homeTeam !== null)
  ) {
    //Game is in the postseason, with teams
    return true
  }
  return false
}

/**
 *
 * Return if regseason has started or not
 */
export const hasRegularSeasonStarted = (events: EventGQL[]) => {
  if (events.length < 1) return false
  const regGames = events.filter((ev) => ev.seasonSegment === 'regular')
  if (regGames.length < 1) return false

  const sorted = regGames.sort((a, b) => a.startTime - b.startTime)
  const now = new Date()
  //Set current time to seconds
  const currentTime = now.getTime() / 1000
  const diff = sorted[0].startTime - currentTime
  if (diff < 0 || sorted[0].eventStatus > 0) {
    // First reg season game has started
    return true
  } else {
    return false
  }
}

/**
 *
 * Return if postseason has started or not
 */
export const hasPostseasonStarted = (events: EventGQL[]) => {
  if (events.length < 1) return false
  const postseasonGames = events.filter((ev) => ev.seasonSegment === 'post')
  if (postseasonGames.length < 1) return false

  const sorted = postseasonGames.sort((a, b) => a.startTime - b.startTime)
  const now = new Date()
  //Set current time to seconds
  const currentTime = now.getTime() / 1000
  const diff = sorted[0].startTime - currentTime
  if (diff < 0 || sorted[0].eventStatus > 0) {
    // First postseason game has started
    return true
  } else {
    return false
  }
}

/**
 *
 * Return if champ series has started or not
 */
export const checkHasChampSeriesStarted = (events: EventGQL[]) => {
  if (events.length < 1) return false
  const champSeriesGames = events.filter((ev) => ev.seasonSegment === 'champseries')
  if (champSeriesGames.length < 1) return false
  let firstGame = champSeriesGames[0]
  if (firstGame.eventStatus > 0 && isChampSeriesLive) {
    //Champ Series is going on right now
    return true
  } else {
    return false
  }
}

/**
 *
 * Map events into specific weeks
 */
export const getWeekList = (events: EventGQL[]) => {
  let weekList: string[] = []
  events.map((e) => {
    let we = weekList.find((w) => w === e.week)
    if (!we) {
      //Week wasn't added yet
      weekList.push(e.week)
    }
    return
  })
  return weekList
}

/**
 *
 * Map events into specific weeks
 */
export const mapEventWeeks = ({ week, events }: EventWeekParams) => {
  return events.filter((event) => event.week === week)
}

/**
 *
 * Map champ series events
 */
export const mapChampSeriesEvents = (
  events: EventGQL[],
  byType?: string
): { [key: string]: EventGQL[] } => {
  if (byType === 'day') {
    return mapChampSeriesEventsByDay(events)
  }
  // By segment
  return {
    roundRobin: events.filter((event) =>
      event.description.toLowerCase().includes('round')
    ),
    semis: events.filter((event) => event.description.toLowerCase().includes('semi')),
    championship: events.filter((event) =>
      event.description.toLowerCase().includes('champ')
    ),
  }
}

/**
 *
 * Map champ series events by date the game is using startTime
 */
export const mapChampSeriesEventsByDay = (events: EventGQL[]) => {
  const champSeriesDays: { [key: string]: EventGQL[] } = events.reduce((acc, event) => {
    const { date } = timeStampToDate(event.startTime)
    const existingEventsInDay = acc[date] || []
    const isDuplicate = existingEventsInDay.some(
      (ev) => ev.startTime === event.startTime
    )

    return {
      ...acc,
      [date]: isDuplicate ? existingEventsInDay : [...existingEventsInDay, event],
    }
  }, {} as { [key: string]: EventGQL[] })

  return champSeriesDays
}

/**
 *
 * Get current Week
 */
export const getCurrentWeek = (events: EventGQL[]) => {
  const weekRes = events.find((ev) => ev.eventStatus < 2)
  if (!weekRes) return null
  return weekRes.week
}

/**
 *
 * Get all events in current Week
 */
export const getCurrentWeekEvents = (events: EventGQL[]) => {
  const weekRes = events.find((ev) => ev.eventStatus < 2)
  if (!weekRes) return
  const wkEvents = mapEventWeeks({ week: weekRes.week, events: events })
  return wkEvents
}

/**
 *
 * Get all events in current Week
 */
export const hasWeekendGamesStarted = (events: EventGQL[]) => {
  const weekRes = events.find((ev) => ev.eventStatus < 2)
  if (!weekRes) return
  let now = Date.now() / 1000
  const wkEvents = mapEventWeeks({ week: weekRes.week, events: events })
  const started = wkEvents.find((w) => w.startTime < now || w.eventStatus > 0)
  return started ? true : false
}

/**
 *
 * Return date range of all games in a specific weekend
 */
export const getWeekendRange = (events: EventGQL[]) => {
  if (events.length === 0) return
  // Only one event, grab the date
  if (events.length === 1) {
    return getShortDate(events[0].startTime, false)
  }
  const sorted = events.sort((a, b) => a.startTime - b.startTime)
  const [first] = sorted.slice(0)
  const [last] = sorted.slice(-1)
  const firstDay = getShortDate(first.startTime, false)
  let lastDay = getShortDate(last.startTime, false)
  return firstDay === lastDay ? firstDay : `${firstDay}${` - ${lastDay}`}`
}

/**
 *
 * Return upcoming games, optional number to return (default 1)
 */
export const getNextMatchupsName = (evs: EventGQL[]) => {
  const upcomingEvents = evs.filter((ev) => ev.eventStatus > 0)
  if (upcomingEvents.length > 0) {
    return upcomingEvents
  }
  return []
}

/**
 *
 * Return upcoming game or final game
 */
export const getNextMatchup = (evs: EventGQL[]) => {
  const upcomingEvents = evs.filter((ev) => ev.eventStatus < 2)
  if (upcomingEvents.length > 0) {
    return upcomingEvents[0]
  }
  if (evs.length > 0 && upcomingEvents.length === 0) {
    //All games have ended, return latest game
    return evs[evs.length - 1]
  }
  return null
}

/**
 *
 * Structure tickets to use in schedule and tickets
 */
export const structureTicketObj = (week: string, events: EventGQL[], page: string) => {
  const dayTs = getDayTickets(events, page)
  const ticketObj = {
    weekend: week,
    suiteId: events[0].suiteId,
    waitList: events[0].waitlist && events[0].waitlistUrl ? true : false,
    dayTickets: dayTs || [],
  }
  return ticketObj
}

/**
 *
 * Add utm to day ticks
 */
export const createDayTicketUrl = (
  event: EventGQL,
  page: string,
  week: string,
  day: string = ''
) => {
  if (!page) return ''
  if (event.seasonSegment === 'champseries') {
    return PLL_LINKS.championshipSeriesTickets
  }
  if (event.waitlistUrl) return event.waitlistUrl
  if (!event.urlTicket) return WLL_LINKS.schedule

  return createTicketUTM({
    ticketId: event.urlTicket,
    page,
    week,
    day,
  })
}

/**
 *
 * Turns events into ticket objects
 */
export const getDayTickets = (events: EventGQL[], page: string) => {
  let tks: DayTicketsType[] = []
  let prevDate: string = ''
  let day = 1
  if (events.length === 1) {
    //Only 1 day ticket
    if (!events[0].urlTicket) return
    const d = getShortDate(events[0].startTime)
    const dayTix = createDayTicketUrl(events[0], page, events[0].week, `${day}`)
    tks = [
      {
        date: d,
        ticketUrl: dayTix || events[0].waitlistUrl,
        ticketId: events[0]?.ticketId || '',
      },
    ]
  } else {
    events.map((e) => {
      let d = getShortDate(e.startTime)
      if (d === prevDate) return
      let dayStr = day > 0 ? `${day}` : ''
      day++ //Increment day for next time
      if (!e.urlTicket) return
      const dayTix = createDayTicketUrl(e, page, events[0].week, dayStr)
      tks.push({
        date: d,
        ticketUrl: dayTix || e.waitlistUrl,
        ticketId: e?.ticketId || '',
      })
      prevDate = d
    })
  }
  return tks
}

/**
 *
 * Shifting events that already happened to the back
 */
export const setTickerStart = (events: EventGQL[]) => {
  const start = events.find((ev) => ev.eventStatus < 2)
  if (!start) {
    //last Item
    return events.length - 1
  }
  return events.indexOf(start)
}

/**
 *
 * Update the location of venues that are more specific
 */
export const createVenueLocation = (location: string, seasonSegment: string) => {
  if (seasonSegment === 'post') {
    switch (location) {
      case 'Boston, MA':
        return 'Foxborough, MA'
      case 'Philadelphia, PA':
        return 'Chester, PA'
      default:
        return location
    }
  } else {
    switch (location) {
      case 'Dallas, TX':
        return 'Frisco, TX'
      case 'Seattle, WA':
        return 'Tacoma, WA'
      case 'Philadelphia, PA':
        return 'Villanova, PA'
      case 'Salt Lake City, UT':
        return 'Herriman, UT'
      case 'Boston, MA':
        return 'Cambridge, MA'
      case 'Minneapolis, MN':
        return 'Eagan, MN'
      case 'Long Island, NY':
        return 'Hempstead, NY'
      default:
        return location
    }
  }
}

/**
 *
 * Checking if this is a special week
 */
export const isWeekSpecial = (event: EventGQL) => {
  if (!event || !event.seasonSegment) return
  if (event.week === '1') return true
  switch (event.seasonSegment) {
    case 'regular':
      return false
    case 'allstar':
      return true
    case 'post':
      return true
    default:
      return false
  }
}

/**
 *
 * Get weekends that are special like  Playoffs
 */
export const checkSpecialWeek = (event: EventGQL, useHomecoming?: boolean) => {
  if (!event || !event.seasonSegment) return

  if (event.week === '1') return 'Opening Weekend'

  switch (event.seasonSegment) {
    case 'regular':
      return null
    case 'allstar':
      return 'All-Star'
    case 'post':
      return event.description
    case 'champseries':
      return 'Championship Series'
    default:
      return null
  }
}

/**
 *
 * Return all broadcaster images for specific event
 */
export const getBroadcastImg = (broadcasters: string[]) => {
  const broadcastImgs = broadcasters.map((name) => {
    switch (name) {
      case 'ESPN':
        return `${BROADCAST_IMG}/${name}_Main.png`
      case 'ESPN2':
        return `${BROADCAST_IMG}/${name}_Main.png`
      case 'ABC':
        return `${BROADCAST_IMG}/${name}_Main.png`
      case 'ESPNU':
        return `${BROADCAST_IMG}/${name}_Black.png`
      case 'ESPN+':
        return `${BROADCAST_IMG}/${name.replace('+', '%2B')}_Black.png`
      default:
        return ''
    }
  })
  return broadcastImgs
}

/**
 *
 * Return all broadcaster images for specific event
 */
export const getReverseBroadcastImg = (broadcasters: string[]) => {
  const broadcastImgs = broadcasters.map((name) =>
    name === ''
      ? ''
      : name === 'ABC'
      ? `${REVERSE_BROADCAST_IMG}/${name.replace('+', '%2B')}_Main.png`
      : `${REVERSE_BROADCAST_IMG}/${name.replace('+', '%2B')}_White.png`
  )
  return broadcastImgs
}

/**
 *
 * Return the period and clock time of a specific game
 */
export const getPeriodAndClockTime = (event: EventGQL) => {
  if (!event) return
  let period: string | number = ''
  if (event.period > 5) {
    const otNumber = event.period - 4 //If second overtime, event.period would be 6
    period = `OT${otNumber}`
  } else {
    period = event.period === 5 ? 'OT' : GamePeriodMap[event.period]
  }
  const clockSecs = !event.clockSeconds
    ? '00'
    : event.clockSeconds < 10
    ? '0' + event.clockSeconds
    : event.clockSeconds

  if (event?.period === 2 && event?.clockMinutes === 0 && event?.clockSeconds === 0) {
    return { period: 'Half', clockTime: '' }
  }

  const clockTime = `${event.clockMinutes}:${clockSecs}`
  return {
    period,
    clockTime,
  }
}

/**
 *
 * Return text to display in button based on event status
 */
export const getActionText = (status: number) => {
  return status === 0 ? 'Preview' : status === 1 ? 'Watch Live' : 'Game Stats'
}

/**
 *
 * Return link  based on event status
 */
export const getEventActionLink = (event: EventGQL) => {
  switch (event.eventStatus) {
    case 0:
      return ''
    case 1:
      return event.urlStreaming || PLL_LINKS.watch
    case 2:
      return `${PLL_LINKS.stats}/games/${event.year || STATS_YEAR}/${event.slugname}`
    case 3:
      return `${PLL_LINKS.stats}/games/${event.year || STATS_YEAR}/${event.slugname}`
    default:
      return ''
  }
}

/**
 *
 * Returns the date and time of the event
 */
export const renderDateAndTime = (startTime: number, showInET: boolean = false) => {
  let shortDate = getShortDate(startTime)
  //If showing in eastern time is requested
  let time = showInET ? getGameTime(startTime) : getGameTimeLocal(startTime)
  let etText = showInET ? 'ET' : ''
  return `${shortDate} • ${time} ${etText}`
}

/**
 *
 * Converts list of events to game logs format
 */
export const convertAllEvents = (
  player: PlayerData,
  events: EventGQL[],
  yearTeam?: string
): GameLog[] => {
  return events.map((e) => {
    if (!yearTeam) {
      yearTeam = player.team.officialId
    }
    let opponentId =
      yearTeam === e.awayTeam.officialId ? e.homeTeam.officialId : e.awayTeam.officialId

    const seasonSegment =
      e.seasonSegment === 'allstar' ? 3 : e.seasonSegment === 'post' ? 2 : 1

    const gameLog: GameLog = {
      officialId: player.officialId,
      teamId: player?.team?.officialId,
      opponent: opponentId,
      gamesPlayed: e.playerEventStats?.gamesPlayed || 0,
      goals: e.playerEventStats?.goals || 0,
      assists: e.playerEventStats?.assists || 0,
      points: e.playerEventStats?.points || 0,
      turnovers: e.playerEventStats?.turnovers || 0,
      shots: e.playerEventStats?.shots || 0,
      shotPct: e.playerEventStats?.shotPct || 0,
      shotsOnGoal: e.playerEventStats?.shotsOnGoal || 0,
      shotsOnGoalPct: e.playerEventStats?.shotsOnGoalPct || 0,
      gamesStarted: e.playerEventStats?.gamesStarted || 0,
      onePointGoals: e.playerEventStats?.onePointGoals || 0,
      saves: e.playerEventStats?.saves || 0,
      savePct: e.playerEventStats?.savePct || 0,
      faceoffPct: e.playerEventStats?.faceoffPct || 0,
      foRecord: e.playerEventStats?.foRecord || '',
      causedTurnovers: e.playerEventStats?.causedTurnovers || 0,
      groundBalls: e.playerEventStats?.groundBalls || 0,
      scoresAgainst: e.playerEventStats?.scoresAgainst || 0,
      powerPlayGoals: e.playerEventStats?.powerPlayGoals || 0,
      numPenalties: e.playerEventStats?.numPenalties || 0,
      touches: e.playerEventStats?.touches || 0,
      totalPasses: e.playerEventStats?.totalPasses || 0,
      eventId: e.slugname,
      startTime: e.startTime,
      seasonSegment: seasonSegment,
    }

    return gameLog
  })
}
