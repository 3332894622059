import { callShopGraphQL } from '../utils/request'
import {
  topSellingProductsQuery,
  singleProductQuery,
  collectionQuery,
  collectionHandleQuery,
} from './shop.queries'
import {
  DEFAULT_MAX_WIDTH,
  setupProductObj,
  transformCollectionRes,
  transformProductsRes,
} from './shop.utils'
import type {
  ShopCollection,
  ShopCollectionResponse,
  ShopProduct,
  ShopProductResponse,
  SingleProductResponse,
} from './shop.types'
import type { ProductSortKeys } from '@shopify/hydrogen-react/storefront-api-types'

/**
 * Fetch trending shop products
 */
export async function fetchTrendingProducts(limit?: number): Promise<ShopProduct[]> {
  try {
    let query = topSellingProductsQuery(limit || 6)
    const res = await callShopGraphQL<ShopProductResponse>(query)
    let cleanProducts = transformProductsRes(res.data.products)
    return cleanProducts || []
  } catch (error) {
    return []
  }
}

/**
 * Fetch shop collection
 */
export async function fetchShopCollection(
  collectionId: string,
  sortKey: ProductSortKeys,
  limit: number = 10,
  imageMaxWidth: number = DEFAULT_MAX_WIDTH
): Promise<ShopCollection | null> {
  try {
    const query = collectionQuery(collectionId, sortKey, limit, imageMaxWidth)
    const res = await callShopGraphQL<ShopCollectionResponse>(query)
    const cleanedCollection = transformCollectionRes(res.data.collection)
    return cleanedCollection || null
  } catch (error) {
    return null
  }
}

/**
 * Fetch shop collection
 */
export async function fetchShopCollectionByHandle(
  handle: string,
  sortKey: ProductSortKeys,
  limit: number = 10,
  imageMaxWidth: number = DEFAULT_MAX_WIDTH
): Promise<ShopCollection | null> {
  try {
    const query = collectionHandleQuery(handle, sortKey, limit, imageMaxWidth)
    const res = await callShopGraphQL<ShopCollectionResponse>(query)

    const cleanedCollection = transformCollectionRes(res.data.collection)
    return cleanedCollection || null
  } catch (error) {
    return null
  }
}

/**
 * Fetch single shop product
 */
export async function fetchShopItem(
  merchId: string,
  imageMaxWidth: number = DEFAULT_MAX_WIDTH
): Promise<ShopProduct | null> {
  try {
    let query = singleProductQuery(merchId, imageMaxWidth)
    const res = await callShopGraphQL<SingleProductResponse>(query)
    let cleanedProduct = setupProductObj(res.data.product)
    return cleanedProduct
  } catch (error) {
    return null
  }
}
