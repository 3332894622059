import React, { createContext, useState, useEffect } from 'react'
import { decodeString, logEvent, resetWindowLocationWithoutParams, useQuery } from '../../Utils'
import { BRAZE_EVENTS, MasterLinkParams } from '../../Constants'
import { logBrazeEvent } from '../../Utils/Analytics/brazeAnalytics'

type LinkContextType = {
  masterLinkParams: MasterLinkParams | null
  sendMasterBrazeEvents: () => void
  getMasterLinkParamsFromLocal: () => MasterLinkParams | null
}

export const LinkContext = createContext<LinkContextType | null>(null)

export const LinkProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  let query = useQuery()
  const [masterLinkParams, setMasterLinkParams] = useState<MasterLinkParams | null>(null)

  useEffect(() => {
    //Tracking referrals
    const referrerQuery = query && query.get('referrerId')
    //PLL login source (for new users) ie shield-team
    const marSource = query && query.get('pllm_source')
    //on site check in
    const oscParam = query && query.get('osc')
    let localParams = getMasterLinkParamsFromLocal()
    const incoming = {
      referrerUserId: decodeString(referrerQuery) || '',
      pllm_source: decodeString(marSource) || '',
      osc: decodeString(oscParam) || '',
    }
    let formatted = formatMasterLinkParams(localParams, incoming)
    storeMasterLinkParams(formatted)
    setMasterLinkParams(formatted)
  }, [])

  const getMasterLinkParamsFromLocal = () => {
    if (localStorage) {
      let params = localStorage.getItem('masterLinkParams')
      if (params && params === undefined) return JSON.parse(params)
      return null
    }
    return null
  }

  const sendMasterBrazeEvents = () => {
    let params = getMasterLinkParamsFromLocal()
    if (params?.osc) {
      logBrazeEvent(BRAZE_EVENTS.on_site_check_in, params?.osc)
      logEvent(BRAZE_EVENTS.on_site_check_in, {
        game_day: params?.osc,
      })
      let newParams = {
        ...params,
        osc: '',
      }
      delete newParams.osc

      setMasterLinkParams(newParams)
      resetWindowLocationWithoutParams(['osc'])
    }
  }

  const formatMasterLinkParams = (
    localParams: MasterLinkParams,
    incomingParams: MasterLinkParams
  ) => {
    return {
      referrerUserId:
        localParams?.referrerUserId || incomingParams?.referrerUserId || '', //Don't allow overwrite
      pllm_source: localParams?.pllm_source || incomingParams?.pllm_source || '', //Don't allow overwrite
      osc: incomingParams?.osc || '', //Allow overwrite
    }
  }

  const storeMasterLinkParams = (formatted: MasterLinkParams) => {
    if (localStorage) {
      localStorage.setItem('masterLinkParams', JSON.stringify(formatted))
    }
  }

  return (
    <LinkContext.Provider
      value={{
        masterLinkParams,
        sendMasterBrazeEvents,
        getMasterLinkParamsFromLocal,
      }}
    >
      {children}
    </LinkContext.Provider>
  )
}
