import React, { useState } from 'react'
import { NAV_ITEMS, TEAMS_MENU_ITEMS } from '../../../Constants'
import {
  MobileDrawerOverlay,
  MobileDrawerContainer,
  MobileDrawerNav,
  MobileDrawerListItem,
  MobileDrawerButton,
  MobileDrawerTeamsList,
  IconWrapper,
  NavMobileBrandHeader,
  SubNavCon,
} from './styles'
import {
  CalendarOutlined,
  KeyboardArrowDownIcon,
  KeyboardArrowUpIcon,
  TrophyOutlined,
} from '../../LazyComponents'
import { colors } from '../../consts'
import { ShopOutlined } from '@mui/icons-material'
import { Img } from '../../Image/Img'
import { WLL_IMAGES } from '../../../Config/config'

type MobileDrawerProps = {
  open: boolean
  onClose: () => void
  onNavigate: (path: string) => void
}

const getIcon = (label: string) => {
  switch (label) {
    case 'Schedule':
      return <CalendarOutlined sx={{ color: colors.white, fontSize: 20 }} />
    case 'Standings':
      return <TrophyOutlined sx={{ color: colors.white, fontSize: 20 }} />
    case 'Shop':
      return <ShopOutlined sx={{ color: colors.white, fontSize: 20 }} />
    default:
      return null
  }
}

export const MobileDrawer: React.FC<MobileDrawerProps> = ({
  open,
  onClose,
  onNavigate,
}) => {
  const [subNavOpen, setSubNavOpen] = useState<{ [key: string]: boolean }>({
    teams: false,
  })

  const handleSubNavClick = (subNav: keyof typeof subNavOpen) => {
    setSubNavOpen((prev) => ({ ...prev, [subNav]: !prev[subNav] }))
  }

  if (!open) return null

  return (
    <MobileDrawerOverlay onClick={onClose}>
      <MobileDrawerContainer onClick={(e) => e.stopPropagation()}>
        <MobileDrawerNav>
          <NavMobileBrandHeader>
            <div className="brandImg">
              <Img src={WLL_IMAGES.wllShield} alt="WLL logo" />
            </div>
          </NavMobileBrandHeader>
          <ul>
            <MobileDrawerListItem>
              <MobileDrawerButton onClick={() => handleSubNavClick('teams')} withIcon>
                <IconWrapper>
                  <TrophyOutlined sx={{ color: colors.white, fontSize: 20 }} />
                </IconWrapper>
                <span>Teams</span>
                {subNavOpen.teams ? (
                  <KeyboardArrowUpIcon sx={{ color: colors.white, fontSize: 20 }} />
                ) : (
                  <KeyboardArrowDownIcon sx={{ color: colors.white, fontSize: 20 }} />
                )}
              </MobileDrawerButton>
              {subNavOpen.teams && (
                <MobileDrawerTeamsList>
                  {TEAMS_MENU_ITEMS.map((itm) => (
                    <MobileDrawerListItem key={itm.path}>
                      <MobileDrawerButton
                        onClick={() => onNavigate(itm.path)}
                        withIcon={false}
                      >
                        <SubNavCon>
                          <Img src={itm.icon} alt={itm.name} />
                          <span>{itm.name}</span>
                        </SubNavCon>
                      </MobileDrawerButton>
                    </MobileDrawerListItem>
                  ))}
                </MobileDrawerTeamsList>
              )}
            </MobileDrawerListItem>
            {NAV_ITEMS.map((item) => (
              <MobileDrawerListItem key={item.path}>
                <MobileDrawerButton onClick={() => onNavigate(item.path)} withIcon>
                  <IconWrapper>{getIcon(item.label)}</IconWrapper>
                  {item.label}
                </MobileDrawerButton>
              </MobileDrawerListItem>
            ))}
          </ul>
        </MobileDrawerNav>
      </MobileDrawerContainer>
    </MobileDrawerOverlay>
  )
}
