import { PlayerData } from "../../Api"
import { SEO_DEFAULTS, SeoDeafultType } from "../../Constants/seo"
import { getFullTeamName } from "../Teams/teamHelpers"

  /**
 * Generate matchup SEO tags
 */
export const generatePlayerSEOTags = (
    player: PlayerData | null,
    tab?: string,
  ) => {
    if (!player) return SEO_DEFAULTS.player

    let capitalTab = 'Profile'
    if(tab){
        capitalTab =  tab.charAt(0).toUpperCase() + tab.slice(1);
    }
    let tags: SeoDeafultType = {
        title:`${player.firstName} ${player.lastName} - ${capitalTab}`,
        description: `View ${player.firstName} ${player.lastName}'s WLL profile, stats, and media.`,
        keywords: `${player.firstName} ${player.lastName}, ${getFullTeamName(player?.team.officialId) || ''}, profile, player stats, wll`
    }
    return tags
  }

