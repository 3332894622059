import type { ProductSortKeys } from "@shopify/hydrogen-react/storefront-api-types"
import { ShopProductSortKeys } from "./shop.utils"

export const topSellingProductsQuery = (limit: number) => `
    {
    products(first: ${limit}, sortKey: BEST_SELLING) {
      edges {
        node {
          id
          title
          handle
          productType
          onlineStoreUrl
          featuredImage {
            url(transform: {maxWidth: 500, preferredContentType: PNG})
          }
          images(first: 1) {
            edges {
              node {
                transformedSrc
              }
            }
          }
          priceRange {
            minVariantPrice {
              amount
            }
            maxVariantPrice {
                amount
              }
          }
          variants (first: 1) {
            edges {
              node {
                id
                sku
                title
              }
            }
          }
        }
      }
    }
  }
`

export const collectionQuery = (collectionId: string, sortKey: ProductSortKeys = ShopProductSortKeys.CREATED, limit: number, imageMaxWidth: number) =>
  `
  {
    collection(id: "${collectionId}") {
      id
      handle
      products(first: ${limit}, sortKey: ${sortKey}) {
        edges {
          node {
            featuredImage {
              url(transform: {maxWidth: ${imageMaxWidth}})
            }
            id
            productType
            title
            onlineStoreUrl
            priceRange {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            variants (first: 1) {
              edges {
                node {
                  id
                  sku
                  title
                }
              }
            }
          }
        }
      }
    }
  }
  `

  export const collectionHandleQuery = (handle: string, sortKey: ProductSortKeys = ShopProductSortKeys.CREATED, limit: number, imageMaxWidth: number) =>
    `
    {
      collection(handle: "${handle}") {
        id
        handle
        products(first: ${limit}, sortKey: ${sortKey}) {
          edges {
            node {
              featuredImage {
                url(transform: {maxWidth: ${imageMaxWidth}})
              }
              id
              productType
              title
              onlineStoreUrl
              priceRange {
                maxVariantPrice {
                  amount
                }
                minVariantPrice {
                  amount
                }
              }
              variants (first: 1) {
                edges {
                  node {
                    id
                    sku
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
    `

export const singleProductQuery = (merchId: string, imageMaxWidth: number) =>
  `
  {
    product(id: "${merchId}") {
      handle
      id
      title
      images(first: 1) {
        edges {
          node {
            url(transform: {maxWidth: ${imageMaxWidth}})
          }
        }
      }
      onlineStoreUrl
      productType
      priceRange {
        maxVariantPrice {
          amount
        }
        minVariantPrice {
          amount
        }
      }
      variants(first: 1) {
        edges {
          node {
            id
            sku
            title
          }
        }
      }
    }
  }
`
