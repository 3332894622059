export type Sponsor = {
  name: string
  sponsorType: 'official' | 'founding'
  image: string
  link: string
}

export const sponsorList: Sponsor[] = [
  {
    name: 'Maybelline',
    sponsorType: 'founding',
    image: `https://premierlacrosseleague.com/wp-content/uploads/2025/01/maybelline_sponsor_logo.png`,
    link: `https://www.maybelline.com/?GeoRedirectOff=True&gad_source=1&gclid=CjwKCAiAhP67BhAVEiwA2E_9g36Xvu35I7wfWO1kOtuaqXb9k6DPRZicP7Yay4TQfNypsfVyCplzexoCoBoQAvD_BwE&gclsrc=aw.ds`,
  },
  {
    name: 'Gatorade',
    sponsorType: 'founding',
    image: `https://premierlacrosseleague.com/wp-content/uploads/2024/01/gatorade-partner.webp`,
    link: `https://www.gatorade.com/`,
  },
  {
    name: 'Lexus',
    sponsorType: 'founding',
    image: `https://premierlacrosseleague.com/wp-content/uploads/2025/01/Lexus_Logo_Horizontal_Black_on_White.webp`,
    link: `https://www.lexus.com/`,
  },
  {
    name: 'Whirlpool',
    sponsorType: 'founding',
    image: `https://premierlacrosseleague.com/wp-content/uploads/2023/11/WhirlpoolBRAND-R-2017Logo-B.webp`,
    link: `https://www.whirlpool.com/`,
  },
  {
    name: 'STX Lacrosse',
    sponsorType: 'founding',
    image: `https://premierlacrosseleague.com/wp-content/uploads/2025/01/STX.webp`,
    link: `https://stx.com/`,
  },
  {
    name: 'TruFIT Customs',
    sponsorType: 'official',
    image: `https://premierlacrosseleague.com/wp-content/uploads/2025/01/TruFIT_Logo.webp`,
    link: `https://trufitcustoms.com/`,
  },
  {
    name: 'Brine Lacrosse',
    sponsorType: 'founding',
    image: `https://premierlacrosseleague.com/wp-content/uploads/2025/02/brine-logo-hor_gold-blk__1__720.webp`,
    link: `https://www.brine.com/`,
  },
  {
    name: 'ECD Lacrosse',
    sponsorType: 'founding',
    image: `https://premierlacrosseleague.com/wp-content/uploads/2025/01/ecd.webp`,
    link: `https://ecdlax.com/`,
  },
  {
    name: 'FanDuel',
    sponsorType: 'founding',
    image: `https://premierlacrosseleague.com/wp-content/uploads/2025/02/horizontal_blue_720.webp`,
    link: `https://www.fanduel.com`,
  },
  {
    name: 'ONS',
    sponsorType: 'founding',
    image: `https://premierlacrosseleague.com/wp-content/uploads/2025/01/ONS_Logo.webp`,
    link: `https://www.onsmd.com/`,
  },
  {
    name: 'Cascade Lacrosse',
    sponsorType: 'founding',
    image: `https://premierlacrosseleague.com/wp-content/uploads/2025/01/cascade.webp`,
    link: `https://cascademaverik.com/`,
  },
  {
    name: 'Maverik Lacrosse',
    sponsorType: 'founding',
    image: `https://premierlacrosseleague.com/wp-content/uploads/2025/01/maverik_lacrosse.webp`,
    link: `https://cascademaverik.com/`,
  },
  {
    name: 'New Balance',
    sponsorType: 'official',
    image: `https://premierlacrosseleague.com/wp-content/uploads/2025/01/31d66b82d2af-new_balance.webp`,
    link: `https://www.newbalance.com/`,
  },
]
