export const timezonesMap: { [key: string]: string } = {
    // North America
    "America/New_York": "Eastern",
    "America/Chicago": "Central",
    "America/Denver": "Mountain",
    "America/Los_Angeles": "Pacific",
    "America/Anchorage": "Alaska",
    "Pacific/Honolulu": "Hawaii",
    "America/Phoenix": "Arizona",
    
    // Europe
    "Europe/London": "Greenwich",
    "Europe/Paris": "Central European",
    "Europe/Berlin": "Central European",
    "Europe/Madrid": "Central European",
    "Europe/Rome": "Central European",
    "Europe/Athens": "Eastern European",
    "Europe/Moscow": "Moscow",
    "Europe/Istanbul": "Turkey",
    "Europe/Lisbon": "Western European",
    
    // Asia
    "Asia/Tokyo": "Japan",
    "Asia/Seoul": "Korea",
    "Asia/Shanghai": "China",
    "Asia/Kolkata": "India",
    "Asia/Bangkok": "Indochina",
    "Asia/Dubai": "Arabian",
    "Asia/Karachi": "Pakistan",
    "Asia/Manila": "Philippines",
    "Asia/Singapore": "Singapore",
    "Asia/Jakarta": "Western Indonesia",
    
    // Africa
    "Africa/Cairo": "Eastern Africa",
    "Africa/Johannesburg": "South Africa",
    "Africa/Lagos": "West Africa",
    "Africa/Nairobi": "East Africa",
    
    // Australia and Oceania
    "Australia/Sydney": "Eastern Australia",
    "Australia/Perth": "Western Australia",
    "Australia/Adelaide": "Central Australia",
    "Pacific/Auckland": "New Zealand",
    
    // South America
    "America/Sao_Paulo": "Brasilia",
    "America/Argentina/Buenos_Aires": "Argentina",
    "America/Bogota": "Colombia",
    "America/Caracas": "Venezuela",
    
    // ... and more as needed
}