import React, { Suspense } from 'react'
import ErrorBoundary from '../Errors/ErrorBoundary'

interface LazyLoaderProps {
  fallback?: React.ReactNode
  children: React.ReactNode
}

interface LazyLoaderProps {
  fallback?: React.ReactNode
  children: React.ReactNode
}

const LazyLoader: React.FC<LazyLoaderProps> = ({ fallback = <></>, children }) => (
  <ErrorBoundary>
    <Suspense fallback={fallback}>{children}</Suspense>
  </ErrorBoundary>
)

export default LazyLoader
