import { styled } from '@mui/system'
import { colors, fontSizes } from '../consts'
type StyleProps = {
  color: 'red' | 'blue' | 'green'
}

export const Container = styled('div')<StyleProps>(({ color, theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  background:
    color === 'red' ? colors.red : color === 'blue' ? colors.lightBlue : colors.green,
  width: '50vw',
  margin: this,
  borderRadius: 10,
  padding: theme.spacing(1, 2),
  [theme.breakpoints.down('sm')]: {
    width: '90vw',
    padding: theme.spacing(0.5, 2),
  },
  zIndex: 99999,
  '& p': {
    fontSize: '1rem',
    fontWeight: '700',
    color: colors.white,
  },
}))

export const SnackBarStyle = styled('div')<StyleProps>(({ color, theme }) => ({
  display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background:
    color === 'red'
        ? `${colors.red}90`
        : color === 'blue'
        ? `${colors.lightBlue}90`
        : `${colors.green}90`,
    width: '50vw',
    margin: theme.spacing(1),
    borderRadius: 10,
    padding: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      width: '90vw',
      padding: theme.spacing(0.25, 1),
    },
    zIndex: 99999,
    '& p': {
      fontSize: fontSizes.medium,
      fontWeight: 700,
      color: colors.white,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: fontSizes.small,
      },
    },
  }))
