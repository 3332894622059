import { lazyImport } from '../../Utils'

export const ExpandMoreIcon = lazyImport(() => import('@mui/icons-material/ExpandMore'))
export const NotificationsIcon = lazyImport(() => import('@mui/icons-material/Notifications'))
export const IosShareIcon = lazyImport(() => import('@mui/icons-material/IosShare'))
export const InputAdornment = lazyImport(() => import('@mui/material/InputAdornment'))
export const MailOutlineIcon = lazyImport(() => import('@mui/icons-material/MailOutline'))
export const AlternateEmailIcon = lazyImport(() => import('@mui/icons-material/AlternateEmail'))

export const MenuIcon = lazyImport(() => import('@mui/icons-material/Menu'))
export const CalendarIcon = lazyImport(() => import('@mui/icons-material/CalendarMonthOutlined'))
export const CalendarOutlined = lazyImport(() => import('@mui/icons-material/CalendarTodayOutlined'))

// ARROWS //
export const KeyboardArrowRightIcon = lazyImport(() => import('@mui/icons-material/KeyboardArrowRight'))
export const KeyboardArrowDownIcon = lazyImport(() => import('@mui/icons-material/KeyboardArrowDown'))
export const KeyboardArrowUpIcon = lazyImport(() => import('@mui/icons-material/KeyboardArrowUp'))


// PROFILE //
export const AccountCircleOutlined = lazyImport(() => import('@mui/icons-material/AccountCircleOutlined'))

// SOCIAL //
export const GoogleIcon = lazyImport(() => import('@mui/icons-material/Google'))

export const TrophyOutlined = lazyImport(() => import('@mui/icons-material/EmojiEventsOutlined'))
export const ShopOutlined = lazyImport(() => import('@mui/icons-material/ShoppingBagOutlined'))