import { formatProfileUrl } from '../Users'
import { FirebaseUser, User } from '../Users/types'
import { getFirAuth } from './firebaseConnect'

export type FavType = 'topics' | 'favPlayers' | 'favTeam' | 'favWLLTeam'

export const NO_USER: User = {
  isLoggedIn: false,
  isAnonymous: false,
  uid: null,
  email: null,
  favPlayers: [],
  topics: [],
}

/**
 * Get currently logged user data from firebase
 */
export const getCurrentUser = async (): Promise<FirebaseUser> => {
  const auth = await getFirAuth()
  const usr = auth?.currentUser
  if (usr && usr.uid) {
    // logged in user
    let firUser: FirebaseUser = {
      isLoggedIn: usr.isAnonymous ? false : true,
      isAnonymous: usr.isAnonymous,
      uid: usr.uid,
    }
    if (usr?.displayName) {
      firUser['firstName'] = usr.displayName.split(' ')[0]
      firUser['lastName'] = usr.displayName.split(' ')[1]
    }
    if (usr?.email) {
      firUser['email'] = usr.email
    }
    if (usr?.phoneNumber) {
      firUser['phone'] = usr.phoneNumber
    }
    if (usr?.photoURL) {
      firUser['profileUrl'] = usr.photoURL
    }

    return firUser
  }
  // not logged in
  return NO_USER
}

/**
 * Get currently logged user data
 */
export const setUpdatedUserData = async (userRes: any): Promise<User> => {
  const usr = (await getFirAuth())?.currentUser
  if (usr && usr.uid) {
    // logged in user
    let userObj: User = {
      isLoggedIn: usr.isAnonymous ? false : true,
      isAnonymous: usr.isAnonymous,
      uid: usr.uid,
      firstName: userRes?.firstName,
      lastName: userRes?.lastName,
      email: userRes?.email,
      profileUrl: userRes?.profileUrl ? formatProfileUrl(userRes.profileUrl) : '',
      phone: usr?.phoneNumber ? usr.phoneNumber : userRes?.phone ? userRes.phone : '',
      birthday: userRes?.birthday || '',
      tw: userRes?.tw || '',
      zip: userRes?.zip || '',
      gamification: userRes?.gamification ? userRes.gamification : false,
      favPlayers: userRes?.favPlayers || [],
    }
    if (userRes?.username) {
      userObj['username'] = userRes.username
    }
    if (userRes?.pllNation) {
      userObj['pllNation'] = userRes.pllNation
    }
    return userObj
  }
  // not logged in
  return NO_USER
}
