import React, { useEffect, useState } from 'react'
import { HelperCon } from './styles'

export const LoginIssue: React.FC = () => {
  let copyLink = window.location.href
  const [copied, setCopied] = useState<string | null>(null)
  const [showHelper, setShowHelper] = useState<boolean>(false)

  useEffect(() => {
    // Set timer to clear copied so user can click multiple times
    if (copied) {
      const timer = setTimeout(() => setCopied(''), 2000)
      return () => clearTimeout(timer)
    }
    return
  }, [copied])

  useEffect(() => {
    let isMobile = false
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      isMobile = true
    }
    if (isMobile) {
      const showTimer = setTimeout(() => setShowHelper(true), 4000)
      return () => clearTimeout(showTimer)
    }
    return
  }, [])

  const handleClick = () => {
    navigator.clipboard.writeText(copyLink || origin)
    setCopied('Copied!')
  }

  return (
    <>
      {showHelper && (
        <HelperCon>
          <div className="loginDesc">
            Trouble logging in? Copy and paste in your phone browser.
          </div>
          <button className="helpBtn" onClick={handleClick}>
            {!copied ? 'Copy' : 'Copied!'}
          </button>
        </HelperCon>
      )}
    </>
  )
}
