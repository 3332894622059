import { styled } from '@mui/system'
import { colors, fontSizes } from '../../consts'

type SelectorProps = {
  size?: 'sm' | 'md' | 'lg'
  color?: string
  textColor?: string
}

export const SelectorStyle = styled('div')<SelectorProps>(
  ({ size, color, textColor, theme }) => ({
    margin: theme.spacing(2, 1),
    '& > .MuiInputBase-root': {
      fontSize:
        size === 'lg'
          ? fontSizes.semiLarge
          : size === 'md'
          ? fontSizes.medium
          : fontSizes.small,
      fontWeight: 'bold',
      [theme.breakpoints.down('md')]: {
        fontWeight: 600,
        fontSize:
          size === 'lg'
            ? fontSizes.medium
            : size === 'md'
            ? fontSizes.small
            : fontSizes.xSmall,
        color: textColor ? textColor : colors.text,
      },
      '& .MuiInput-underline:before': { borderBottomColor: colors.lightestGrey },
    '& .MuiInput-underline:after': { borderBottomColor: colors.lightestGrey },
      '&::before': {
        display: 'none',
      },
      '&::after': {
        display: 'none',
      },
      '& svg': {
        fill: color,
      },
    },
  })
)

export const SeasonRowStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(0, 2),
}))

export const SelectorCon = styled('div')(({ theme }) => ({
  margin: theme.spacing(1, 2),
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  [theme.breakpoints.down('xs')]: {
    justifyContent: 'center',
  },

  '& > div:first-of-type': {
    borderRadius: 18,
  },
}))

type SeasonTypeProps = {
  active: boolean
  disabled?: boolean
}
export const SeasonTypeItemCon = styled('div')<SeasonTypeProps>(
  ({ active, disabled = false, theme }) => ({
    display: disabled ? 'none' : 'block',
    fontSize: fontSizes.small,
    fontWeight: active ? 800 : 400,
    margin: theme.spacing(0.5, 2),
    cursor: 'pointer',
  })
)

export const SelectorOutlinedStyle = styled('div')<SelectorProps>(
  ({ color, theme }) => ({
    fontSize: fontSizes.medium,
    fontWeight: 'normal',
    border: `solid 1px ${colors.lightGrey}`,
    borderRadius: 8,
    padding: theme.spacing(0, 3),
    height: 40,
    minWidth: 200,
    margin: theme.spacing(0, 0.5),

    '&::before': {
      display: 'none',
    },
    '&::after': {
      display: 'none',
    },
    '& svg': {
      fill: color || colors.lightGrey,
    },
  })
)
