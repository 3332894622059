
export const colors = {
  pageBackground: '#efefef',
  darkBackground: '#262626',
  darkLayout: '#3A3E40',
  white: '#fff',
  offWhite: '#FAFAFA',
  tan: '#B9B9B9',
  black: '#000',
  desc: '#A6A6A6',
  gray: {
    gray20: '#DEDEE3',
    gray40: '#B4B4B9',
    gray60: '#98989D',
    gray70: '#595959',
    gray75: '#3A3E40',
    gray80: '#313131',
    gray90: '#262626'
  },
  lightGrey: '#c7c7c7',
  lightestGrey: '#ececec',
  text: '#242424',
  helperText: 'rgba(0, 0, 0, .60)',
  yellow: '#ffcb06',
  blue: '#0764a6',
  darkBlue: '#0032A0',
  lightBlue: '#3ba9f6',
  lightOlive: '#95D6DB',
  placeholder: '#f6f6f6',
  green: '#00c364',
  darkGreen: '#006a13',
  greenOpaque: 'rgba(0, 195, 100, .20)',
  red: '#FA5050',
  redOpaque: 'rgba(255, 18, 18, .20)',
  gold: '#ecbf28',
  limeGreen: '#28C101',
}

export const zIndex = {
  loginOverlay: 1299,
  slideupOverlay: 1209,
  videoOverlay: 1208,
  allStarVoteEmailOverlay: 1207,
}

// width of black gradient overlay on article cover
// to make text always readable
export const MAX_GRADIENT_WIDTH = 752
export const panelWidth = {
  largest: 420,
  desktop: 350,
  tablet: 280,
  mobile: 140,
}

export const BORDER_RADIUSES = {
  xSmall: 6,
  small: 8,
  medium: 10,
  semiLarge: 12,
  large: 16,
  xLarge: 20
}

export const SLIDEUP_HEIGHTS: {[key: string]: string} = {
  full: '100%',
  twoThirds: '66%',
  half: '50%',
  third: '33%'
}

export const ARTICLE_CARD_SIZE = {
  featured: {
    desktop: '100%',
    tablet: '100%',
    mobile: '100%',
    smMobile: '100%'
  },
  home_featured: {
    desktop: '100%',
    tablet: '100%',
    mobile: '100%',
    smMobile: '100%'
  },
  normal: {
    desktop: 420,
    tablet: 320,
    mobile: 300,
    smMobile: 300
  },
  ticker: {
    desktop: 320,
    tablet: 300,
    mobile: 280,
    smMobile:260
  },
  small: {
    desktop: 320,
    tablet: 250,
    mobile: 240,
    smMobile: 240
  },
  thumbnail: {
    desktop: 100,
    tablet: 90,
    mobile: 80,
    smMobile: 70
  },
  multiCol: {
    desktop: 420,
    tablet: 250,
    mobile: 240,
    smMobile: 240
  },
  text: {
    desktop: '25%',
    tablet: '30%',
    mobile: '45%',
    smMobile:'70%'
  },
  matchup: {
    desktop: "25vw",
    tablet: '25vw',
    mobile: '25vw',
    smMobile:'70%'
  },
  playerCard: {
    desktop: 200,
    tablet: 200,
    mobile: 180,
    smMobile: 180
  },
  weekendItem: {
    desktop: 290,
    tablet: 210,
    mobile: 140,
    smMobile: 140
  },
  academyEvent: {
    desktop: 380,
    tablet: 380,
    mobile: 370,
    smMobile: 370
  },
}

export const MERCH_CARD_SIZE = {
  normal: {
    desktop: 250,
    tablet: 225,
    mobile: 130,
    smMobile: 130
  },
  small: {
    desktop: 260,
    tablet: 240,
    mobile: 150,
    smMobile: 140
  }
}



export const fontSizes = {
  xSmall: 10,
  small: 13, //'0.8rem',
  medium: 17, //'1rem',
  semiLarge: 20, // player name on player page
  large: 30, // player name on player page
  xlarge: 38, //'1.2rem',
}
