import { colors, fontSizes, zIndex } from '../../consts'
import { styled } from '@mui/system'

type OverlayProps = {
  type: 'dialog' | 'video' | 'popup' | 'slideup'
  orientation?: 'center' | 'bottom'
}

export const ModalOverlay = styled('div')<OverlayProps>(
  ({ type, orientation, theme }) => ({
    position: 'fixed',
    left: 0,
    bottom: 0,
    width: '100%',
    height: type === 'slideup' ? '40vh' : '100%',
    overflow: 'hidden',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: orientation === 'bottom' ? 'flex-end' : 'center',
    alignItems: 'center',

    // layer and background color depends on type
    ...(type === 'slideup' && {
      zIndex: zIndex.loginOverlay,
      backgroundColor: 'none',
    }),
    ...(type === 'dialog' && {
      zIndex: zIndex.loginOverlay,
      backgroundColor: 'rgba(255,255,255,0.8)',
    }),
    ...(type === 'video' && {
      zIndex: zIndex.videoOverlay,
      backgroundColor: 'rgba(0,0,0,0.7)',
      width: '100vw',
      height: '100vh',
    }),
    ...(type === 'popup' && {
      zIndex: zIndex.loginOverlay,
      backgroundColor: 'rgba(0,0,0,0.4)',
    }),
    '& .closeX': {
      position: 'fixed',
      top: orientation === 'bottom' ? '48%' : 80,
      right: 25,
      color: colors.white,
      background: colors.text,
      borderRadius: '50%',
      padding: '10px 12px',
      fontSize: 18,
      fontWeight: 800,
      cursor: 'pointer',
      zIndex: 99,
      [theme.breakpoints.down('sm')]: {
        fontSize: 16,
        top: orientation === 'bottom' ? '55%' : 70,
        right: 10,
        padding: '8px 10px',
      },
    },
  })
)

export const BottomCon = styled('div')(({ theme }) => ({
  position: 'fixed',
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 9999,
}))

export const AppCalloutCon = styled('div')(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    right: 0,
    left: 0,
    padding: theme.spacing(0, 1, 0, 6),
    backgroundImage: `linear-gradient(to right, #000000, #434343)`,
  },
  '& img': {
    width: 20,
    height: 20,
    marginRight: theme.spacing(2),
    borderRadius: 3,
  },
  '& .text': {
    fontSize: fontSizes.small,
    color: colors.white,
    fontWeight: 'bold',
    marginRight: 10,
  },
  '& a': {
    textDecoration: 'none',
    cursor: 'pointer',
    '& button': {
      padding: theme.spacing(1, 2),
      borderRadius: theme.spacing(3),
      fontWeight: 'bold',
      color: colors.text,
      cursor: 'pointer',
      backgroundColor: colors.white,
      marginRight: theme.spacing(1),
      border: 'none',
      '&:focus': {
        outline: 'none',
      },
    },
  },
  '& .close': {
    display: 'flex',
    width: 40,
    justifyContent: 'center',
    borderRight: `solid 1px ${colors.white}`,
    margin: theme.spacing(0, 1, 0, 0),
    '& p': {
      fontSize: fontSizes.medium,
      fontWeight: 'bold',
      color: colors.white,
    },
  },
}))
