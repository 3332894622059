import { lazy, Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { StandingsProvider } from '../../Context'

const LeagueProvider = lazy(() =>
  import('../../Context').then((module) => ({ default: module.LeagueProvider }))
)

export const LeagueContextLayout: React.FC = () => {
  return (
    <Suspense fallback={<div />}>
      <StandingsProvider>
        <LeagueProvider>
          <Outlet />
        </LeagueProvider>
      </StandingsProvider>
    </Suspense>
  )
}
