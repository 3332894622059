import { indexedDBs } from '../../Config/Storage/storage.config'

export interface CachedItem {
  key: string
  value: any
}

class IndexedDBHelper {
  private dbName: string
  private storeName: string
  private dbVersion: number
  private db: IDBDatabase | null

  constructor(
    dbName = indexedDBs.main.dbName,
    storeName = indexedDBs.main.storeName,
    dbVersion = indexedDBs.main.dbVersion
  ) {
    this.dbName = dbName
    this.storeName = storeName
    this.dbVersion = dbVersion
    this.db = null
  }

  /**
   * Opens a connection to the IndexedDB database.
   */
  private openDB(): Promise<IDBDatabase> {
    return new Promise((resolve, reject) => {
      if (this.db) {
        resolve(this.db)
        return
      }

      let request: IDBOpenDBRequest
      try {
        request = indexedDB.open(this.dbName, this.dbVersion)
      } catch (error: any) {
        reject(new Error(`Failed to open IndexedDB: ${error?.message}`))
        return
      }

      request.onerror = () => {
        const error = request.error
        reject(
          new Error(`Failed to open IndexedDB: ${error?.message || 'Unknown error'}`)
        )
      }

      request.onsuccess = (event) => {
        this.db = request.result
        resolve(this.db)
      }

      request.onupgradeneeded = (event) => {
        const db = request.result
        if (db.objectStoreNames.contains(this.storeName)) {
          //delete old if outdated
          db.deleteObjectStore(this.storeName)
        }
        // Create new object store
        db.createObjectStore(this.storeName)
      }
    })
  }

  /**
   * Stores an item in the IndexedDB.
   * @param key The key under which the item is stored.
   * @param value The data to store.
   */
  async storeItem(key: string, value: any): Promise<void> {
    const db = await this.openDB()
    return new Promise((resolve, reject) => {
      try {
        const transaction = db.transaction([this.storeName], 'readwrite')
        const store = transaction.objectStore(this.storeName)
        const request = store.put(value, key)

        transaction.oncomplete = () => resolve()
        transaction.onerror = () => {
          const error = transaction.error
          reject(
            new Error(
              `Transaction failed during storeItem: ${error?.message || 'Unknown error'}`
            )
          )
        }
        transaction.onabort = () => {
          const error = transaction.error
          reject(
            new Error(
              `Transaction aborted during storeItem: ${
                error?.message || 'Unknown error'
              }`
            )
          )
        }

        request.onerror = () => {
          const error = request.error
          reject(
            new Error(
              `Failed to store item in IndexedDB: ${error?.message || 'Unknown error'}`
            )
          )
        }
      } catch (error: any) {
        reject(new Error(`Exception during storeItem: ${error?.message}`))
      }
    })
  }

  /**
   * Retrieves an item from the IndexedDB.
   * @param key The key of the item to retrieve.
   * @returns The retrieved item or undefined if not found.
   */
  async retrieveItem(key: string): Promise<any | undefined> {
    const db = await this.openDB()
    return new Promise((resolve, reject) => {
      try {
        const transaction = db.transaction([this.storeName], 'readonly')
        const store = transaction.objectStore(this.storeName)
        const request = store.get(key)

        transaction.onerror = () => {
          const error = transaction.error
          reject(
            new Error(
              `Transaction failed during retrieveItem: ${
                error?.message || 'Unknown error'
              }`
            )
          )
        }
        transaction.onabort = () => {
          const error = transaction.error
          reject(
            new Error(
              `Transaction aborted during retrieveItem: ${
                error?.message || 'Unknown error'
              }`
            )
          )
        }

        request.onsuccess = (event) => {
          const result = (event.target as IDBRequest).result
          resolve(result)
        }
        request.onerror = () => {
          const error = request.error
          reject(
            new Error(
              `Failed to retrieve item from IndexedDB: ${
                error?.message || 'Unknown error'
              }`
            )
          )
        }
      } catch (error: any) {
        reject(new Error(`Exception during retrieveItem: ${error?.message}`))
      }
    })
  }

  /**
   * Deletes an item from the IndexedDB.
   * @param key The key of the item to delete.
   */
  async deleteItem(key: string): Promise<void> {
    const db = await this.openDB()
    return new Promise((resolve, reject) => {
      try {
        const transaction = db.transaction([this.storeName], 'readwrite')
        const store = transaction.objectStore(this.storeName)
        const request = store.delete(key)

        transaction.oncomplete = () => resolve()
        transaction.onerror = () => {
          const error = transaction.error
          reject(
            new Error(
              `Transaction failed during deleteItem: ${
                error?.message || 'Unknown error'
              }`
            )
          )
        }
        transaction.onabort = () => {
          const error = transaction.error
          reject(
            new Error(
              `Transaction aborted during deleteItem: ${
                error?.message || 'Unknown error'
              }`
            )
          )
        }

        request.onerror = () => {
          const error = request.error
          reject(
            new Error(
              `Failed to delete item from IndexedDB: ${
                error?.message || 'Unknown error'
              }`
            )
          )
        }
      } catch (error: any) {
        reject(new Error(`Exception during deleteItem: ${error?.message}`))
      }
    })
  }
}

// Instantiate the helper
export const indexedDBHelper = new IndexedDBHelper()
