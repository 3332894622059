import React from 'react'
import { DropdownBtnStyle } from './styles'

type ActionButtonProps = {
  handleClick: (event: React.MouseEvent<HTMLElement>) => void
  ariaLabel: string
  endIcon?: React.ReactNode
  color?: string
  fontSize?: string
  disabled?: boolean
  children: React.ReactNode
}

export const NavDropdownBtn: React.FC<React.PropsWithChildren<ActionButtonProps>> = ({
  handleClick,
  ariaLabel,
  color,
  fontSize,
  disabled,
  children,
}) => {
  return (
    <DropdownBtnStyle
      aria-label={ariaLabel}
      color={color}
      fontSize={fontSize}
      disabled={disabled}
      onClick={handleClick}
    >
      {children}
    </DropdownBtnStyle>
  )
}
