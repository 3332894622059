import React, { useEffect, useState, createContext } from 'react'
import { PlayerProfileUrlParams, usePlayerUrlParams } from '../utils'
import {
  EventGQL,
  FeedItem,
  GameLog,
  PlayerCareerStats,
  PlayerData,
  PlayersStatsData,
  TeamFrontData,
  fetchWLLPlayerEventStats,
} from '../../Api'
import { CURRENT_YEAR, STATS_YEAR } from '../../Config/League/league.config'
import { eventParse } from './eventParse'
import type { Event } from './eventParse'
import type { FilteredResult, StatListType } from '../../Constants/statsProcess'
import type { SeasonType } from '../../Routing/urls'
import {
  getTeamEvents,
  getAdditionalTeamData,
  extractStatFromObject,
  SEASON_SEGMENT_NAMES,
} from '../../Utils'
import { websiteUrls } from '../../Constants'
import { fetchWLLPlayerBySlug } from '../../Api'
import { convertAllEvents } from '../../Utils/Events/eventHelpers'

export type GameLogExt = {
  raw: GameLog
  values: FilteredResult[]
  event: Event
  eventUrl: string
}

export type Season = { seasonType: SeasonType; year: number }
export type SeasonStatArrays = {
  regular: PlayersStatsData | null
  post: PlayersStatsData | null
  champseries: PlayersStatsData | null
}

type PlayerProfileContextType = {
  params: PlayerProfileUrlParams | null
  infoLoaded: boolean
  error: Error | null
  player: PlayerData | null
  seasonStatsArrays: SeasonStatArrays
  seasonStats: PlayersStatsData | null
  career: PlayerCareerStats | null
  gameLogs: GameLog[]
  playerEvents: EventGQL[]
  team: TeamFrontData | null
  seasonSelect: Season
  media: FeedItem[]
  getStats: (
    statType: keyof StatListType,
    useChampSeries: boolean,
    stats: PlayersStatsData | PlayerCareerStats
  ) => FilteredResult[]
  getGameLogs: () => GameLogExt[]
}

export const PlayerProfileContext = createContext<PlayerProfileContextType | null>(null)

export const PlayerProfileProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const defaultParams = usePlayerUrlParams()
  const [params, setParams] = useState<PlayerProfileUrlParams | null>(defaultParams)
  const [infoLoaded, setInfoLoaded] = useState<boolean>(false)
  const [media, setMedia] = useState<FeedItem[]>([])
  const [error, setError] = useState<Error | null>(null)
  const [player, setPlayer] = useState<PlayerData | null>(null)
  const [gameLogs, setGameLogs] = useState<GameLog[]>([])
  const [playerEvents, setPlayerEvents] = useState<EventGQL[]>([])
  const [team, setTeam] = useState<TeamFrontData | null>(null)
  const [seasonSelect, setSeasonSelect] = useState<Season>({
    seasonType: SEASON_SEGMENT_NAMES.championshipseries,
    year: CURRENT_YEAR,
  })

  useEffect(() => {
    if (!params?.slug) {
      console.log('No slug')
      return
    }
    setSeasonSelect({
      seasonType: params?.type ? params?.type : seasonSelect.seasonType,
      year: CURRENT_YEAR,
    })
    fetchData()
  }, [])

  const fetchData = async () => {
    if (!params) {
      console.log('No slug')
      return
    }
    let prms = {
      slug: params.slug,
      tab: params.tab,
      year: params.year || seasonSelect.year,
      statYear: STATS_YEAR,
      type: params.type || seasonSelect.seasonType,
      eventsYear: params.eventsYear,
    }
    try {
      await fetchProfile(prms)
    } catch (err: any) {
      console.log(err)
      setError(err)
    }
    return
  }

  const fetchProfile = async (prms: PlayerProfileUrlParams) => {
    let infoYear = params?.year ? params.year : CURRENT_YEAR
    const playerData = await fetchWLLPlayerBySlug(prms.slug, infoYear)
    if (!playerData) return
    setPlayer(playerData)
    getTeam(playerData)
    setInfoLoaded(true)

    //Fetch Stats for year
    await fetchPlayerGameStats(playerData)
  }

  const fetchPlayerGameStats = async (player: PlayerData) => {
    let pStatsRes = await fetchWLLPlayerEventStats(player.slug, seasonSelect.year)
    if (!pStatsRes || !pStatsRes.length) return

    let plr = { ...player, allEvents: pStatsRes }

    updateGameStatsInState(plr, seasonSelect.year)
    assignTeamEvents(plr)
  }

  const assignTeamEvents = (player: PlayerData) => {
    if (!player?.team?.officialId) return
    try {
      if (player.allEvents) {
        const evs = getTeamEvents(player.allEvents, player.team.officialId)
        setPlayerEvents(evs)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /** Get team data for player */
  const getTeam = (plr: PlayerData) => {
    if (plr?.team?.officialId) {
      let team = getAdditionalTeamData(plr.team.officialId)
      setTeam(team)
      return
    } else {
      return null
    }
  }

  const updateGameStatsInState = (player: PlayerData, year: number) => {
    if (player?.allEvents && player.allEvents.length > 0) {
      let logs = convertAllEvents(player, player.allEvents, player.team.officialId)
      setGameLogs(logs)
    }
  }

  /**
   * Returns stats for player depending at their position and stat type
   */
  const getStats = (
    statType: keyof StatListType,
    useChampSeries: boolean,
    stats: PlayersStatsData | PlayerCareerStats
  ): FilteredResult[] => {
    if (!stats || !player?.position) return []

    // Handle regular season stats
    return extractStatFromObject(player.position, statType, stats)
  }

  /**
   * Get processed game logs
   */
  const getGameLogs = (): GameLogExt[] => {
    const segment =
      seasonSelect.seasonType === SEASON_SEGMENT_NAMES.allstar
        ? 3
        : seasonSelect.seasonType === SEASON_SEGMENT_NAMES.post
        ? 2
        : 1
    const pos = player?.position
    if (!pos) return []
    if (gameLogs.length < 1) return []

    return gameLogs
      .sort((a, b) => a.startTime - b.startTime)
      .map((log) => {
        const event = eventParse(log)
        if (event === null) return null
        const values = extractStatFromObject(pos, 'game', log)
        return {
          raw: log,
          values: values,
          event: event,
          eventUrl: `${websiteUrls.statsBaseUrl}/games/${seasonSelect.year}/${log.eventId}`,
        }
      })
      .filter((log) => log !== null && log.raw.seasonSegment === segment) as GameLogExt[]
  }

  return (
    <PlayerProfileContext.Provider
      value={{
        params,
        seasonStatsArrays: { regular: null, post: null, champseries: null },
        seasonStats: null,
        career: null,
        infoLoaded,
        error,
        player,
        gameLogs,
        playerEvents,
        team,
        seasonSelect,
        media,
        getStats,
        getGameLogs,
      }}
    >
      {children}
    </PlayerProfileContext.Provider>
  )
}
