export type SeasonSelectorType = 'main' | 'standings'
export const SEASON_SELECTOR_TYPES: { [key: string]: SeasonSelectorType } = {
  MAIN: 'main',
  STANDINGS: 'standings',
}

export const SEASON_CONFERNCES: { [key: number]: string[] } = {
  2025: ['eastern', 'western'],
}

export const PLL_CONFERENCES = {
  EASTERN: 'eastern',
  WESTERN: 'western',
}
