import { PLL_URL, WLL_IMAGES, WLL_LINKS } from '../Config/config'
import { teamsDataArray } from './team'

export type NavItem = {
  label: string
  path: string
}

export type SubMenuItem = {
  id: string
  name: string
  path: string
  icon: string
}

export const getNavTeamIcon = (teamId: string) => {
  return (
    teamsDataArray.find((team) => team.officialId === teamId)?.teamLogo ??
    WLL_IMAGES.wllShield
  )
}

export const TEAMS_MENU_ITEMS: SubMenuItem[] = [
  {
    id: 'WGUA',
    name: 'Boston Guard',
    path: '/teams/guard',
    icon: getNavTeamIcon('WGUA'),
  },
  {
    id: 'WCAL',
    name: 'California Palms',
    path: '/teams/palms',
    icon: getNavTeamIcon('WCAL'),
  },
  {
    id: 'WMDL',
    name: 'Maryland Charm',
    path: '/teams/charm',
    icon: getNavTeamIcon('WCHR'),
  },
  {
    id: 'WGUC',
    name: 'New York Charging',
    path: '/teams/charging',
    icon: getNavTeamIcon('WCHA'),
  },
]

export const NAV_ITEMS: NavItem[] = [
  { label: 'Schedule', path: '/schedule' },
  { label: 'Standings', path: '/standings' },
  { label: 'WLL Play', path: `${PLL_URL}/play/schedule?tab=girls` },
]

export const NAV_ACTION_ITEMS: NavItem[] = [
    { label: 'Tickets', path: WLL_LINKS.championshipSeriesTickets },
    { label: 'Shop', path: WLL_LINKS.shop },
  ]
