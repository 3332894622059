import { TeamFrontData } from '../Api/Teams/team.types'
import { MAIN_PLL_URL, MAIN_WLL_URL, Team_LOGO_URL } from '../Config/config'
import { PackedMap, statFields } from './statsProcess'

export const champSeriesTeams: { [key: number]: string[] } = {
  2025: ['wcha', 'wgua', 'wchr', 'wplm'],
}

export const websiteUrls = {
  shopBaseUrl: `https://shopwll.com`,
  statsBaseUrl: `https://stats.${MAIN_PLL_URL}`,
  ticketsBaseUrl: `https://${MAIN_WLL_URL}/schedule`,
  schedule: `https://${MAIN_WLL_URL}/schedule`,
  twitterUrl: 'https://twitter.com/',
  instagramUrl: 'https://instagram.com/',
}

export const teamDropdown = [
  { label: 'All Teams', value: 'All Teams' },
  { label: 'Charging', value: 'WCHA' },
  { label: 'Charm', value: 'WCHR' },
  { label: 'Guard', value: 'WGUA' },
  { label: 'Palms', value: 'WPLM' },
]

export const TEAM_LOGO_IMAGES: { [key: string]: string } = {
  'charm': `${Team_LOGO_URL}/2024/Logo/wll_maryland_charm_logo_primary.png`,
  'palms': `${Team_LOGO_URL}/2024/Logo/wll_california_palms_logo_primary.png`,
  'charging': `${Team_LOGO_URL}/2024/Logo/wll_new_york_charging_logo_primary.png`,
  'guard': `${Team_LOGO_URL}/2024/Logo/wll_boston_guard_logo_primary.png`,
  'player pool': `https://${MAIN_PLL_URL}/wp-content/uploads/2024/03/shield-bg.png`,
  'east': `https://${MAIN_PLL_URL}/wp-content/uploads/2024/06/24ASG_EIcon_FullColor.webp`,
  'west': `https://${MAIN_PLL_URL}/wp-content/uploads/2024/06/24ASG_WIcon_FullColor.webp`,
}

export const teamsDataArray: TeamFrontData[] = [
  {
    officialId: 'WCHA',
    fullName: 'Charging',
    teamColor: '#000000',
    background: '#000000',
    igTag: 'nycharging',
    twTag: 'wllcharging',
    teamBanner: '',
    teamLogo: 'https://img.premierlacrosseleague.com/Teams/2024/Logo/wll_new_york_charging_logo.png',
    shopImg: '',
    location: 'New York',
    locationCode: 'NYC',
  },
  {
    officialId: 'WGUA',
    fullName: 'Guard',
    teamColor: '#000000',
    background: '#000000',
    igTag: 'wllguard',
    twTag: 'wllguard',
    teamBanner: '',
    teamLogo: 'https://img.premierlacrosseleague.com/Teams/2024/Logo/wll_boston_guard_logo.png',
    shopImg: '',
    location: 'Boston',
    locationCode: 'BOS',
  },
  {
    officialId: 'WCHR',
    fullName: 'Charm',
    teamColor: '#000000',
    background: '#000000',
    igTag: 'wllcharm',
    twTag: 'wllcharm',
    teamBanner: '',
    teamLogo: 'https://img.premierlacrosseleague.com/Teams/2024/Logo/wll_maryland_charm_logo.png',
    shopImg: '',
    location: 'Maryland',
    locationCode: 'MDC',
  },
  {
    officialId: 'WPLM',
    fullName: 'Palms',
    teamColor: '#000000',
    background: '#000000',
    igTag: 'wllpalms',
    twTag: 'wllpalms',
    teamBanner: '',
    teamLogo: 'https://img.premierlacrosseleague.com/Teams/2024/Logo/wll_california_palms_logo.png',
    shopImg: '',
    location: 'California',
    locationCode: 'CAL',
  },
  {
    officialId: 'ZPP',
    fullName: 'Player Pool',
    teamColor: '#000',
    background: '#eeeeee',
    igTag: 'pll',
    twTag: 'PremierLacrosse',
    teamBanner: '',
    teamLogo: '',
    shopImg: '',
  },
]

export const pllDefaultTeam = {
  officialId: 'WLL',
  fullName: 'Womens Lacrosse League',
  teamColor: '#ffcb06',
  background: '#eeeeee',
  igTag: 'wll',
  twTag: 'wll',
  teamBanner: '',
  teamLogo: '',
  shopImg: '',
}
export const teamStatFields: PackedMap = {
  ...statFields,
  's': ['s', 'scores', 'Scores'],
  'sPG': ['sPG', 'scores per game', 'Scores Per Game'],
}

export const fullNameTeamStatLabels = Object.fromEntries(
  Object.values(teamStatFields).map(([_, fullLabel, key]) => [key, fullLabel])
)
