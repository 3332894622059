export type StorageOptionsStatus = {
  indexedDB:boolean
  local: boolean
  session: boolean
  cookies: boolean
}

//Cached storage names
export const STORAGE_NAMES = {
  seasonEvents: 'wll_season_events',
  home_feed: 'home_feed',
  home_shop_collection: 'home_shop_collection',
  banners: 'banners',
  standings: 'standings',
  app_config: 'wll_app_config',
  team_data: 'wll_team_data'
}
