import {
  ShopProduct,
  ShopProductEdges,
  ShopifyCollection,
  SingleProductEdge,
} from './shop.types'

export const DEFAULT_MAX_WIDTH = 500
export enum ShopProductSortKeys {
  BEST_SELLING = "BEST_SELLING",
  CREATED = "CREATED_AT",
  MANUAL = "MANUAL",
  PRICE = "PRICE",
  PRICE_DESC = "PRICE_DESC",
  TITLE = "TITLE",
  UPDATED = "UPDATED",
  RELEVANCE = "RELEVANCE",
  COLLECTION_DEFAULT = "COLLECTION_DEFAULT",
  BEST_SELLING_DESC = "BEST_SELLING_DESC"
}

export const transformCollectionRes = (collection: ShopifyCollection) => {
  let prods = transformProductsRes(collection.products)
  return {
    id: collection.id,
    handle: collection.handle,
    products: prods,
  }
}

export const transformProductsRes = (products: ShopProductEdges) => {
  try {

    let list: ShopProduct[] = []
    products.edges.map((e) => {
      let prod = setupProductObj(e)
      list.push(prod)
      return
    })

    return list
  } catch (error) {
    console.error('Error in transformProductsRes', error)
    return []
  }
}

export const setupProductObj = (product: SingleProductEdge) => {
  return {
    id: product.node.id,
    title: product.node?.title,
    handle: product.node?.handle,
    onlineStoreUrl: product.node?.onlineStoreUrl,
    productType: product.node?.productType,
    featuredImage: product.node?.featuredImage?.url,
    price: updatePriceText(product.node?.priceRange?.minVariantPrice?.amount),
    priceRange: {
      minVariantPrice: product.node.priceRange?.minVariantPrice?.amount,
      maxVariantPrice: product.node.priceRange?.maxVariantPrice?.amount,
    },
    variants: product.node?.variants?.edges && product.node.variants.edges.length > 0 ? product.node.variants.edges.map((v) => {
      return {
        id: v.node.id,
        title: v.node.title,
        sku: v.node.sku,
      }
    }) : [],
  }
}

const updatePriceText = (price: number) => {
  //Add trailing zero if needed
  let dollars = price.toString().split('.')[0]
  let cents = price.toString().split('.')[1]
  if (cents.length < 2) {
    return parseInt(`${dollars}.${cents}0`)
  }
  return price
}
