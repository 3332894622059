import { EventGQL } from '../Events/events.types'
import { API_ENDPOINTS } from '../utils/endpoints'
import { makeAPIRequest } from '../utils/request'
import type {
  PlayerData,
} from './player.types'

/**
 * Fetch player profile data by name
 */
export const fetchWLLPlayerBySlug = async (
  slug: string,
  year: number
): Promise<PlayerData | null> => {
  try {
    let plrRes = await makeAPIRequest(`${API_ENDPOINTS.PLAYERS}/${slug}`, {
      method: 'GET',
      apiVersion: '4',
      queryParams: {
        year,
      },
    })
    if (plrRes?.error) {
      plrRes.error?.message && console.error(plrRes.error?.message)
      return null
    }
    return plrRes?.data
  } catch (error) {
    return null
  }
}

/**
 * Updating a player's game stats
 */
export const fetchWLLPlayerEventStats = async (
  slug: string,
  year: number
): Promise<EventGQL[] | null> => {
  try {
    let plrRes = await makeAPIRequest(`${API_ENDPOINTS.PLAYERS}/${slug}/events/stats`, {
      method: 'GET',
      apiVersion: '4',
      queryParams: {
        year,
      },
    })
    if (plrRes?.error) {
      plrRes.error?.message && console.error(plrRes.error?.message)
      return null
    }
    return plrRes?.data
  } catch (error) {
    return null
  }
}
