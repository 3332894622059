import React, { createContext, useState } from 'react'
import {
  CURRENT_YEAR,
  STANDINGS_YEAR,
} from '../../Config/League/league.config'
import { SEASON_SELECTOR_TYPES, SeasonSelectorType } from '../../Constants/season'
import { SEASON_SEGMENT_NAMES } from '../../Utils'

export type SeasonType = 'regular' | 'post' | 'allstar' | 'champseries'
export type SeasonSelectors = { [key: string]: { seasonType: SeasonType; year: number } }
export type Season = { seasonType: SeasonType; year: number }
type SeasonContextType = {
  seasonSelect: SeasonSelectors
  onSeasonDropdownChange: (seasonOpts: Season, type?: SeasonSelectorType) => void
}

export const SeasonContext = createContext<SeasonContextType | null>(null)

export const SeasonProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const startingSeasonType = SEASON_SEGMENT_NAMES.champseries
  const [seasonSelect, setSeasonSelect] = useState<SeasonSelectors>({
    main: {
      seasonType: startingSeasonType,
      year: CURRENT_YEAR,
    },
    standings: {
      seasonType: startingSeasonType,
      year: STANDINGS_YEAR,
    },
  })

  const onSeasonDropdownChange = async (
    seasonOpts: Season,
    type: SeasonSelectorType = SEASON_SELECTOR_TYPES.MAIN
  ) => {
    setSeasonSelect((prevSeasonSelect) => ({
      ...prevSeasonSelect,
      [type]: seasonOpts,
    }))
  }

  return (
    <SeasonContext.Provider
      value={{
        seasonSelect,
        onSeasonDropdownChange,
      }}
    >
      {children}
    </SeasonContext.Provider>
  )
}
