

export const eventInProgressBySlug = `
query($slug: ID) {
  event(slug:$slug) {
    eventStatus
    visitorScore
    homeScore
    period
    clockTenths
    clockSeconds
    clockMinutes
  }
}
`

export const eventHubInfo = `
query($year: Int!, $week: Int!){
  eventHub(year: $year, week: $week) {
    week
    premierZoneTimes
    premierZoneLocations
    premierZoneMap
    gateTimes
    gateLocations
    boxOfficeLocation
    eventFAQ
    premierZoneMap
    venue
    venueMap
    updateMessage
    showUpdate
  }
}
`

export const eventInfoWithTeamAndPlayers = `
query($slug: ID) {
  event(slug: $slug) {
    id
    slugname
    externalId
    year
    startTime
    week
    venue
    description
    location
    gameNumber
    broadcaster
    eventStatus
    period
    homeScore
    homePeriodScores
    visitorScore
    visitorPeriodScores
    urlTicket
    league
    urlPreview
    seasonSegment
    urlStreaming
    addToCalendarId
    gameStatus
    awayTeam {
      ...teamInfo
    }
    homeTeam {
      ...teamInfo
    }
  }
  }

  fragment teamInfo on Team {
    officialId
    location
    locationCode
    fullName
    teamWins
    teamLosses
    teamTies
    conference
    stats(segment: event) {
      assists
      causedTurnovers
      clearAttempts
      clearPct
      clears
      faceoffPct
      faceoffs
      faceoffsLost
      faceoffsWon
      gamesPlayed
      goals
      goalsAgainst
      groundBalls
      manDownPct
      numPenalties
      onePointGoals
      pim
      powerPlayGoals
      powerPlayGoalsAgainst
      powerPlayGoalsAgainstPct
      rideAttempts
      saa
      savePct
      saves
      scores
      scoresAgainst
      shotClockExpirations
      shotPct
      shots
      shotsOnGoal
      shotsOnGoalPct
      timesManUp
      timesShortHanded
      turnovers
    }
    players {
        officialId
        profileUrl
        name
        firstName
        lastName
        jerseyNum
        position
        isCaptain
        rosterStatus
        slug
        expFromYear
        currentTeam{
          officialId
          jerseyNum
          position
          fullName
          injuryStatus
          conference
        }
      stats(segment: event) {
        points
        faceoffPct
        shotPct
        savePct
        shotsOnGoalPct
        onePointGoals
        scoresAgainst
        saa
        gamesPlayed
        goals
        assists
        groundBalls
        turnovers
        causedTurnovers
        faceoffsWon
        faceoffsLost
        faceoffs
        shots
        goalsAgainst
        numPenalties
        pim
        pimValue
        saves
        powerPlayGoals
        powerPlayShots
        shortHandedGoals
        shortHandedShots
        shortHandedGoalsAgainst
        powerPlayGoalsAgainst
        shotsOnGoal
        tof
        goalieWins
        goalieLosses
        goalieTies
        GAA
        foRecord
      }
    }
    }
`

