import { callGraphQL, makeAPIRequest } from '../utils/request'
import type {
  EventResponse,
  EventGQL
} from './events.types'
import {
  eventInProgressBySlug,
} from './events.queries'
import { API_ENDPOINTS } from '../utils/endpoints'

/**
 * Fetch event info for particular officialId
 */
export async function fetchEventInProgressBySlugGQL(
  slug: string
): Promise<EventResponse | null> {
  try {
    return callGraphQL<EventResponse>(eventInProgressBySlug, {
      slug: slug,
    })
  } catch (error) {
    return null
  }
}

// WOMENS LACROSSE LEAGUE //
export const fetchWLLSeasonEvents = async (year: number, includeCS?: boolean): Promise<EventGQL[] | null> => {
  try {
    let evRes = await makeAPIRequest(API_ENDPOINTS.SEASON_EVENTS, {
      method: 'GET',
      apiVersion: '4',
      queryParams: {
        year,
        includeCS: includeCS || false
      }
    })
    if(evRes?.error){
      evRes.error?.message && console.error(evRes.error?.message)
      return null
    }
    return evRes?.data
  } catch (error) {
    return null
  }
}