import { GameLog } from '../../Api'
import { getFullTeamName } from '../../Utils'
import { getYearMonthDay } from '../../Utils/formatting'

export type Event = {
  day: number
  month: number
  year: number
  teamId: string
  teamName: string
}

export function eventIdParse(evId: string, teamId: string): any {
  const parts = evId.split('_')
  if (parts.length !== 7) return null
  const teams = parts[5].split('@').filter((t) => t !== teamId)
  if (teams.length < 1) return null
  const res: Event = {
    year: parseInt(parts[2]),
    month: parseInt(parts[3]),
    day: parseInt(parts[4]),
    teamId: teams[0],
    teamName: getFullTeamName(teams[0]) || '',
  }
  return res
}

export function eventParse(log: GameLog): any {
  const [year, month, day] = getYearMonthDay(log.startTime)
  const res: Event = {
    year: year,
    month: month,
    day: day,
    teamId: log.opponent,
    teamName: getFullTeamName(log.opponent) || '',
  }
  return res
}