export const USER_ENDPOINTS = {
    GET_USER: 'get-create-user',
    UPDATE_USER: 'user',
    GET_OR_CREATE_USER: 'users',
}

export const GAMIFCATION_ENDPOINTS = {
    GET_CONFIG: 'configuration',
    ENGAGEMENT: `user-engagement`,
}

export const API_ENDPOINTS = {
    SEASON_EVENTS: 'wll/events',
    GET_FEED: 'home-feed',
    GET_FEED_BY_ID: 'feed-by-id',
    GET_FEED_BY_TAG: 'feed-by-tag',
    GET_GAME_HIGHLIGHTS: 'game-highlights',
    TEAMS: 'wll/teams',
    PLAYERS: 'wll/players',
    STANDINGS: 'wll/standings',
}