export type PackedMap = { [key: string]: [string, string, string] }
export type StatListType = {
  career: string[]
  season: string[]
  game: string[]
  fav: string[]
}
export type StatMapType = { name: string; longName: string; field: string }
export type StatTypes = keyof StatListType
export type FilteredResult = StatMapType & { value: number | string | null | undefined }

// Stat mapping
// [short name, long name, field name]
export const statFields: PackedMap = {
  '1G': ['1G', '1Pt Goals', 'onePointGoals'],
  '1ptG': ['1pt G', '1Pt Goals', 'onePointGoals'],
  'G': ['G', 'Goals', 'goals'],
  A: ['A', 'Assists', 'assists'],
  CT: ['CT', 'Caused Turnovers', 'causedTurnovers'],
  FO: ['FO', 'Faceoffs', 'foRecord'],
  'FO%': ['FO %', 'Faceoff %', 'faceoffPct'],
  GB: ['GB', 'Groundballs', 'groundBalls'],
  GP: ['GP', 'Games Played', 'gamesPlayed'],
  P: ['P', 'Points', 'points'],
  Pen: ['Pen', 'Penalties', 'numPenalties'],
  PIM: ['PIM', 'Penalty Minutes', 'pimValue'],
  PowG: ['PowG', 'Power Play Goals', 'powerPlayGoals'],
  SA: ['SA', 'Scores Against', 'scoresAgainst'],
  SAA: ['SAA', 'Scores Against Avg.', 'saa'],
  Sh: ['Sh', 'Shots', 'shots'],
  'Sh%': ['Sh%', 'Shot %', 'shotPct'],
  SOG: ['SOG', 'Shots On Goal', 'shotsOnGoal'],
  'SOG%': ['SOG%', 'Shots On Goal %', 'shotsOnGoalPct'],
  Sv: ['Sv', 'Saves', 'saves'],
  'Sv%': ['Sv %', 'Save %', 'savePct'],
  TO: ['TO', 'Turnovers', 'turnovers'],
  TCH: ['TCH', 'Touches', 'touches'],
  PAS: ['PAS', 'Total Passes', 'totalPasses'],
}

export const fullNameStatLabels = Object.fromEntries(
  Object.values(statFields).map(([_, fullLabel, key]) => [key, fullLabel])
)

/// SSDM, LSM and D
export const defenseStatHeaderList: StatListType = {
  career: ['GP', 'CT', 'GB', 'P', 'G', 'A', 'PIM'],
  season: [
    'GP',
    'CT',
    'GB',
    'P',
    'G',
    'A',
    'Sh',
    'Sh%',
    'SOG',
    'TO',
    'Pen',
    'PIM',
  ],
  game: ['CT', 'GB', 'P', 'Pen', 'Sh', 'SOG'],
  fav: ['CT', 'GB', 'P'],
}

// FOR POSTIONS: FO
export const faceoffStatHeaderList: StatListType = {
  career: ['GP', 'FO', 'FO%', 'P', 'G', 'A', 'GB'],
  season: [
    'GP',
    'FO',
    'FO%',
    'P',
    'G',
    'A',
    'GB',
    'Sh',
    'Sh%',
    'SOG',
    'TO',
    'Pen',
    'PIM',
  ],
  game: ['FO', 'FO%', 'P', 'A', 'GB', 'Sh', 'SOG'],
  fav: ['FO%', 'P', 'GB'],
}

// FOR POSTIONS: G
export const goalieStatHeaderList: StatListType = {
  career: ['GP', 'Sv%', 'Sv', 'SA', 'P', ' G', 'A', 'GB'],
  season: ['GP', 'Sv%', 'Sv', 'SAA', 'SA', 'P', 'CT', 'GB', 'TO', 'PIM'],
  game: ['SA', 'Sv%', 'Sv', 'P', 'CT', 'GB', 'TO', 'PIM'],
  fav: ['Sv%', 'Sv'],
}

// FOR POSTIONS: M and A
export const offenseStatHeaderList: StatListType = {
  career: ['GP', 'P', 'G', 'A', 'Sh%',  'GB', 'PowG', 'TO'],
  season: [
    'GP',
    'P',
    'G',
    'A',
    'Sh',
    'Sh%',
    '2PtSh',
    '2PtSh%',
    'SOG',
    'SOG%',
    'GB',
    'TO',
    'PowG',
    'Pen',
    'PIM',
  ],
  game: ['P', 'G', 'A', 'Sh', 'Sh%', 'SOG', 'GB', 'PowG', 'TO', 'TCH', 'PAS'],
  fav: ['P', 'G', 'A'],
}

export const positionStatHeaderLists: { [key: string]: StatListType } = {
  LSM: defenseStatHeaderList,
  D: defenseStatHeaderList,
  FO: faceoffStatHeaderList,
  G: goalieStatHeaderList,
  M: offenseStatHeaderList,
  A: offenseStatHeaderList,
  F: offenseStatHeaderList,
}

// Stat mapping
// [short name, long name, field name]
export const offensiveSeasonStatsMap: PackedMap = {
  GP: ['GP', 'Games Played', 'gamesPlayed'],
  P: ['P', 'Points', 'points'],
  'G': ['G', 'Goals', 'onePointGoals'],
  A: ['A', 'Assists', 'assists'],
  Sh: ['Sh', 'Shots', 'shots'],
  'Sh%': ['Sh%', 'Shot %', 'shotPct'],
  GB: ['GB', 'Groundballs', 'groundBalls'],
  TO: ['TO', 'Turnovers', 'turnovers'],
  CT: ['CT', 'Caused Turnovers', 'causedTurnovers'],
  SOG: ['SOG', 'Shots On Goal', 'shotsOnGoal'],
  'SOG%': ['SOG%', 'Shots On Goal %', 'shotsOnGoalPct'],
  PowG: ['PowG', 'Power Play Goals', 'powerPlayGoals'],
  Pen: ['Pen', 'Penalties', 'numPenalties'],
  PIM: ['PIM', 'Penalty Minutes', 'pimValue'],
  FO: ['FO', 'Faceoffs', 'foRecord'],
  'FO%': ['FO %', 'Faceoff %', 'faceoffPct'],
  Sv: ['Sv', 'Saves', 'saves'],
}

// [short name, long name, field name]
export const defensiveSeasonStatsMap: PackedMap = {
  GP: ['GP', 'Games Played', 'gamesPlayed'],
  GB: ['GB', 'Groundballs', 'groundBalls'],
  CT: ['CT', 'Caused Turnovers', 'causedTurnovers'],
  P: ['P', 'Points', 'points'],
  'G': ['G', 'Goals', 'onePointGoals'],
  A: ['A', 'Assists', 'assists'],
  Sh: ['Sh', 'Shots', 'shots'],
  'Sh%': ['Sh%', 'Shot %', 'shotPct'],
  TO: ['TO', 'Turnovers', 'turnovers'],
  SOG: ['SOG', 'Shots On Goal', 'shotsOnGoal'],
  'SOG%': ['SOG%', 'Shots On Goal %', 'shotsOnGoalPct'],
  PowG: ['PowG', 'Power Play Goals', 'powerPlayGoals'],
  Pen: ['Pen', 'Penalties', 'numPenalties'],
  PIM: ['PIM', 'Penalty Minutes', 'pimValue'],
  FO: ['FO', 'Faceoffs', 'foRecord'],
  'FO%': ['FO %', 'Faceoff %', 'faceoffPct'],
  Sv: ['Sv', 'Saves', 'saves'],
}

// [short name, long name, field name]
export const faceoffSeasonStatsMap: PackedMap = {
  GP: ['GP', 'Games Played', 'gamesPlayed'],
  FO: ['FO', 'Faceoffs', 'foRecord'],
  'FO%': ['FO %', 'Faceoff %', 'faceoffPct'],
  GB: ['GB', 'Groundballs', 'groundBalls'],
  P: ['P', 'Points', 'points'],
  'G': ['G', 'Goals', 'onePointGoals'],
  A: ['A', 'Assists', 'assists'],
  Sh: ['Sh', 'Shots', 'shots'],
  'Sh%': ['Sh%', 'Shot %', 'shotPct'],
  TO: ['TO', 'Turnovers', 'turnovers'],
  CT: ['CT', 'Caused Turnovers', 'causedTurnovers'],
  SOG: ['SOG', 'Shots On Goal', 'shotsOnGoal'],
  'SOG%': ['SOG%', 'Shots On Goal %', 'shotsOnGoalPct'],
  PowG: ['PowG', 'Power Play Goals', 'powerPlayGoals'],
  Pen: ['Pen', 'Penalties', 'numPenalties'],
  PIM: ['PIM', 'Penalty Minutes', 'pimValue'],
  Sv: ['Sv', 'Saves', 'saves'],
}

export const goalieSeasonStatsMap: PackedMap = {
  GP: ['GP', 'Games Played', 'gamesPlayed'],
  Sv: ['Sv', 'Saves', 'saves'],
  'Sv%': ['Sv %', 'Save %', 'savePct'],
  SAA: ['SAA', 'Scores Against Avg.', 'saa'],
  SA: ['SA', 'Scores Against', 'scoresAgainst'],
  GA: ['GA', 'Goals Against', 'goalsAgainst'],
  PPGA: ['PPGA', 'Power Play Goals Against', 'powerPlayGoalsAgainst'],
  P: ['P', 'Points', 'points'],
  'G': ['G', 'Goals', 'onePointGoals'],
  A: ['A', 'Assists', 'assists'],
  Sh: ['Sh', 'Shots', 'shots'],
  'Sh%': ['Sh%', 'Shot %', 'shotPct'],
  GB: ['GB', 'Groundballs', 'groundBalls'],
  TO: ['TO', 'Turnovers', 'turnovers'],
  CT: ['CT', 'Caused Turnovers', 'causedTurnovers'],
  SOG: ['SOG', 'Shots On Goal', 'shotsOnGoal'],
  'SOG%': ['SOG%', 'Shots On Goal %', 'shotsOnGoalPct'],
  Pen: ['Pen', 'Penalties', 'numPenalties'],
  PIM: ['PIM', 'Penalty Minutes', 'pimValue'],
}

export const positionSeasonStatHeadersLists: { [key: string]: PackedMap } = {
  LSM: defensiveSeasonStatsMap,
  D: defensiveSeasonStatsMap,
  FO: faceoffSeasonStatsMap,
  G: goalieSeasonStatsMap,
  M: offensiveSeasonStatsMap,
  A: offensiveSeasonStatsMap,
  F: offensiveSeasonStatsMap,
}