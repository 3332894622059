import React from 'react'
import { IconProps } from './types'
import { styled } from '@mui/system'
import { colors } from '../consts'
import { KeyboardArrowRightIcon } from '../LazyComponents/lazyIcons'

export const Tickets: React.FC<
  React.PropsWithChildren<IconProps & { fill?: string }>
> = ({ width, height, className, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 510 510"
    className={className}
    width={width}
    height={height}
    aria-label="Tickets Icon"
  >
    <path
      fill={fill}
      d="M488.5 345.7a34 34 0 0021.5-31.6v-64.3c0-19-15.5-34.6-34.6-34.6H472a34.6 34.6 0 00-3-45.6l-45.4-45.4a34 34 0 00-37.6-7.2c-6.8 3-14.9 1.5-20.1-3.7a18.2 18.2 0 01-3.8-20.1 34 34 0 00-7-37.6l-45.6-45.5a34.6 34.6 0 00-48.9 0l-205 205.1h-21c-19 0-34.5 15.5-34.5 34.6v64.3a34 34 0 0021.5 31.6c7 2.7 11.6 9.5 11.6 16.9s-4.7 14.2-11.6 16.9A34 34 0 000 411v64.3c0 19 15.5 34.6 34.6 34.6h440.8c19 0 34.6-15.5 34.6-34.6v-64.3a34 34 0 00-21.5-31.6c-7-2.7-11.6-9.5-11.6-16.9s4.7-14.2 11.6-16.9zM281.7 31.3a4.6 4.6 0 016.5 0l45.5 45.5a3.8 3.8 0 01.9 4.2c-8 18-4 39.6 10 53.5 14 14 35.4 18 53.4 10 1.4-.6 3.1-.3 4.3.9l45.5 45.5a4.6 4.6 0 010 6.4l-18 18h-78l-127-127zm-78.1 78.2l105.7 105.7H97.9zM30 475.5V411c0-1.6 1-3 2.4-3.6 18.3-7.2 30.7-25.2 30.7-44.9S50.7 325 32.4 317.7c-1.4-.5-2.4-2-2.4-3.6v-64.3c0-2.5 2-4.6 4.6-4.6H375V480H34.6c-2.5 0-4.6-2-4.6-4.6zM480 314c0 1.6-1 3.1-2.4 3.6a48.3 48.3 0 000 89.7c1.4.6 2.4 2 2.4 3.7v64.3c0 2.5-2 4.6-4.6 4.6H405V245.2h70.4c2.5 0 4.6 2 4.6 4.6z"
    />
    <path
      className="symbolFillColor"
      fill={fill}
      d="M105 285h225v30H105zm0 60h225v30H105zm0 60h225v30H105z"
    />
  </svg>
)

export const BarChart: React.FC<
  React.PropsWithChildren<IconProps & { fill?: string }>
> = ({ width, height, className, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
    width={width}
    height={height}
    aria-label="Bar Chart Icon"
  >
    <path
      fill={fill}
      d="M488.4 492h-22V173.5a27 27 0 00-26.8-26.8h-49a27 27 0 00-27 26.8V492H308V317.8A27 27 0 00281 291h-49a27 27 0 00-26.9 27V492h-55.7v-90.2a27 27 0 00-26.9-26.9h-49a27 27 0 00-26.9 26.9V492H23.6a10 10 0 100 20h464.8a10 10 0 100-20zm-358.9 0H66.7v-90.2c0-3.8 3.1-6.9 6.9-6.9h49c3.8 0 6.9 3.1 6.9 6.9V492zm158.5 0h-62.8V317.8c0-3.8 3-6.9 6.9-6.9h49c3.8 0 6.9 3.1 6.9 7V492zm158.5 0h-62.8V173.5c0-3.8 3-6.8 6.9-6.8h49c3.8 0 6.9 3 6.9 6.8V492zm20-481.5A10 10 0 00455.8 0H395a10 10 0 100 20h37.3l-98.8 98.9-37.3-37.3a10 10 0 00-14.2 0L102.3 261.3a10 10 0 0014.1 14.2l172.7-172.7 37.3 37.3a10 10 0 0014.1 0l106-106V68a10 10 0 1020 0V11v-.5z"
    />
    <circle className="symbolFillColor" cx="75.6" cy="303.3" r="10" />
  </svg>
)

export const ShoppingCart: React.FC<
  React.PropsWithChildren<IconProps & { fill?: string }>
> = ({ width, height, className, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    className={className}
    width={width}
    height={height}
    aria-label="Shopping Cart Icon"
  >
    <path
      fill={fill}
      d="M165 300h272a15 15 0 0014.4-10.9l60-210A15 15 0 00497 60H130.4l-10.8-48.3A15 15 0 00105 0H15a15 15 0 100 30h78L147 273.7A45 45 0 00165 360h272a15 15 0 100-30H165a15 15 0 010-30zM477 90l-51.4 180H177L137 90h340.1zM150 405a45 45 0 1090.1-.1 45 45 0 00-90.1.1zm45-15a15 15 0 110 30 15 15 0 010-30zm167 15a45 45 0 1090.1-.1 45 45 0 00-90.1.1zm45-15a15 15 0 110 30 15 15 0 010-30zm0 0"
    />
  </svg>
)

export const Clipboard: React.FC<
  React.PropsWithChildren<IconProps & { fill?: string }>
> = ({ width, height, className, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 439 439"
    className={className}
    width={width}
    height={height}
    fill={fill}
    aria-label="Clipboard Icon"
  >
    <path
      fill={fill}
      d="M348 57.5h-39.7V39.7c0-5.7-6.3-8.3-12-8.3h-30.9C258.1 10.4 239.8 0 219 0a47.5 47.5 0 00-46.5 31.4h-30.3c-5.7 0-11.5 2.6-11.5 8.3v17.8H91A44.4 44.4 0 0047 99.8v299.4c0 23 21 39.7 44 39.7h257c23 0 43.9-16.7 43.9-39.7V99.8a44.4 44.4 0 00-44-42.3zm-196.5-5.2h28.8c5-.6 9-4.4 9.9-9.4 3-13.5 14.9-23.2 28.7-23.5a29.8 29.8 0 0128.2 23.5c1 5.1 5.3 9 10.5 9.4h29.8V94H151.5V52.3zM371 399.2c0 11.5-11.5 18.8-23 18.8H91c-11.6 0-23-7.3-23-18.8V99.8c1-12 11-21.2 23-21.4h39.6V105a11 11 0 0011.5 10h154.1c6 .3 11.3-4 12-10V78.4H348c12 .2 21.9 9.5 23 21.4v299.4z"
    />
    <path
      fill={fill}
      d="M179.2 233.6c-3.9-4.2-10.4-4.4-14.6-.6l-33.4 32-14.2-14.7c-3.9-4.1-10.4-4.4-14.6-.5-4 4.2-4 10.9 0 15.1l21.4 22c1.9 2 4.6 3.2 7.4 3 2.7 0 5.3-1 7.3-3l40.7-38.7a10 10 0 000-14.6zm150 22.4H209a10.4 10.4 0 100 21h120.2a10.4 10.4 0 100-21zm-150-106c-3.9-4.2-10.4-4.4-14.6-.5l-33.4 31.8-14.2-14.6c-3.9-4.1-10.4-4.4-14.6-.5-4 4.2-4 10.9 0 15.1l21.4 22c1.9 2 4.6 3.2 7.4 3.1 2.7 0 5.3-1.2 7.3-3.1l40.7-38.7a10 10 0 000-14.6zm150 22.4H209a10.4 10.4 0 100 21h120.2a10.4 10.4 0 100-21zm-150 144.8c-3.9-4.2-10.4-4.4-14.6-.6l-33.4 32-14.2-14.7c-3.9-4.2-10.4-4.4-14.6-.5-4 4.2-4 10.9 0 15.1l21.4 22c1.9 2 4.6 3.2 7.4 3 2.7 0 5.3-1 7.3-3l40.7-38.7a10 10 0 000-14.6zm150 22.4H209a10.4 10.4 0 100 21h120.2a10.4 10.4 0 100-21z"
    />
  </svg>
)

export const Crown: React.FC<React.PropsWithChildren<IconProps>> = ({
  width,
  height,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 120 68"
    className={className}
    width={width}
    height={height}
    aria-label="Crown Icon"
  >
    <path
      strokeWidth="4"
      stroke="#000"
      fill="#ffcd00"
      d="M59.8 2l28.9 36.1L117.6 2l-7.2 65H9.2L2 2l28.9 36.1L59.8 2z"
    />
  </svg>
)

export const Play: React.FC<React.PropsWithChildren<IconProps & { fill?: string }>> = ({
  fill,
  width,
  height,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 510 510"
    className={className}
    width={width}
    height={height}
    aria-label="Play Icon"
  >
    <path
      fill={fill}
      d="M204 369.8L357 255 204 140.2v229.6zM255 0C114.7 0 0 114.8 0 255s114.8 255 255 255 255-114.8 255-255S395.2 0 255 0zm0 459c-112.2 0-204-91.8-204-204S142.8 51 255 51s204 91.8 204 204-91.8 204-204 204z"
    />
  </svg>
)

export const Left: React.FC<React.PropsWithChildren<IconProps>> = ({
  width,
  height,
  fill,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    className={className}
    width={width}
    height={height}
    fill={fill}
    aria-label="Left Arrow Icon"
  >
    <path
      fill={fill}
      d="m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z"
    />
  </svg>
)

export const Right: React.FC<React.PropsWithChildren<IconProps>> = ({
  width,
  height,
  fill,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 80 100"
    className={className}
    width={width}
    height={height}
    fill={fill}
    aria-label="Right Arrow Icon"
  >
    <polygon fill={fill} points="0,0 80,50 0,100" />
  </svg>
)

export const Spinner: React.FC<
  React.PropsWithChildren<IconProps & { fill?: string }>
> = ({ width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 200 200"
    aria-label="Spinner Icon"
  >
    <path
      className="symbolStrokeColor"
      strokeWidth="20"
      strokeLinecap="round"
      fill="none"
      d="m189,100c0,-49 -40,-89 -89,-89c-49,0 -89,40 -89,89"
      stroke={fill}
    >
      <animateTransform
        attributeType="xml"
        attributeName="transform"
        type="rotate"
        from="0 100 100"
        to="360 100 100"
        dur="1s"
        repeatCount="indefinite"
      />
    </path>
  </svg>
)

export const AddToCal: React.FC<React.PropsWithChildren<IconProps>> = ({
  width,
  height,
  className,
  fill,
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 615 615"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    aria-label="Add to Calendar Icon"
  >
    <g>
      <g>
        <path
          fill={fill}
          d="M499.641,320.573c-12.207-3.251-25.021-5.011-38.25-5.011c-1.602,0-3.189,0.071-4.781,0.119
			c-78.843,2.506-142.118,66.556-143.375,145.709c-0.015,0.799-0.062,1.587-0.062,2.391c0,15.85,2.515,31.102,7.119,45.422
			C339.474,568.835,395.381,612,461.391,612c81.859,0,148.219-66.359,148.219-148.219
			C609.609,395.151,562.954,337.441,499.641,320.573z M461.391,561.797c-54.133,0-98.016-43.883-98.016-98.016
			s43.883-98.016,98.016-98.016s98.016,43.883,98.016,98.016S515.523,561.797,461.391,561.797z"
        />
        <polygon
          fill={fill}
          points="475.734,396.844 442.266,396.844 442.266,449.438 389.672,449.438 389.672,482.906 442.266,482.906 
			442.266,535.5 475.734,535.5 475.734,482.906 528.328,482.906 528.328,449.438 475.734,449.438 		"
        />
        <path
          fill={fill}
          d="M126.703,112.359c9.228,0,16.734-7.507,16.734-16.734V54.984v-38.25C143.438,7.507,135.931,0,126.703,0h-14.344
			c-9.228,0-16.734,7.507-16.734,16.734v38.25v40.641c0,9.228,7.506,16.734,16.734,16.734H126.703z"
        />
        <path
          fill={fill}
          d="M389.672,112.359c9.228,0,16.734-7.507,16.734-16.734V54.984v-38.25C406.406,7.507,398.899,0,389.672,0h-14.344
			c-9.228,0-16.734,7.507-16.734,16.734v38.25v40.641c0,9.228,7.507,16.734,16.734,16.734H389.672z"
        />
        <path
          fill={fill}
          d="M274.922,494.859c-2.333-11.6-3.572-23.586-3.572-35.859c0-4.021,0.177-7.999,0.435-11.953H74.109
			c-15.845,0-28.688-12.843-28.688-28.688v-229.5h411.188v88.707c3.165-0.163,6.354-0.253,9.562-0.253
			c11.437,0,22.61,1.109,33.469,3.141V93.234c0-21.124-17.126-38.25-38.25-38.25h-31.078v40.641c0,22.41-18.23,40.641-40.641,40.641
			h-14.344c-22.41,0-40.641-18.231-40.641-40.641V54.984H167.344v40.641c0,22.41-18.231,40.641-40.641,40.641h-14.344
			c-22.41,0-40.641-18.231-40.641-40.641V54.984H40.641c-21.124,0-38.25,17.126-38.25,38.25v363.375
			c0,21.124,17.126,38.25,38.25,38.25H274.922z"
        />
        <circle fill={fill} cx="137.165" cy="260.578" r="37.954" />
        <circle fill={fill} cx="251.016" cy="260.578" r="37.954" />
        <circle fill={fill} cx="364.867" cy="260.578" r="37.954" />
        <circle fill={fill} cx="251.016" cy="375.328" r="37.953" />
        <circle fill={fill} cx="137.165" cy="375.328" r="37.953" />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
)

export const Info: React.FC<React.PropsWithChildren<IconProps>> = ({
  width,
  height,
  fill,
  className,
}) => (
  <svg
    fill={fill || '#000000'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    width={width}
    height={height}
    aria-label="Info Icon"
  >
    {' '}
    <path d="M 12 2 C 6.4889971 2 2 6.4889971 2 12 C 2 17.511003 6.4889971 22 12 22 C 17.511003 22 22 17.511003 22 12 C 22 6.4889971 17.511003 2 12 2 z M 12 4 C 16.430123 4 20 7.5698774 20 12 C 20 16.430123 16.430123 20 12 20 C 7.5698774 20 4 16.430123 4 12 C 4 7.5698774 7.5698774 4 12 4 z M 11 7 L 11 9 L 13 9 L 13 7 L 11 7 z M 11 11 L 11 17 L 13 17 L 13 11 L 11 11 z" />
  </svg>
)

export const CirclePlus: React.FC<React.PropsWithChildren<IconProps>> = ({
  width,
  height,
  fill,
  className,
}) => (
  <svg
    fill={fill || '#000000'}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 100 100"
    width={width}
    height={height}
    aria-label="Circle Plus Icon"
  >
    <circle cx="50" cy="50" r="45" fill="none" stroke-width="7.5"></circle>
    <line x1="32.5" y1="50" x2="67.5" y2="50" stroke={fill} stroke-width="5"></line>
    <line x1="50" y1="32.5" x2="50" y2="67.5" stroke={fill} stroke-width="5"></line>
  </svg>
)

export const RightArrow = styled(KeyboardArrowRightIcon)<IconProps>(
  ({ fill, width, theme }) => ({
    color: fill || colors.gray.gray20,
    fontSize: width || 30,
  })
)

export const getMapIc = () => {
  return 'https://premierlacrosseleague.com/wp-content/uploads/2022/05/map_ic.png'
}
