export const logBrazeEvent = async (name: string, params?: any | undefined) => {
  try {
    const braze = await import('@braze/web-sdk')
    //Add league param
    params = params ? { ...params, league: 'wll' } : { league: 'wll' }
    braze.logCustomEvent(name, params)
  } catch (e) {
    console.log('Braze SDK load error:', e)
  }
}

export const setBrazeAttribute = async (attributeName: string, property: any) => {
  try {
    const braze = await import('@braze/web-sdk')
    const brazeUser = braze.getUser()
    if (brazeUser) {
      brazeUser.setCustomUserAttribute(attributeName, property)
    }
  } catch (e) {
    console.log(e)
  }
}
