import { Outlet } from 'react-router-dom'
import { lazy, Suspense } from 'react'

const PlayerProfileProvider = lazy(() =>
  import('../../Context').then((module) => ({ default: module.PlayerProfileProvider }))
)

export const PlayerProfileContextLayout: React.FC = () => {
  return (
    <Suspense fallback={<div />}>
      <PlayerProfileProvider>
        <Outlet />
      </PlayerProfileProvider>
    </Suspense>
  )
}
