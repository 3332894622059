import React from 'react'
import { ErrorCon } from './styles'
import { PLL_LINKS } from '../../Config/config'
import { Lk } from '../../Components/Links/Lk'
type ErrorProps = {
  errorNo?: number
  error?: Error | null | string
}

export const Error: React.FC<React.PropsWithChildren<ErrorProps>> = ({ errorNo }) => {
  return (
    <ErrorCon>
      <h1>404</h1>
      <img
        src="https://premierlacrosseleague.com/wp-content/uploads/2021/03/sad_chazz.jpg"
        alt=""
      />
      <h4>Whoops</h4>
      <p>Looks like you found Sad Chazz. He thinks you should try these:</p>
      <div className="linkCon">
        <Lk link={PLL_LINKS.schedule} alt='Schedule' classNameObj='link'>Schedule</Lk>
        <Lk link={PLL_LINKS.stats} alt='Stats'>Stats</Lk>
        <Lk link={`${PLL_LINKS.shop}?utm_source=Web_Main&utm_campaign=general_shop_link`} alt='Shop'>Shop</Lk>
      </div>
    </ErrorCon>
  )
}
