import React, { createContext, useState, useEffect, useContext } from 'react'
import {
  Feed,
  ShopProduct,
  fetchWLLSeasonEvents
} from '../../Api'
import {
  EVENTS_YEAR,
  hasChampSeriesStarted as showChampSeries,
} from '../../Config/League/league.config'
import type {
  EventGQL,
} from '../../Api'
import {
  checkHasChampSeriesStarted,
} from '../../Utils/Events/eventHelpers'
import { getFeed } from '../../Api/Feed/feed.api'
import { fetchShopCollection } from '../../Api/Shop/shop.api'
import { SHOP_COLLECTIONS } from '../../Config/Shop/shop.config'
import { ShopProductSortKeys } from '../../Api/Shop/shop.utils'
import { StorageContext } from '../StorageContext'
import { STORAGE_NAMES } from '../../Constants'

type LeagueContextType = {
  events: EventGQL[]
  eventsLoading: boolean
  feed: Feed | null
  isFetchingMainFeed: boolean
  feedLoading: boolean
  hasChampSeriesStarted: boolean
  bestSellingProducts: ShopProduct[]
  getTeamEvents: (team: string) => EventGQL[]
  updateEventInState: (event: EventGQL) => void
}

export const LeagueContext = createContext<LeagueContextType | null>(null)

export const LeagueProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const { isStorageReady, store, getStoredData } = useContext(StorageContext)!
  const [events, setEvents] = useState<EventGQL[]>([])
  const [eventsLoading, setEventsLoading] = useState<boolean>(true)
  const [feed, setFeed] = useState<Feed | null>(null)
  const [isFetchingMainFeed, setIsFetchingMainFeed] = useState<boolean>(false)
  const [feedLoading, setFeedLoading] = useState<boolean>(true)
  const [bestSellingProducts, setBestSellingProducts] = useState<ShopProduct[]>([])
  const [hasChampSeriesStarted, setHasChampSeriesStarted] = useState<boolean>(false)

  useEffect(() => {
    if (isStorageReady) {
      fetchData()
    }
  }, [isStorageReady])

  const fetchData = async (): Promise<any> => {
    await Promise.all([
      fetchEvents(),
      fetchFeed(),
      fetchMainShopCollection(),
    ])
  }

  const fetchEvents = async () => {
    // First try to fill from storage
    getStoredEvents()
    try {
      const eventsRes = await fetchWLLSeasonEvents(EVENTS_YEAR, showChampSeries)
      if (eventsRes && eventsRes.length > 0) {
        setEvents(eventsRes)
        setHasChampSeriesStarted(checkHasChampSeriesStarted(eventsRes))
        setEventsLoading(false)
        store(STORAGE_NAMES.seasonEvents, eventsRes)
      }
      setEventsLoading(false)
    } catch (err) {
      console.log('Error getting events', err)
      setEventsLoading(false)
    }
  }

  const getStoredEvents = async (): Promise<void> => {
    const storedData = await getStoredData(STORAGE_NAMES.seasonEvents)
    if (storedData?.data && storedData.data.length > 0) {
      setEvents(storedData.data)
      setEventsLoading(false)
    }
  }

  const fetchMainShopCollection = async () => {
    const { data, refetch } = await getStoredShopCollection()
    if (data && !refetch) return
    //Fetch in background if needed
    try {
      const collection = await fetchShopCollection(
        SHOP_COLLECTIONS.MAIN,
        ShopProductSortKeys.BEST_SELLING,
        10
      )
      if (collection?.products && collection.products.length > 0) {
        setBestSellingProducts(collection.products)
        store(STORAGE_NAMES.home_shop_collection, collection.products)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getStoredShopCollection = async (): Promise<{ data: any; refetch: boolean }> => {
    const storedData = await getStoredData(STORAGE_NAMES.home_shop_collection, 1800000)
    if (storedData?.data) {
      setBestSellingProducts(storedData?.data)
    }
    return storedData
  }

  const fetchFeed = async () => {
    getCachedFeed()
    if (isFetchingMainFeed) return
    if (!feed) {
      setIsFetchingMainFeed(true)
    }
    const feedRes = await getFeed({ tag: 'WLL', limit: 35, includeVideos: true })

    if (!feedRes || feedRes.length < 1) {
      setFeedLoading(false)
      return
    }
    const newFeed = {
      trending: feedRes.slice(0, 3),
      latest: feedRes.slice(3),
    }
    setFeed(newFeed)
    isFetchingMainFeed && setIsFetchingMainFeed(false)
    feedLoading && setFeedLoading(false)
    store(STORAGE_NAMES.home_feed, newFeed)
    return
  }

  const getCachedFeed = async (): Promise<void> => {
    const { data } = await getStoredData(STORAGE_NAMES.home_feed)
    if (data) {
      setFeed(data)
      setFeedLoading(false)
    }
  }

  const getTeamEvents = (team: string): EventGQL[] => {
    const teamEvents = events.filter(
      (event) =>
        event.homeTeam?.officialId === team || event.awayTeam?.officialId === team
    )
    return teamEvents
  }

  const updateEventInState = (event: EventGQL) => {
    setEvents(events.map((e) => e.id === event.id ? event : e))
    //Update in storage
    store(STORAGE_NAMES.seasonEvents, events)
  }

  return (
    <LeagueContext.Provider
      value={{
        events,
        eventsLoading,
        feed,
        hasChampSeriesStarted,
        isFetchingMainFeed,
        feedLoading,
        bestSellingProducts,
        getTeamEvents,
        updateEventInState,
      }}
    >
      {children}
    </LeagueContext.Provider>
  )
}
