import { Banner } from "../Components/Layout/PageHeaders/PageHeader"
import { MAIN_PLL_URL } from "../Config/config"

export const homeCalloutList = [
    {
        id: `cs_25_callout`,
        title: '25 Championship Series',
        image: `https://${MAIN_PLL_URL}/wp-content/uploads/2025/01/wll_cs_home_card.webp`,
        startTime: 1733558402,
        endTime: 1708416001,
        link: `https://${MAIN_PLL_URL}/championship-series`,
        buttonText: 'Get Ready',
    }
]

export const HOME_BANNER: Banner = {
    id: 99999,
    sponsor: '',
    topUrl: `https://premierlacrosseleague.com/wp-content/uploads/2025/01/WLL_Web_Banner.webp`,
    topLink: ``,
    leftSideUrl: '',
    rightSideUrl: '',
    secondaryMobile: '',
    location: ['home'],
    startTime: 1737580299,
    endTime: 1769116299,
    tags: ['wll', 'maybelline']
}