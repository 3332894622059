import React, { createContext, useState, useEffect, useContext } from 'react'
import { fetchWLLStandings, TeamStanding } from '../../Api'
import { filterStandings } from '../../Utils'
import { SeasonContext } from '../SeasonContext'
import { Season } from '../SeasonContext/SeasonContext'
import { StorageContext } from '../StorageContext'
import { STORAGE_NAMES } from '../../Constants'
import { SEASON_SELECTOR_TYPES } from '../../Constants/season'

type StandingsContextType = {
  standings: TeamStanding[]
  loading: boolean
  getStandings: (seasonSelect: Season) => Promise<void>
}

export const StandingsContext = createContext<StandingsContextType | null>(null)

export const StandingsProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const selectorType = SEASON_SELECTOR_TYPES.STANDINGS
  const { seasonSelect } = useContext(SeasonContext)!
  const { isStorageReady, store, getStoredData } = useContext(StorageContext)!
  const [standings, setStandings] = useState<TeamStanding[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [yearObj, setYearObj] = useState<Season | null>()

  useEffect(() => {
    if (isStorageReady) {
      fetchData()
    }
  }, [isStorageReady])

  useEffect(() => {
    updateStandings()
  }, [seasonSelect[selectorType]])

  const fetchData = async (): Promise<any> => {
    await getStandings(seasonSelect[selectorType])
  }

  const updateStandings = async () => {
    if (
      seasonSelect[selectorType].year !== yearObj?.year ||
      seasonSelect[selectorType].seasonType !== yearObj?.seasonType
    ) {
      // Refetch & update standings
      setYearObj(seasonSelect[selectorType])
      await getStandings(seasonSelect[selectorType])
    }
  }

  const getStandings = async (season: Season) => {
    let champSeriesSelected = season.seasonType === 'champseries'
    if (!champSeriesSelected) {
      const { data, refetch } = await getCachedStandings()
      if (data && !refetch) return
    }
    try {
      let teams = await fetchWLLStandings(season.year)

      if (!teams) {
        setLoading(false)
        return
      }
      teams = filterStandings(teams, season)
      setStandings(teams)
      setLoading(false)
      if (!champSeriesSelected) {
        //Store reg season standings in cache
        store(`${STORAGE_NAMES.standings}_${season.year}`, teams)
      }
      return
    } catch (err: any) {
      setLoading(false)
      return err
    }
  }

  const getCachedStandings = async (): Promise<{ data: any; refetch: boolean }> => {
    const storedData = await getStoredData(
      `${STORAGE_NAMES.standings}_${seasonSelect.year}`,
      300000
    )
    if (storedData?.data) {
      const cleanStandings = filterStandings(storedData.data, seasonSelect[selectorType])
      setStandings(cleanStandings)
      setLoading(false)
    }
    return storedData
  }

  return (
    <StandingsContext.Provider
      value={{
        standings: standings,
        loading: loading,
        getStandings: getStandings,
      }}
    >
      {children}
    </StandingsContext.Provider>
  )
}
