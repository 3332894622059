import React from 'react'
import { styled } from '@mui/system'
import { BoxProps } from './Boxes'
import { colors } from '../consts'

type styleProps = {
  revOnWrap?: boolean
  wrap?: boolean
  noMargin?: boolean
  padding?: number
  border?: number
}

const BoxTwoColStyle = styled('div')<styleProps>(
  ({ revOnWrap, noMargin, padding, border, wrap, theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: revOnWrap ? 'wrap-reverse' : wrap ? 'wrap' : 'nowrap',
    '& > div': {
      borderRadius: border ? border : 6,
      flex: `0 1`,
      flexGrow: 1,
      background: colors.white,
      padding: padding ? theme.spacing(padding) : theme.spacing(4),
      marginTop: noMargin === true ? 0 : theme.spacing(2),
      [theme.breakpoints.down('md')]: {
        flex: `1 1`,
        flexGrow: 1,
        padding: padding ? theme.spacing(0) : theme.spacing(2),
        marginTop: noMargin === true ? 0 : theme.spacing(1),
      },
    },

    '& div:first-of-type': {
      marginRight: theme.spacing(2),
    },

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& div:first-of-type': {
        marginBottom: theme.spacing(4),
        marginRight: 0,

        ...(revOnWrap === true
          ? {
              order: 2,
              marginTop: theme.spacing(4),
            }
          : {
              marginBottom: theme.spacing(4),
            }),
      },
    },
  })
)

export const BoxWithTwoColumns: React.FC<
  React.PropsWithChildren<BoxProps & { reverseOnWrap?: boolean; wrap?: boolean }>
> = ({ noMargin, padding, children, border, reverseOnWrap, wrap }) => {
  return (
    <BoxTwoColStyle
      revOnWrap={reverseOnWrap}
      noMargin={noMargin}
      padding={padding}
      border={border}
      wrap={wrap}
    >
      {children}
    </BoxTwoColStyle>
  )
}
