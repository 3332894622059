import React from 'react'
import { styled } from '@mui/system'
import { FOOTER_HEIGHT } from './MobileMenuContainer'
import { colors } from '../consts'
import { NavComponent } from './Navbar/NavComponent'

type PageContainerProps = {
  color?: string
  sidePadding?: number
  vertPadding?: number
  useFooter?: boolean
  sponsor?: boolean
  fullScreen?: boolean
}

export const Root = styled('div')<PageContainerProps>(({ sponsor }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  height: '100vh',
}))

export const PageStyle = styled('div')<PageContainerProps>(
  ({ color, useFooter, fullScreen, sponsor, theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    overflowY: 'scroll',
    padding: sponsor ? theme.spacing(0, 0, 8, 0) : theme.spacing(0, 8, 8, 8),
    background: color,
    minHeight: '100vh',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '& ::-webkit-scrollbar-track': {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4, 4, 4),
      width: '100%',
    },

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0, 4, 0),
      marginBottom: useFooter ? FOOTER_HEIGHT : 0,
      width: '100%',
    },
    '& .inner': {
      display: 'flex',
      flexDirection: 'column',
      // alignItems: 'center',
      flex: 1,
      width: '100%',
      maxWidth: 1400,
    },
  })
)

export const PageContainer: React.FC<React.PropsWithChildren<PageContainerProps>> = ({
  color = colors.pageBackground,
  sponsor,
  sidePadding,
  vertPadding,
  fullScreen,
  useFooter,
  children,
}) => {
  return (
    <NavComponent>
      <PageStyle
        color={color}
        useFooter={useFooter}
        sidePadding={sidePadding}
        fullScreen={fullScreen}
        vertPadding={vertPadding}
        sponsor={sponsor}
      >
        <div className="inner">{children}</div>
      </PageStyle>
    </NavComponent>
  )
}
