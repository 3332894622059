import { useParams, useLocation } from 'react-router-dom'
import type { PlayerTabs, SeasonType } from '../Routing/urls'
import {
  YEAR_MIN,
  YEAR_MAX,
  CURRENT_YEAR,
  EVENTS_YEAR,
} from '../Config/League/league.config'

/**
 * Get current teamCode from window.teamCode variable
 * or from route params if exists. Empty string if
 * no code found.
 */
export const useTeamName = (): string => {
  const rparams: Record<string, string | undefined> = useParams()
  if (rparams.teamName) {
    return rparams.teamName
  }
  return ''
}

export type PlayerProfileUrlParams = {
  slug: string
  tab: PlayerTabs
  type: SeasonType
  year: number
  yr?: number
  statYear?: number
  eventsYear?: number
}

export type SingleVideoUrlParams = {
  mediaId: string
}
export type VideoPlaylistUrlParams = {
  playlistId: string
  tagId: string
  eventSlug: string
}

function parseTab(tab: any): PlayerTabs {
  switch (tab) {
    case 'media':
      return 'media'
    case 'stats':
      return 'stats'
    default:
      return 'profile'
  }
}

function parseYear(year: any): number {
  if (!year || typeof year !== 'string') return CURRENT_YEAR
  const y = parseInt(year)
  if (y < YEAR_MIN) return YEAR_MIN
  if (y > YEAR_MAX) return CURRENT_YEAR
  return y
}

function parseSeasonType(season: any): SeasonType {
  return season === 'post'
    ? 'post'
    : season === 'champseries'
    ? 'champseries'
    : 'regular'
}

/**
 * Get current player name and other params from URL
 */
export const usePlayerUrlParams = (): PlayerProfileUrlParams => {
  const rparams: Record<string, string | undefined> = useParams()
  const location = useLocation()
  const qparams = location.search
    ? Object.fromEntries(new URLSearchParams(location.search))
    : {}
  const result: PlayerProfileUrlParams = {
    slug: rparams.slug || '',
    tab: parseTab(rparams.tab),
    year: qparams.yr ? parseYear(qparams.yr) : parseYear(qparams.year),
    type: parseSeasonType(qparams.type),
    eventsYear: qparams.eventsYear ? parseYear(qparams.eventsYear) : EVENTS_YEAR,
  }
  return result
}

/**
 * Get single video id from URL
 */
export const useSingleVideoUrlParams = (): SingleVideoUrlParams => {
  const rparams: Record<string, string | undefined> = useParams()
  const result: SingleVideoUrlParams = {
    mediaId: rparams.mediaId || '',
  }
  return result
}

/**
 * Get video playlist id from URL
 */
export const useVideoPlaylistUrlParams = (): VideoPlaylistUrlParams => {
  const rparams: Record<string, string | undefined> = useParams()
  const result: VideoPlaylistUrlParams = {
    playlistId: rparams.playlistId || '',
    tagId: rparams.tag || '',
    eventSlug: rparams.eventSlug || '',
  }
  return result
}
