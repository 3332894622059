import { TeamStanding } from "../../Api"
import { Season } from "../../Context/SeasonContext/SeasonContext"
import { filterChampSeriesStandings } from "../Teams/teamHelpers"

export const filterStandings = (standings: TeamStanding[], season: Season) => {
  if (season.seasonType === 'champseries') {
    standings = filterChampSeriesStandings(standings, season.year)
  }
  return standings
}

  export const shouldShowTies = (teams: TeamStanding[]) => {
    //Check if a team has a tie or csTie
    let teamWithTie = teams.find(
      (it) => (it.ties && it.ties > 0) || (it.csTies && it.csTies > 0)
    )
    return teamWithTie ? true : false
  }