import { initializeApp, FirebaseApp } from 'firebase/app'
import type { Analytics } from 'firebase/analytics'
import { FIREBASE_CONFIG, USE_FIREBASE } from '../../Config/Firebase/config'
import {
  initializeAuth,
  indexedDBLocalPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
  browserPopupRedirectResolver,
  GoogleAuthProvider
} from 'firebase/auth'
import type { Auth } from 'firebase/auth'

export type GCredential = {
  clientId: string
  credential: string
  select_by: string
}

let firebaseApp: FirebaseApp | undefined = undefined
let auth: Auth | undefined = undefined
export let analytics: any | undefined = undefined
export let googleProvider: any | undefined = undefined

export async function initFirebase() {
  if (firebaseApp !== undefined) {
    return
  }
  if (USE_FIREBASE) {
    firebaseApp = initializeApp(FIREBASE_CONFIG)
    auth = initializeAuth(firebaseApp, {
      persistence: [
        indexedDBLocalPersistence,
        browserLocalPersistence,
        browserSessionPersistence,
      ],
      popupRedirectResolver: browserPopupRedirectResolver,
    })
    const { getAnalytics } = await import('firebase/analytics')
    analytics = getAnalytics(firebaseApp)
  }
}

export async function getFirAuth(): Promise<Auth | undefined> {
  if (auth === undefined) {
    await initFirebase()
  }
  if (auth === undefined) {
    throw new Error('no auth access')
  }
  return auth
}

export async function getGoogleAuthCredential(cred: string): Promise<any | undefined> {
  return GoogleAuthProvider.credential(cred)
}

export async function getFirAnalytics(): Promise<Analytics> {
  if (analytics === undefined) {
    await initFirebase()
  }
  if (analytics === undefined) {
    throw new Error('no analytics access')
  }
  return analytics
}

export const getGoogleProvider = async () => {
  if (googleProvider === undefined) {
    googleProvider = new GoogleAuthProvider()
  }
  return googleProvider
}

export const getActionCodeSettings = (redirect: string) => {
  return {
    url: redirect,
    handleCodeInApp: true,
    iOS: {
      bundleId: process.env.REACT_APP_IOS_BUNDLE_ID || 'com.pll.PLL',
    },
    android: {
      packageName: process.env.REACT_APP_ANDROID_BUNDLE_ID || 'com.pll.pllandroid',
      installApp: true,
    },
  }
}
