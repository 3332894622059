export const WLL_DOMAIN = 'thewll.com'
export const MAIN_WLL_URL = `https://${WLL_DOMAIN}`
export const MAIN_PLL_URL = 'premierlacrosseleague.com'
export const PLL_URL = `https://${MAIN_PLL_URL}`
export const API_ROOT_URL = `${process.env.REACT_APP_API_BASE_ROUTE}/api`
export const API_ROOT_WP = `https://${MAIN_PLL_URL}/wp-json/wp`
export const FLAG_URL = `https://${MAIN_PLL_URL}/wp-content/uploads/2021/03/`
export const PLL_IMAGE_URL = `https://${MAIN_PLL_URL}/wp-content/uploads`
export const Team_LOGO_URL = `https://img.${MAIN_PLL_URL}/Teams`
export const BROADCAST_IMG = `https://img.${MAIN_PLL_URL}/Logos`
export const REVERSE_BROADCAST_IMG = `https://img.${MAIN_PLL_URL}/Logos`
export const NEW_PLL_IMAGE_URL = `https://img.${MAIN_PLL_URL}`
export const ADD_EVENT_SCRIPT = 'https://addevent.com/libs/atc/1.6.1/atc.min.js'
export const FEVO_SCRIPT = 'https://offer.fevo.com/js/fevo.js'
export const GRAPHQL_ROOT_URL = process.env.REACT_APP_GRAPH_ROOT_URL || ''
export const GRAPHQL_ACCESS_KEY = process.env.REACT_APP_GRAPHQL_AUTH_KEY || ''
export const CHAMPIONSHIP_SERIES = `https://${MAIN_PLL_URL}/championship-series`
export const WLL_SLOGAN = `It's Our Time.`
export const windowSize = typeof window !== 'undefined' ? window.innerWidth : 1024

export const WLL_LINKS = {
  schedule: `/schedule`,
  tickets: `/schedule`,
  shop: `https://wllshop.com/`,
  championshipSeriesTickets: `${CHAMPIONSHIP_SERIES}/tickets`,
}
export const WLL_IMAGES = {
  wllShield: `${NEW_PLL_IMAGE_URL}/Logos/2024/wll_primary_logo.png`,
  siteHeader: `${PLL_IMAGE_URL}/2025/01/WLL-Primary-Fullcolor-White-Text.webp`,
  wllChampSeriesLogo: `https://premierlacrosseleague.com/wp-content/uploads/2024/12/2025_champ_series_PLL_primary.webp`,
  defaultProfile: `${PLL_IMAGE_URL}/2025/01/wll_profile_background.png`,
}

export const WLL_SOCIAL_URLS = {
  twitter: 'https://twitter.com/wlacrosseleague',
  instagram: 'https://instagram.com/wll/',
}

export const PLL_LINKS = {
  schedule: `${PLL_URL}/schedule`,
  tickets: `${PLL_URL}/schedule`,
  stats: `https://stats.${MAIN_PLL_URL}`,
  allstar: `${PLL_URL}/allstar`,
  shop: `https://shop.${MAIN_PLL_URL}`,
  rules: `https://pll-public-prod.s3.amazonaws.com/lacrosse/rules/2024+PLL+Rulebook.pdf`,
  youtubeChannel: 'https://www.youtube.com/channel/UCNUOJo_m8-w2yPSIC5DLK1g',
  watch: `https://plus.espn.com/pll`,
  championshipSeriesTickets: `${CHAMPIONSHIP_SERIES}/tickets`,
  championshipSeriesSchedule: `${CHAMPIONSHIP_SERIES}/schedule`,
  myTickets: `https://pll.gg/qrDfv9KD6Hb`,
  plln: `https://pll.gg/join-plln-web`,
}

export const PLL_IMAGES = {
  pllShield: `${PLL_IMAGE_URL}/2019/03/pll_logo.png`,
  champSeriesLogo: {
    webp: `https://premierlacrosseleague.com/wp-content/uploads/2024/12/2025_champ_series_PLL_primary.webp`,
    fallback: `https://premierlacrosseleague.com/wp-content/uploads/2024/12/2025_champ_series_PLL_primary.webp`,
  },
}

export const BROADCASTER_LINKS = {
  generalESPNPlus: `https://plus.espn.com/pll`,
}

export const yearDropdown = [{ label: '2025', value: 2025 }]

export const allYearsLabel = { label: 'All Years', value: 0 }

export const yearDropdownAllYears = [allYearsLabel, { label: '2025', value: 2025 }]
