import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import { initFirebase } from './Api/firebase'

declare global {
  interface Window {
    google: any
    adsbygoogle: any
    bc: any
    brazeInitialized?: boolean
  }
}

initFirebase()

const container = document.getElementById('wll-web')
const root = createRoot(container!)
root.render(<App />)
