import React from 'react'
import { Navbar } from './Navbar'
import { NavCon } from './styles'
import Footer from '../Footer/Footer'

type NavParams = {
  zIndex?: number
  children?: React.ReactNode
}

export const NavComponent: React.FC<NavParams> = ({ children, zIndex}) => {

  return (
    <NavCon>
      <Navbar />
      {children}
      <Footer />
    </NavCon>
  )
}
