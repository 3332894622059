import React from 'react'
import { IconButton } from '../../Icons'
import { AccountCircleOutlined } from '../../LazyComponents'
import { NavButtonCon } from './styles'
import { NAV_ACTION_ITEMS } from '../../../Constants/nav'

interface MobileNavProps {
  onNavigate: (path: string) => void
}

export const MobileNav: React.FC<MobileNavProps> = ({ onNavigate }) => {

  return (
    <>
      <div className="leftCol">
      </div>
      <div className="rightCol">
        <div className="navKeyActions">
          {NAV_ACTION_ITEMS.map((item) => (
            <NavButtonCon
              key={item.path}
              onClick={() => onNavigate(item.path)}
              withIcon={false}
            >
              <button>{item.label}</button>
            </NavButtonCon>
          ))}
        </div>
        <IconButton color="inherit" onClick={() => onNavigate('/login')} sx={{ ml: 1 }}>
          <AccountCircleOutlined
            color="inherit"
            style={{ color: 'fff', fontSize: 30 }}
          />
        </IconButton>
      </div>
    </>
  )
}
