import * as React from 'react'
import { Spinner } from '../Components/Icons'
import { colors } from '../Components/consts'
import { styled } from '@mui/system'

const LoadCon = styled('div')(({ theme }) => ({
  '@global': {
    '@keyframes loading_fade_in': {
      '0%': {
        opacity: 0,
      },
      '50%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
  },
  position: 'absolute',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  display: 'flex',
  '& svg': {
    width: theme.spacing(4),
    height: theme.spacing(4),
    marginRight: theme.spacing(2),
  },
}))

/**
 * Display loading indicator with delay
 */
export const Loading: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <LoadCon>
      <Spinner fill={colors.yellow} />
      Loading...
    </LoadCon>
  )
}
