import { styled } from '@mui/system'

type HProps = { hideOnMobile?: boolean }

/**
 * Responsive horizontal spacer
 */
export const HSpace = styled('div')<HProps>(
  ({ hideOnMobile, theme }) => ({
    height: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(2),
      display: hideOnMobile ? 'none' : 'block',
    },
  })
)
