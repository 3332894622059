import React, { useState, useEffect, useContext, useRef } from 'react'
import { logEvent } from '../../Utils'
import { Desc, Head2 } from '../Typography/Text.styles'
import { Btn } from '../Buttons/Btn'
import { ErrorStyle, InputStyle, StyledMuiTelInput } from './styles'
import { colors } from '../../Components/consts'
import { AuthContext } from '../../Api/firebase'
import { ANALYTICS_TAGS } from '../../Constants/analytics'

type PhoneSignInParams = {
  loginInput: string
  handleInputChange: (newVal: string) => string | undefined
}

export const PhoneSignIn: React.FC<PhoneSignInParams> = ({
  loginInput,
  handleInputChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { sendPhoneVerification } = useContext(AuthContext)!
  const [phone, setPhone] = useState<string>('')
  const [code, setCode] = useState<string>('')
  const [phoneResult, setPhoneResult] = useState<any | null>(null)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [showCodeInput, setShowCodeInput] = useState<boolean>(false)
  const [btnEnabled, setBtnEnabled] = useState<boolean>(false)
  const [matchIsValidTel, setMatchIsValidTel] = useState<Function | null>(null)

  useEffect(() => {
    if (loginInput.length > 0) setPhone(`+1` + loginInput)
    import('mui-tel-input')
      .then((module) => {
        setMatchIsValidTel(() => module.matchIsValidTel)
      })
      .catch((err) => console.error('Failed to load mui-tel-input:', err))
  }, [])

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [phone])

  useEffect(() => {
    if (phoneResult) {
      setShowCodeInput(true)
    }
  }, [phoneResult])

  const handlePhoneChange = (newValue: string) => {
    if (matchIsValidTel) {
      let valid = matchIsValidTel(newValue)
      setErrorMsg('')
      let item = handleInputChange(newValue)
      setPhone(newValue || '')
      if (valid) {
        setBtnEnabled(true)
      }
    }
  }

  const handlePhoneSignin = async () => {
    if (!btnEnabled) return
    setBtnEnabled(false)
    let result = await sendPhoneVerification(phone)
    if (result) {
      setPhoneResult(result)
      setBtnEnabled(true)
    } else {
      setErrorMsg('Trouble texting the code')
      setBtnEnabled(true)
    }
  }

  const handleCodeChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    let code = e.target.value
    setCode(code)
    if (code.length === 6) {
      setBtnEnabled(true)
    }
  }

  // Validate the code that user input
  const ValidateSMSCode = async () => {
    if (!btnEnabled) return
    setBtnEnabled(false)
    if (!phoneResult) {
      setErrorMsg('Error validating code')
      setBtnEnabled(true)
      return
    }
    try {
      let result = await phoneResult.confirm(code)
      // success
      logEvent(ANALYTICS_TAGS.phone_login_click, {
        path: window.location.href,
      })
    } catch (err) {
      alert('Something went wrong. Try again.')
      setBtnEnabled(true)
    }
  }

  return (
    <>
      <Head2 justify="center">Sign In With Email or Phone</Head2>
      {!showCodeInput && (
        <Desc justify="center">Send a text with a unique code to your phone.</Desc>
      )}
      {showCodeInput && (
        <Desc justify="center" textColor={colors.blue}>
          A text was sent to your phone. Code expires in 5 minutes.
        </Desc>
      )}
      {!showCodeInput && (
        <StyledMuiTelInput
          inputRef={inputRef}
          placeholder="Enter Phone Number"
          size="small"
          variant="outlined"
          value={phone}
          onChange={handlePhoneChange}
          defaultCountry={'US'}
        />
      )}
      {showCodeInput && (
        <InputStyle
          required
          type="number"
          variant="outlined"
          margin="normal"
          id="temailOutlined"
          helperText="Enter 6 digit code sent to phone"
          value={code}
          onChange={(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            handleCodeChange(e)
          }}
        />
      )}
      {!showCodeInput && (
        <>
          <div id="phone-signin-btn" />
          <Btn btnStyle="primary" disabled={!btnEnabled} handleClick={handlePhoneSignin}>
            Send Code
          </Btn>
        </>
      )}
      {showCodeInput && (
        <>
          <Btn disabled={!btnEnabled} btnStyle="primary" handleClick={ValidateSMSCode}>
            Sign In
          </Btn>
        </>
      )}
      <ErrorStyle>{errorMsg}</ErrorStyle>
    </>
  )
}
