import { colors, fontSizes } from '../../Components/consts'
import { styled } from '@mui/system'

export const ErrorCon = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  '& h1': {
    margin: theme.spacing(1),
    color: colors.yellow,
    fontSize: fontSizes.xlarge,
  },
  '& h4': {
    margin: theme.spacing(1, 0.5),
    color: colors.text,
    fontSize: fontSizes.large,
  },
  '& img': {
    width: 220,
    height: 300,
    borderRadius: 30,
    [theme.breakpoints.down('sm')]: {
      width: 146,
      height: 200,
      borderRadius: 15,
    },
  },
  '& p': {
    margin: theme.spacing(1, 0.5),
    color: colors.text,
    fontSize: fontSizes.medium,
    fontWeight: 600,
  },
  '& .linkCon': {
    margin: theme.spacing(2, 0.5),
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    '& a': {
      color: colors.text,
      fontSize: fontSizes.medium,
      fontWeight: 600,
      margin: theme.spacing(1, 0.5),
    },
    '& .link': {
      color: colors.text,
      fontSize: fontSizes.medium,
      fontWeight: 600,
      textDecoration: 'underline',
      cursor: 'pointer',
    }
  },
}))
