
export const BRAZE_EVENTS = {
    log_in: 'logged_in',
    on_site_check_in: 'on_site_check_in',
    team_page_view: 'team_page_view'
}


//Analytics dash: https://www.notion.so/premierlacrosseleague/Analytics-Tags-Database-ca224c3268ec4c7c914c408b3e68b396?pvs=4
export const ANALYTICS_TAGS = {
    
    // Login //
    login_click: 'login_click',
    phone_login_click: 'phone_login_click',
    email_login_click: 'email_login_click',
    google_login_click: 'google_login_click',
    login_cancelled: 'login_cancelled',

    // User //
    fav_team_click: 'fav_wll_team_click',


    // SEASON //
    season_selection_change: 'season_selection_change',
    week_selection_change: 'week_selection_change',
    standings_toggle_click: 'standings_toggle_click',
    boxscore_click: 'boxscore_click',
    schedule_btn_click: 'schedule_btn_click',
    schedule_week_click: 'schedule_week_click',
    tickets_click: 'ticket_click',
    standings_team_click: 'standings_team_click',

    // TEAM & PLAYER //
    player_roster_click: 'player_roster_click',
    leader_click: 'leader_click',

    // VIDEO //
    video_start: 'video_start',
    video_watched: 'video_watched',


    //OTHER //
    home_banner_click: 'home_banner_click',
    banner_click: 'banner_click',
    banner_impression: 'banner_impression',
    callout_card_click: 'callout_card_click',
    twitter_click: 'twitter_click',
    instagram_click: 'instagram_click',
    youtube_click: 'youtube_click',
    tiktok_click: 'tiktok_click',
    discord_click: 'discord_click',
}