import { makeAPIRequest } from '../utils/request'
import type { TeamGQL } from './team.types'
import { API_ENDPOINTS } from '../utils/endpoints'
import { PlayerData } from '../Players/player.types'

export const fetchWLLTeam = async (teamId: string): Promise<TeamGQL | null> => {
  try {
    let tmRes = await makeAPIRequest(`${API_ENDPOINTS.TEAMS}/${teamId}`, {
      method: 'GET',
      apiVersion: '4',
    })
    if (tmRes?.error) {
      tmRes.error?.message && console.error(tmRes.error?.message)
      return null
    }
    return tmRes?.data
  } catch (error) {
    return null
  }
}

export const fetchWLLTeamRoster = async (
  teamId: string,
  year: number
): Promise<PlayerData[] | null> => {
  try {
    let tmRes = await makeAPIRequest(`${API_ENDPOINTS.TEAMS}/${teamId}/roster`, {
      method: 'GET',
      apiVersion: '4',
      queryParams: {
        year,
      },
    })
    if (tmRes?.error) {
      tmRes.error?.message && console.error(tmRes.error?.message)
      return null
    }
    return tmRes?.data
  } catch (error) {
    return null
  }
}

export async function fetchWLLStandings(year: number) {
  try {
    const standingsRes = await makeAPIRequest(API_ENDPOINTS.STANDINGS, {
      method: 'GET',
      apiVersion: '4',
      queryParams: {
        year: year,
      },
    })
    if (standingsRes?.error) {
      standingsRes.error?.message && console.error(standingsRes.error?.message)
      return null
    }
    return standingsRes?.data
  } catch (error) {
    return null
  }
}
