import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FULL_VIDEO_VIEW_HEIGHT, VideoPlayerOptions } from '../../../../Constants/video'
import { calcDimensionsFromAspectRatio } from '../../../../Utils/Video/videoUtils'
import {
  PLL_VIDEO_ACCOUNT_ID,
  VIDEO_PLAYER_SCRIPT,
  VIDEO_PLAYER_SCRIPT_ID,
} from '../../../../Config/Video/config'
import { useDynamicTheme } from '../../../../Hooks/Theme/useDynamicTheme'

type VideoPlayerProps = {
  videoId: string | null
  playerId: string
  options: VideoPlayerOptions
  onVideoStart: () => void
  onVideoComplete: () => void
  onVideoPause: (watchedTime: number) => void
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videoId,
  playerId,
  options,
  onVideoStart,
  onVideoComplete,
  onVideoPause,
}) => {
  const [playerLoaded, setPlayerLoaded] = useState<boolean>(false)
  const [videoPlayer, setVideoPlayer] = useState<any>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const isMounted = useRef(true)
  const { isMobile } = useDynamicTheme()
  const videoWidth = `${calcDimensionsFromAspectRatio(
    options.aspectRatio,
    FULL_VIDEO_VIEW_HEIGHT,
    isMobile
  )}vh`

  const playerOptions = useMemo(
    () => ({
      ...options,
      videoId,
    }),
    [options, videoId]
  )

  useEffect(() => {
    const scriptId = VIDEO_PLAYER_SCRIPT_ID
    if (document.getElementById(scriptId)) {
      return // Script already loaded
    }
    let script = document.getElementById(scriptId) as HTMLScriptElement
    if (!script) {
      script = document.createElement('script')
      script.id = scriptId
      script.src = VIDEO_PLAYER_SCRIPT
      script.async = true
      document.body.appendChild(script)

      script.onload = () => {
        if (typeof window.bc === 'function' && videoRef.current) {
          const player = window.bc(videoRef.current, playerOptions)
          setVideoPlayer(player)
          player.on('loadedmetadata', () => {
            if (videoRef.current) {
              player.muted(options.muted)
              if (options.autoplay) {
                const promise = player.play()
                if (promise !== undefined) {
                  promise
                    .then((pr: any) => {
                      // Autoplay started!
                      console.log('Autoplay started')
                    })
                    .catch((error: any) => {
                      // Autoplay was prevented.
                      console.log('Autoplay prevented', error)
                    })
                }
              }
            }
          })
          setPlayerLoaded(true)
          //Analytics listeners
          player.on('analytics-beacon', function (e: any) {
            // console.log('sent a(n) ' + e.params.event + ' beacon!', e.params)
            sendVideoAnalytics(e.params.event, e.params)
          })
        } else {
          console.error('Player function not available.')
        }
      }
      // Failure callback
      script.onerror = (error) => {
        console.error('Error loading the Brightcove player:', error)
      }
    }

    const sendVideoAnalytics = (name: string, data: any) => {
      switch (name) {
        case 'video_view':
          onVideoStart()
          break
        case 'video_engagement':
          if (data?.forward_buffer_seconds && data?.duration) {
            const watchedTime = data.duration - data.forward_buffer_seconds
            const pctWatched = watchedTime / data.duration

            if (pctWatched >= 0.85) {
              onVideoComplete()
            }
          }
          break
        case 'video_pause':
          if (data?.forward_buffer_seconds > 0) {
            const watchedTime = (data?.duration ?? 0) - data.forward_buffer_seconds
            onVideoPause(watchedTime)
          } else {
            // Completed watching video
            onVideoComplete()
          }
          break
        default:
          break
      }
    }

    return () => {
      if (isMounted.current) {
        // Only dispose of the player when the component unmounts
        if (script && script.parentNode) {
          script.parentNode.removeChild(script)
        }
        if (window.bc && videoRef.current) {
          if (videoPlayer) {
            videoPlayer.dispose()
          }
        }
      }
    }
  }, [])

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  if (!videoId) return null

  return (
    <video
      id={`main-video-player-${playerId}-${videoId}`}
      ref={videoRef}
      className="video-js"
      style={{ width: videoWidth, height: `${FULL_VIDEO_VIEW_HEIGHT}vh` }}
      controls={options.controls}
      data-account={PLL_VIDEO_ACCOUNT_ID}
      data-player={playerId}
      data-embed="default"
      data-video-id={videoId}
    ></video>
  )
}
