import React, { lazy, Suspense } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { MainRoutes } from './Routing'
import {
  SHOP_API_VERSION,
  SHOP_STOREFRONT_TOKEN_PUBLIC,
  SHOP_STOREFRONT_URL,
} from './Config/Shop/shop.config'
import { ShopifyProvider } from '@shopify/hydrogen-react'
import { useDynamicTheme } from './Hooks/Theme/useDynamicTheme'

const ThemeProvider = lazy(() =>
  import('@mui/material/styles').then((module) => ({ default: module.ThemeProvider }))
)

const AuthProvider = lazy(() =>
  import('./Api/firebase').then((module) => ({ default: module.AuthProvider }))
)
const StorageProvider = lazy(() =>
  import('./Context').then((module) => ({ default: module.StorageProvider }))
)
const LinkProvider = lazy(() =>
  import('./Context').then((module) => ({ default: module.LinkProvider }))
)
const SeasonProvider = lazy(() =>
  import('./Context').then((module) => ({ default: module.SeasonProvider }))
)
const VideoProvider = lazy(() =>
  import('./Context').then((module) => ({ default: module.VideoProvider }))
)
const SnackbarProvider = lazy(() =>
  import('./Context/SnackbarContext').then((module) => ({
    default: module.SnackbarProvider,
  }))
)

const SlideupProvider = lazy(() =>
  import('./Context/SlideupContext').then((module) => ({
    default: module.SlideupProvider,
  }))
)

const App: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { theme } = useDynamicTheme()

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ShopifyProvider
          storeDomain={SHOP_STOREFRONT_URL}
          storefrontToken={SHOP_STOREFRONT_TOKEN_PUBLIC}
          storefrontApiVersion={SHOP_API_VERSION}
          countryIsoCode="US"
          languageIsoCode="EN"
        >
          <Suspense fallback={<></>}>
            <StorageProvider>
              <LinkProvider>
                  <SnackbarProvider>
                    <SlideupProvider>
                      <AuthProvider>
                        <SeasonProvider>
                          <VideoProvider>
                            <MainRoutes />
                          </VideoProvider>
                        </SeasonProvider>
                      </AuthProvider>
                    </SlideupProvider>
                  </SnackbarProvider>
              </LinkProvider>
            </StorageProvider>
          </Suspense>
        </ShopifyProvider>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default App
