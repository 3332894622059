import { styled } from '@mui/system'
import { colors, fontSizes } from '../../consts'
import { Img } from '../../Image/Img'

export const FooterContainer = styled('footer')(({ theme }) => ({
  backgroundColor: colors.black,
  color: colors.white,
  padding: theme.spacing(6, 4),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(4, 2),
  },
}))

export const FooterContent = styled('div')(({ theme }) => ({
  maxWidth: '1200px',
  margin: '0 auto',
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: theme.spacing(4),
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
    gap: theme.spacing(3),
    margin: theme.spacing(0, 2),
  },
}))

export const LogoSection = styled('div')(({ theme }) => ({
  gridColumn: '1',
  [theme.breakpoints.down('md')]: {
    gridColumn: 'auto',
  },
}))

export const Logo = styled(Img)({
  width: 120,
  height: 'auto',
})

export const FooterSection = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}))

export const SectionTitle = styled('h3')(({ theme }) => ({
  fontSize: fontSizes.medium,
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  color: colors.white,
}))

export const FooterLink = styled('a')(({ theme }) => ({
  color: colors.white,
  textDecoration: 'none',
  fontSize: fontSizes.small,
  lineHeight: '1.5',
  transition: 'opacity 0.2s ease',
  '&:hover': {
    opacity: 0.7,
  },
}))
