import { colors, fontSizes } from '../../consts'
import { styled } from '@mui/system'

export const UserCon = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  background: colors.white,
  borderRadius: 20,
  minHeight: '90vh',
  zIndex: 9,
  padding: theme.spacing(2, 4),

  '& > h3': {
    color: colors.text,
    fontSize: fontSizes.large,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: fontSizes.semiLarge,
    },
  },
  '& > p': {
    color: colors.helperText,
    fontSize: fontSizes.medium,
    fontWeight: 500,
    margin: theme.spacing(1, 'auto'),
  },
  '& .nameRow': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  '& .error': {
    color: '#cc0033',
    display: 'inline-block',
    fontSize: '12px',
    lineHeight: '15px',
    margin: '2px',
  },
}))

export const ItmCol = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  width: '100%',
  '& p': {
    textAlign: 'left',
    color: colors.helperText,
    fontSize: fontSizes.small,
    marginBottom: 2,
  },
  '& .textHelper': {
    color: colors.helperText,
    display: 'inline-block',
    fontSize: '11px',
    lineHeight: '15px',
    marginTop: '-2px',
  }
}))

export const SubmitCon = styled('div')(({ theme }) => ({
  display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    margin: theme.spacing(2, 1),
    width: '100%',
    '& .mainBtn': {
      padding: theme.spacing(1, 2),
      cursor: 'pointer',
      border: 'none',
      borderRadius: 12,
      background: colors.blue,
      color: colors.white,
      fontSize: fontSizes.medium,
      fontWeight: 'bold',
    },
    '& .loadingBtn': {
      padding: theme.spacing(1, 2),
      cursor: 'pointer',
      border: 'none',
      borderRadius: 12,
      background: colors.lightestGrey,
      color: colors.white,
      fontSize: fontSizes.medium,
      fontWeight: 'bold',
    },
    '& .later': {
      marginRight: 3,
      cursor: 'pointer',
      border: 'none',
      background: 'none',
      color: colors.helperText,
      fontSize: fontSizes.medium,
    },
}))
