import React, { useState, useEffect, useRef, memo } from 'react'
import { ImageWrapper } from './styles'

type ImgParams = {
  src: string
  alt: string
  className?: string
}

export const Img: React.FC<ImgParams> = memo(({ src, alt, className }) => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const imageRef = useRef<HTMLImageElement | null>(null)
  const [altText, setAltText] = useState<string | undefined>('')

  useEffect(() => {
    let isMounted = true
    const observer = setupObserver()
    return () => {
      isMounted = false
      observer?.disconnect()
    }
  }, [src])

  const setupObserver = () => {
    const img = imageRef.current
    if (img?.complete) {
      loadImage(src) // If image is already loaded, load it directly
      return
    }
    if (img) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            loadImage(src)
            observer.disconnect()
          }
        },
        { rootMargin: '50px', threshold: 0.1 }
      )
      observer.observe(img)
      return observer
    }
    return
  }

  const loadImage = async (src: string) => {
    try {
      const res = await fetchImage(src)
      if (!res) {
        console.warn(`Failed to load image: ${src}`)
        return
      }

      // res is already a Blob, no need to convert
      if (imageRef.current) {
        const objectUrl = URL.createObjectURL(res)
        imageRef.current.src = objectUrl
        setLoaded(true)
        setAltText(alt)

        imageRef.current.onload = () => {
          URL.revokeObjectURL(objectUrl)
        }
      }
    } catch (err) {
      console.error(`Error loading image ${src}:`, err)
      // Only set direct src as fallback if element is in viewport
      if (
        imageRef.current &&
        imageRef.current.getBoundingClientRect().top < window.innerHeight
      ) {
        imageRef.current.src = src
        setLoaded(true)
        setAltText(alt)
      }
    }
  }

  const fetchImage = async (src: string | null, maxRetries = 2, timeout = 8000): Promise<Blob | null> => {
    if (!src || src.startsWith('data:')) return null

    if (typeof window === 'undefined' || !window.caches) {
      console.warn('Cache API not available')
      try {
        const response = await fetch(src)
        return response.ok ? response.blob() : null
      } catch {
        return null
      }
    }

    const CACHE_DURATION = 7 * 24 * 60 * 60 * 1000 // 7 days in milliseconds
    const cache = await window.caches.open('image-cache')
    
    // Check for existing cache
    const cachedResponse = await cache.match(src)
    if (cachedResponse) {
      const cachedDate = cachedResponse.headers.get('cache-date')
      if (cachedDate) {
        const cacheAge = Date.now() - Number(cachedDate)
        if (cacheAge < CACHE_DURATION) {
          return cachedResponse.blob()
        }
        // Cache expired, remove it
        await cache.delete(src)
      }
    }

    // Fetch new image if not cached or cache expired
    let retries = 0
    while (retries < maxRetries) {
      const controller = new AbortController()
      const timeoutId = setTimeout(() => controller.abort(), timeout)

      try {
        const response = await fetch(src, { signal: controller.signal })
        clearTimeout(timeoutId)

        if (response.ok) {
          // Add cache date header to track age
          const headers = new Headers(response.headers)
          headers.set('cache-date', Date.now().toString())
          
          const newResponse = new Response(response.body, {
            status: response.status,
            statusText: response.statusText,
            headers
          })
          
          await cache.put(src, newResponse.clone())
          return newResponse.blob()
        }
      } catch (error) {
        clearTimeout(timeoutId)
        console.warn(`Retry ${retries + 1}/${maxRetries} failed for image: ${src}`, error)
      }
      
      retries++
    }

    return null
  }

  return (
    <ImageWrapper loaded={loaded}>
      <img
        ref={imageRef}
        src={loaded ? undefined : undefined}
        data-lazy={src}
        alt={loaded ? altText : ''}
        className={`lazy-image ${className} ${loaded ? 'loaded' : ''}`}
      />
    </ImageWrapper>
  )
})
