import { NO_USER } from '../firebase/AuthData'
import { makeAPIRequest, QueryParams } from '../utils/request'
import { getFirAuth } from '../firebase/firebaseConnect'
import { UserUpdateData } from './types'
import { USER_ENDPOINTS } from '../utils/endpoints'
import { getFirUserAndStatus } from '../../Utils'

export const getUser = async (data: any) => {
  const { user } = await getFirUserAndStatus()
  let headerParams: QueryParams = {}
  if (user) {
    const token = await user.getIdToken()
    headerParams.Authorization = `Bearer ${token}`
  }
  const response = await makeAPIRequest(
    USER_ENDPOINTS.GET_OR_CREATE_USER,
    {
      method: 'GET',
      apiVersion: '4',
      headerParams: headerParams,
    }
  )
  if (response?.error) {
    //Issue with getting user, use firebase one
    console.error(response.error?.message)
    return data //Passed in firebase data
  }
  return response?.data
}

export const createUser = async (data: any) => {
  const { user } = await getFirUserAndStatus()
  let headerParams: QueryParams = {}
  if (!user) {
    return Promise.resolve(NO_USER)
  }
  const token = await user.getIdToken()
  headerParams.Authorization = `Bearer ${token}`
  let body = JSON.stringify(data)
  const response = await makeAPIRequest(
    USER_ENDPOINTS.GET_OR_CREATE_USER,
    {
      method: 'POST',
      apiVersion: '4',
      headerParams: headerParams,
      body: body,
    }
  )
  if (response?.error) {
    //Issue with getting user, use firebase one
    console.error(response.error?.message)
    return data //Passed in firebase data
  }
  return response?.data
}

export const updateUser = async (data: UserUpdateData) => {
  const usr = (await getFirAuth())?.currentUser
  if (!usr) return false
  let headerParams: QueryParams = {}
  if (usr) {
    const token = await usr.getIdToken()
    headerParams.Authorization = `Bearer ${token}`
  }
  let body = JSON.stringify(data)
  try {
    const response = await makeAPIRequest(USER_ENDPOINTS.UPDATE_USER, {
      method: 'PUT',
      apiVersion: '2',
      headerParams: headerParams,
      body: body,
    })
    if (response?.error) {
      console.error(response.error?.message || 'Unknown error')
      return false
    }
    return true
  } catch (err: unknown) {
    console.error(err)
    return false
  }
}

export const updateFavoriteTeam = async (team: string) => {
  if (!team) return { success: false, team: null }
  const data: object = {
    favWLLTeam: team,
  }
  const success = await updateUser(data)
  return { success, team: team }
}
