import { Outlet } from 'react-router-dom'
import { lazy, Suspense } from 'react'
const TeamProvider = lazy(() =>
  import('../../Context').then((module) => ({ default: module.TeamProvider }))
)
const PlayerProvider = lazy(() =>
  import('../../Context').then((module) => ({ default: module.PlayerProvider }))
)

export const TeamContextLayout: React.FC = () => {
  return (
    <Suspense fallback={<div />}>
      <TeamProvider>
        <PlayerProvider>
          <Outlet />
        </PlayerProvider>
      </TeamProvider>
    </Suspense>
  )
}
