import React, { useContext, useEffect, useState } from 'react'
import { useTeamName } from '../utils'
import {
  Coach,
  EventGQL,
  FeedItem,
  ShopProduct,
  TeamGQL,
  Feed,
  fetchWLLTeam,
  getFeedBySlug,
} from '../../Api'
import {
  hasChampSeriesStarted,
  isChampSeriesLive,
} from '../../Config/League/league.config'
import {
  getAdditionalTeamData,
  getIdByTeamName,
  SEASON_SEGMENT_NAMES,
  checkHasChampSeriesStarted,
} from '../../Utils'
import { SHOP_COLLECTIONS } from '../../Config/Shop/shop.config'
import { fetchShopCollection } from '../../Api/Shop/shop.api'
import { ShopProductSortKeys } from '../../Api/Shop/shop.utils'
import { TEAMS_URL } from '../../Routing/urls'
import { STORAGE_NAMES, websiteUrls } from '../../Constants'
import { StorageContext } from '../StorageContext'
import { WLL_IMAGES } from '../../Config/config'

export const TeamContext = React.createContext<{
  teamId: string
  team: TeamGQL | null
  events: EventGQL[]
  artLoading: boolean
  teamFeed: {
    trending: FeedItem[]
    latest: FeedItem[]
    team?: FeedItem[]
  }
  coaches: Coach[]
  teamMerchCollection: ShopProduct[]
  csStarted: boolean
  error: Error | null
  isTeamLoading: boolean
  urlTeamRoster: () => string
  urlShopGear: () => string
  urlTickets: () => string
  getHeadCoach: () => Coach | null
  getAssistantCoaches: () => Coach[]
} | null>(null)

export const TeamProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const teamNameParam = useTeamName()
  const teamId = getIdByTeamName(teamNameParam)
  const { isStorageReady, store, getStoredData } = useContext(StorageContext)!
  const [teamData, setTeamData] = useState<TeamGQL | null>(null)
  const [isTeamLoading, setisTeamLoading] = useState<boolean>(true)
  const [error, setError] = useState<null | any>(null)
  const [events, setEvents] = useState<EventGQL[]>([])
  const [artLoading, setArtLoading] = useState<boolean>(true)
  const [teamFeed, setTeamFeed] = useState<Feed>({
    trending: [],
    latest: [],
    team: [],
  })
  const [coaches, setCoaches] = useState<Coach[]>([])
  const [teamMerchCollection, setTeamMerchCollection] = useState<ShopProduct[]>([])
  const [csStarted, setCSStarted] = useState<boolean>(hasChampSeriesStarted)

  useEffect(() => {
    if (isStorageReady && teamId) fetchData()
  }, [teamId, isStorageReady])

  const fetchData = async () => {
    await Promise.all([fetchTeamData(), fetchFeed(), fetchTeamShopCollection()])
  }

  const fetchTeamData = async () => {
    const { data, refetch } = await getStoredData(
      `${STORAGE_NAMES.team_data}_${teamId}`,
      300000
    )
    if (data) {
      // Use stored team first
      handleSettingTeamData(data)
    }
    if (data && !refetch) return
    try {
      let fetchedTeam = await fetchWLLTeam(teamId)
      if (fetchedTeam) {
        fetchedTeam = { ...getAdditionalTeamData(teamId), ...fetchedTeam }
      }
      if (!fetchedTeam) return setisTeamLoading(false)
      handleSettingTeamData(fetchedTeam)
      cacheNewTeamData(fetchedTeam)
    } catch (err: any) {
      console.log(err)
      setError(err)
      setisTeamLoading(false)
    }
  }

  const cacheNewTeamData = async (tm: TeamGQL): Promise<void> => {
    store(`${STORAGE_NAMES.team_data}_${teamId}`, tm)
  }

  const handleSettingTeamData = (team: TeamGQL) => {
    setTeamData(team)
    structureSeasonEvents(team)
    setCoaches(team?.coaches)
    getDefaultCoachImage()
    setisTeamLoading(false)
  }

  const fetchFeed = async () => {
    const feedParams = {
      tag: teamNameParam,
      limit: 10,
      includeVideos: true,
    }
    try {
      const teamFeed = await getFeedBySlug(feedParams)
      if (!teamFeed || teamFeed.length < 1) {
        setArtLoading(false)
        return
      }
      const tFeed = {
        trending: [teamFeed[0]],
        latest: teamFeed.slice(1),
        team: teamFeed,
      }
      setTeamFeed(tFeed)
      setArtLoading(false)
      return
    } catch (err) {
      console.log(err)
      setArtLoading(false)
      return
    }
  }

  const fetchTeamShopCollection = async () => {
    let hasCollection = teamId in SHOP_COLLECTIONS
    if (!hasCollection) {
      return Promise.resolve()
    }
    try {
      const collection = await fetchShopCollection(
        SHOP_COLLECTIONS[teamId],
        ShopProductSortKeys.BEST_SELLING,
        10
      )
      if (collection?.products && collection.products.length > 0) {
        setTeamMerchCollection(collection.products)
      }
      return
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * Filters out CS events if ChampSeries has finished & sets csStarted if games have been played
   */
  const structureSeasonEvents = (tm: TeamGQL) => {
    if (!tm?.events) return
    const csHasStarted = checkHasChampSeriesStarted(tm.events)
    if (!isChampSeriesLive) {
      const regSeason = tm.events.filter(
        (ev) => ev.seasonSegment !== SEASON_SEGMENT_NAMES.champseries
      )
      setEvents(regSeason)
      return
    }
    if (csHasStarted && tm.events.length > 0) {
      setEvents(tm.events)
      setCSStarted(csHasStarted)
      return
    }
  }

  const urlTeamRoster = (): string => {
    if (!teamData) return ''
    return `${TEAMS_URL}/${teamData.officialId}/roster`
  }

  const urlTickets = (): string => {
    return '/schedule'
  }

  const urlShopGear = (): string => {
    if (!teamData) return ''
    return `${websiteUrls.shopBaseUrl}/collections/${teamData.fullName}`
  }

  const getHeadCoach = (): Coach | null => {
    if (coaches.length < 1) return null
    const heads = coaches.filter((c) => c.coachType.toLowerCase() === 'head')
    if (heads.length > 0) {
      return heads[0]
    }
    return null
  }

  const getAssistantCoaches = (): Coach[] | [] => {
    if (coaches.length < 1) return []
    return coaches.filter((c) => c.coachType.toLowerCase() === 'assistant')
  }

  const getDefaultCoachImage = () => {
    coaches.map((coach) => {
      if (!coach?.profileUrl) {
        coach.profileUrl = WLL_IMAGES.defaultProfile
        return
      }
    })
  }

  return (
    <TeamContext.Provider
      value={{
        teamId: teamId,
        team: teamData,
        isTeamLoading,
        events,
        artLoading,
        teamFeed,
        coaches,
        teamMerchCollection,
        csStarted,
        error,
        urlTeamRoster,
        urlShopGear,
        urlTickets,
        getHeadCoach,
        getAssistantCoaches,
      }}
    >
      {children}
    </TeamContext.Provider>
  )
}
