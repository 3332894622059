import React from 'react'
import { styled } from '@mui/system'
import { colors } from '../consts'

type Color = 'white' | 'yellow' | 'dark' | 'grey'
type ColorMapType = { [key in Color]: string }

const colsorMap: ColorMapType = {
  white: colors.white,
  yellow: colors.yellow,
  dark: colors.text,
  grey: colors.darkLayout,
}

type styleProps = {
  boxColor: Color | string
  noMargin?: boolean
  vertMargins?: number
  sideMargins?: number
  padding?: number
  bgImg?: string
  border?: number
  maxWidth?: number
  fullHeight?: boolean
  animated?: boolean
}


export type BoxProps = {
  boxColor?: string
  noMargin?: boolean
  vertMargins?: number
  sideMargins?: number
  padding?: number
  bgImg?: string
  border?: number
  className?: string
  maxWidth?: number
  fullHeight?: boolean
  animated?: boolean
  children?: React.ReactNode
}

const BoxStyle = styled('section')<styleProps>(
  ({ boxColor, noMargin, padding, vertMargins, sideMargins, bgImg, border, maxWidth, fullHeight, animated = true, theme }) => ({
    background: getBackgroundColor(boxColor),
    borderRadius: border ? border : 6,
    padding:
      padding !== null && padding !== undefined
        ? theme.spacing(padding)
        : theme.spacing(4),
    marginTop: vertMargins
      ? theme.spacing(vertMargins)
      : noMargin === true
      ? 0
      : theme.spacing(2),
    marginBottom: vertMargins ? theme.spacing(vertMargins) : 0,
    marginLeft: sideMargins ? theme.spacing(sideMargins) : 0,
    marginRight: sideMargins ? theme.spacing(sideMargins) : 0,
    backgroundImage: bgImg !== '' ? `url(${bgImg})` : 'none',
    backgroundPosition: 'right top',
    backgroundSize: 'cover',
    maxWidth: maxWidth ? maxWidth : '100%',
    minHeight: fullHeight ? '100vh' : 'auto',
    '@keyframes fadeIn': {
      '0%': {
        transform: 'translateY(60px)',
      },
      '100%': {
        transform: 'translateY(0)',
      }
    },
      animation: animated ? 'fadeIn linear' : 'none',
      animationTimeline: animated ? 'view()' : 'none',
      animationRange: animated ? 'entry 0% cover 15%' : 'none',
    [theme.breakpoints.down('md')]: {
      padding:
        padding !== null && padding !== undefined ? theme.spacing(1) : theme.spacing(2),
      marginTop: vertMargins
        ? theme.spacing(vertMargins * 0.75)
        : noMargin === true
        ? 0
        : theme.spacing(1),
      marginBottom: vertMargins ? theme.spacing(vertMargins * 0.75) : 0,
      marginLeft: sideMargins ? theme.spacing(sideMargins * 0.75) : 0,
      marginRight: sideMargins ? theme.spacing(sideMargins * 0.75) : 0,
      backgroundPosition: 'right center',
    },
  })
)

const getBackgroundColor = (color?: string) => {
  if (!color) return colors.white
  switch (color) {
    case 'white':
      case 'yellow':
      case 'dark':
      case 'grey':
      return colsorMap[color]
    default:
      return color
  }
}

export const Box: React.FC<React.PropsWithChildren<BoxProps>> = ({
  boxColor = 'white',
  noMargin,
  bgImg,
  padding,
  vertMargins,
  sideMargins,
  border,
  maxWidth,
  fullHeight,
  children,
}) => {
  return (
    <BoxStyle
      boxColor={boxColor}
      noMargin={noMargin}
      bgImg={bgImg}
      padding={padding}
      vertMargins={vertMargins}
      sideMargins={sideMargins}
      border={border}
      maxWidth={maxWidth}
      fullHeight={fullHeight}
    >
      {children}
    </BoxStyle>
  )
}

// SAS - Side panel | large main column | Side panel
// AA - two columns, based on grid, wrap on 'sm' size
// aa - two columns, wrap on 'xs' size
type ColumnStylesProps = {
  layout: 'SAS' | 'AA' | 'aa'
}

const BoxColumnsStyle = styled('section')<ColumnStylesProps & BoxProps>(
  ({ layout, color, noMargin, vertMargins, sideMargins, padding, border, theme }) => ({
    columnGap: theme.spacing(1),
    gridRowGap: theme.spacing(2),

    // set grid layout template
    ...(layout === 'SAS' && {
      display: 'grid',
      maxWidth: 1400,
      gridTemplateColumns: `25% auto 25%`,
      gridTemplateAreas: '"col1 col2 col3"',
      [theme.breakpoints.between('sm', 'lg')]: {
        columnGap: theme.spacing(1),
        gridTemplateColumns: `0.5fr 0.5fr`,
        gridTemplateAreas: '"col2 col2" "col1 col3"',
      },
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: `1fr`,
        gridTemplateAreas: '"col2" "col1"  "col3"',
      },
      '& > div': {
        borderRadius: border ? border : 6,
        background: getBackgroundColor(color),
        padding:
          padding !== null && padding !== undefined
            ? theme.spacing(padding)
            : theme.spacing(5, 6),
        marginTop: vertMargins
          ? theme.spacing(vertMargins)
          : noMargin === true
          ? 0
          : theme.spacing(2),
        marginBottom: vertMargins ? theme.spacing(vertMargins) : 0,
        marginLeft: sideMargins ? theme.spacing(sideMargins) : 0,
        marginRight: sideMargins ? theme.spacing(sideMargins) : 0,
        [theme.breakpoints.between('md', 'lg')]: {
          padding:
            padding !== null && padding !== undefined
              ? theme.spacing(0)
              : theme.spacing(4, 2),
          marginTop: vertMargins
            ? theme.spacing(vertMargins)
            : noMargin === true
            ? 0
            : theme.spacing(2),
          marginBottom: vertMargins ? theme.spacing(vertMargins) : 0,
          marginLeft: sideMargins ? theme.spacing(sideMargins) : 0,
          marginRight: sideMargins ? theme.spacing(sideMargins) : 0,
        },
        [theme.breakpoints.between('sm', 'md')]: {
          padding:
            padding !== null && padding !== undefined
              ? theme.spacing(0)
              : theme.spacing(2),
          marginTop: vertMargins
            ? theme.spacing(vertMargins * 0.75)
            : noMargin === true
            ? 0
            : theme.spacing(2),
          marginBottom: vertMargins ? theme.spacing(vertMargins * 0.75) : 0,
          marginLeft: sideMargins ? theme.spacing(sideMargins * 0.75) : 0,
          marginRight: sideMargins ? theme.spacing(sideMargins * 0.75) : 0,
        },
        [theme.breakpoints.down('sm')]: {
          padding:
            padding !== null && padding !== undefined
              ? theme.spacing(0)
              : theme.spacing(2),
          marginTop: vertMargins
            ? theme.spacing(vertMargins * 0.75)
            : noMargin === true
            ? 0
            : theme.spacing(2),
          marginBottom: vertMargins ? theme.spacing(vertMargins * 0.75) : 0,
          marginLeft: sideMargins ? theme.spacing(sideMargins * 0.75) : 0,
          marginRight: sideMargins ? theme.spacing(sideMargins * 0.75) : 0,
        },
      },
    }),

    ...(layout === 'AA' && {
      display: 'grid',
      gridTemplateColumns: `0.fr 0.5fr`,
      gridTemplateAreas: '"col1 col2"',
      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: `1fr`,
        gridTemplateAreas: '"col1" "col2"',
        '& > div': {
          overflowX: 'auto',
        },
      },
    }),

    ...(layout === 'aa' && {
      display: 'flex',
      flexWrap: 'wrap',
      gridRowGap: theme.spacing(0.5),
      '& > div': {
        
        borderRadius: border ? border : 6,
        flex: `0 1 1`,
        flexGrow: 1,
        background: getBackgroundColor(color),
        padding:
          padding !== null && padding !== undefined
            ? theme.spacing(padding)
            : theme.spacing(2),
        marginTop: vertMargins
          ? theme.spacing(vertMargins)
          : noMargin === true
          ? 0
          : theme.spacing(2),
        marginBottom: vertMargins ? theme.spacing(vertMargins) : 0,
        marginLeft: sideMargins ? theme.spacing(sideMargins) : 0,
        marginRight: sideMargins ? theme.spacing(sideMargins) : 0,
        [theme.breakpoints.down('md')]: {
          padding:
            padding !== null && padding !== undefined
              ? theme.spacing(padding * 0.75)
              : theme.spacing(2),
          marginTop: noMargin === true ? 0 : theme.spacing(1),
        },
      },
      '& > div.wide': {
        width: '100%',
      },
      '& >div:nth-of-type(1)': {
        marginTop: noMargin === true ? 0 : theme.spacing(2),
        gridArea: 'col1',
      },
    }),

    '&>div:nth-of-type(1)': {
      gridArea: 'col1',
    },

    '&>div:nth-of-type(2)': {
      gridArea: 'col2',
    },

    '&>div:nth-of-type(3)': {
      gridArea: 'col3',
    },
    '&>div:nth-of-type(4)': {
      gridArea: 'col4',
    },
  })
)

export const MobileBox: React.FC<React.PropsWithChildren<BoxProps>> = ({
  boxColor = 'white',
  sideMargins = 2,
  vertMargins = 2,
  bgImg,
  padding,
  children,
}) => {
  return (
    <BoxStyle
      boxColor={boxColor}
      sideMargins={sideMargins}
      vertMargins={vertMargins}
      bgImg={bgImg}
      padding={padding}
    >
      {children}
    </BoxStyle>
  )
}

export const BoxWithColumns: React.FC<
  React.PropsWithChildren<BoxProps & ColumnStylesProps>
> = ({
  boxColor = 'white',
  noMargin,
  padding,
  layout,
  vertMargins,
  sideMargins,
  maxWidth,
  children,
}) => {
  return (
    <BoxColumnsStyle
      layout={layout}
      boxColor={boxColor}
      noMargin={noMargin}
      padding={padding}
      vertMargins={vertMargins}
      sideMargins={sideMargins}
      maxWidth={maxWidth}
    >
      {children}
    </BoxColumnsStyle>
  )
}
