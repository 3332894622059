import { styled } from '@mui/system'
import { colors, fontSizes } from '../../consts'

export const NavCon = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100vw',
}))

export const NavbarCon = styled('nav')(({ theme }) => ({
  height: 50,
  background: colors.black,
  margin: 0,
  position: 'relative',
  top: 0,
  width: '100vw',
  [theme.breakpoints.down('md')]: {
    height: 40,
  },
}))

export const NavbarMainCon = styled('section')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(0, 4),
  height: 50,
  [theme.breakpoints.down('md')]: {
    height: 40,
    margin: theme.spacing(0, 1),
  },
  '& .leftCol': {
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: 10,
    flexGrow: 2,
    height: 50,
    paddingBottom: 8,
    [theme.breakpoints.down('md')]: {
      height: 40,
      alignItems: 'center',
      paddingBottom: 0,
    },
  },
  '& .rightCol': {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: 15,
    height: 50,
    [theme.breakpoints.down('md')]: {
      height: 40,
      alignItems: 'center',
      marginRight: 5,
    },
    '& .navKeyActions': {
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      height: '100%',
      paddingBottom: 8,
      [theme.breakpoints.down('md')]: {
        alignItems: 'center',
        paddingBottom: 0,
      },
    },
    '& .navProfile': {
      margin: 'auto 5px',
      right: 10,
      width: 40,
      zIndex: 2,
      [theme.breakpoints.down('md')]: {
        marginRight: 0,
        width: 30,
      },
      '& svg': {
        color: '#fff',
      },
    },
  },
}))

export const NavBrandCon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& .menuCon': {
    marginLeft: 10,
  },
  '& .brandImg': {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 5,
    cursor: 'pointer',
    '& img': {
      maxHeight: 45,
      [theme.breakpoints.down('md')]: {
        maxHeight: 35,
      },
    },
  },
}))

export const NavMobileBrandHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: theme.spacing(3, 1),
  '& .brandImg': {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      maxHeight: 60,
    },
  },
}))

export const NavButtonCon = styled('div')<{ withIcon: boolean }>(
  ({ theme, withIcon }) => ({
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      display: 'block',
      padding: theme.spacing(0, withIcon ? 0 : 2, 0, 2),
      background: 'transparent',
      color: colors.white,
      fontWeight: 500,
      fontSize: fontSizes.medium,
      textDecoration: 'none',
      border: 'none',
      cursor: 'pointer',
      [theme.breakpoints.down('lg')]: {
        fontSize: fontSizes.small,
      },
      '&:focus': {
        outline: 'none',
      },
    },
  })
)

export const SubNavCon = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& img': {
    width: 20,
    marginLeft: 5,
  },
}))

export const MobileDrawerOverlay = styled('div')(({ theme }) => ({
  position: 'fixed',
  inset: 0,
  zIndex: 50,
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
}))

export const MobileDrawerContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  height: '100%',
  width: 225,
  backgroundColor: colors.black,
}))

export const MobileDrawerNav = styled('nav')(({ theme }) => ({
  padding: theme.spacing(1, 0),
  '& ul': {
    listStyle: 'none',
    paddingInlineStart: theme.spacing(2),
  },
}))

export const MobileDrawerListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(4, 0),
}))

export const MobileDrawerButton = styled('button')<{ withIcon: boolean }>(
  ({ theme, withIcon }) => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: theme.spacing(0, withIcon ? 0 : 2, 0, 2),
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    color: colors.white,
    fontWeight: 500,
    fontSize: fontSizes.small,
  })
)

export const MobileDrawerTeamsList = styled('ul')(({ theme }) => ({
  marginLeft: theme.spacing(5),
  marginTop: theme.spacing(1),
}))

export const IconWrapper = styled('span')(({ theme }) => ({
  marginRight: theme.spacing(1),
}))
