import { Positions } from '../../Api'
import {
  FilteredResult,
  fullNameStatLabels,
  offenseStatHeaderList,
  positionSeasonStatHeadersLists,
  positionStatHeaderLists,
  statFields,
  StatListType,
  StatMapType,
  StatTypes,
} from '../../Constants/statsProcess'
import { ConvertStatValue } from '../formatting'
/**
 * Converts stat field to Human readable full stat name using `fullNameStatLabels`
 */
export const mapPlayerStat = (stat: string): string => {
  return fullNameStatLabels[stat] || ''
}

/**
 * Get stats mapping for player position
 */
export const getMapForPosition = (position?: Positions): StatListType => {
  if (!position) return offenseStatHeaderList

  if (position in positionStatHeaderLists) return positionStatHeaderLists[position]
  return offenseStatHeaderList
}
/**
 * Get season stats mapping for player position
 */
export const mapPlayerSeasonStatHeaders = (position?: string) => {
  if (!position) return positionSeasonStatHeadersLists['F']
  if (position in positionSeasonStatHeadersLists)
    return positionSeasonStatHeadersLists[position]
  return positionSeasonStatHeadersLists['F']
}
export function getStatList(position: Positions, statType: StatTypes): StatMapType[] {
  const maps = getMapForPosition(position)[statType]
  const result: StatMapType[] = []
  maps.map((key) => {
    const row = statFields[key]
    if (row) {
      result.push({
        name: row[0],
        longName: row[1],
        field: row[2],
      })
    }
  })
  return result
}

export function extractStatFromObject(
  position: Positions,
  statType: StatTypes,
  data: any | null
): FilteredResult[] {
  if (!data) return []
  const fields = getStatList(position, statType)
  return fields.map((f) => ({
    ...f,
    value: ConvertStatValue(data[f.field], f.field),
  }))
}
