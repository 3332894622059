import React, { useState } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { SubMenu } from './SubMenu'
import { MobileDrawer } from './MobileDrawer'
import { DesktopNav } from './DesktopNav'
import { useHoverMenu } from '../../../Hooks/Navbar/useHoverMenu'
import { NavbarCon, NavbarMainCon, NavBrandCon } from './styles'
import { TEAMS_MENU_ITEMS } from '../../../Constants'
import { Img } from '../../Image/Img'
import { MenuIcon } from '../../LazyComponents/lazyIcons'
import { IconButton } from '../../Icons'
import { MobileNav } from './MobileNav'
import { colors } from '../../consts'
import { WLL_IMAGES } from '../../../Config/config'

export const Navbar: React.FC = () => {
  const {
    anchorEl: teamsAnchorEl,
    setAnchorEl: setTeamsAnchorEl,
    handleMouseLeave: handleTeamsMouseLeave,
  } = useHoverMenu()
  const [mobileOpen, setMobileOpen] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleTeamsClick = (event: React.MouseEvent<HTMLElement>) => {
    setTeamsAnchorEl(event.currentTarget)
  }

  const handleTeamsClose = () => {
    setTeamsAnchorEl(null)
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const handleNavigation = (path: string) => {
    window.location.href = path
    handleTeamsClose()
    setMobileOpen(false)
  }

  return (
    <NavbarCon>
      <NavbarMainCon>
        <NavBrandCon>
          {isMobile && (
            <div className="menuCon">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <MenuIcon sx={{ color: colors.white }} />
              </IconButton>
            </div>
          )}
          <div className="brandImg" onClick={() => handleNavigation('/')}>
            <Img
              src={WLL_IMAGES.siteHeader}
              alt="WLL Site Logo"
            />
          </div>
        </NavBrandCon>

        {/* Desktop Navigation */}
        {!isMobile && (
          <DesktopNav
            onTeamsClick={handleTeamsClick}
            onNavigate={handleNavigation}
          />
        )}
        {isMobile && (
          <MobileNav
            onNavigate={handleNavigation}
          />
        )}

        {/* Mobile Navigation */}
        <MobileDrawer
          open={mobileOpen}
          onClose={handleDrawerToggle}
          onNavigate={handleNavigation}
        />

        <SubMenu
          menuText="Teams"
          options={TEAMS_MENU_ITEMS}
          anchorEl={teamsAnchorEl}
          onClose={handleTeamsClose}
          onNavigate={handleNavigation}
          onMouseLeave={handleTeamsMouseLeave}
        />
      </NavbarMainCon>
    </NavbarCon>
  )
}
