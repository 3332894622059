import { StorageOptionsStatus } from '../../Constants'
import { indexedDBHelper } from '../Storage/indexedDB'

export const storeInCache = async (
  key: string,
  item: any,
  storageStatuses: StorageOptionsStatus
): Promise<void> => {
  const { indexedDB, local, session } = storageStatuses
  if (!indexedDB && !local && !session) return
  //Append last time updated
  const appendedItem = { data: item, lastUpdated: new Date().toISOString() }
  if (indexedDB) {
    try {
      await indexedDBHelper.storeItem(key, appendedItem)
      return
    } catch (error) {
      console.error(`IndexedDB storage failed for key "${key}":`, error)
      // Proceed to fallback options
    }
  }
  if (local) {
    try {
      localStorage.setItem(key, JSON.stringify(appendedItem))
    } catch (e: any) {
      if (e?.name === 'QuotaExceededError') {
        console.error('LocalStorage limit exceeded!')
      } else {
        console.error('An unexpected error occurred:', e)
      }
    }
  } else {
    //try Session
    try {
      sessionStorage.setItem(key, JSON.stringify(appendedItem))
    } catch (e: any) {
      if (e?.name === 'QuotaExceededError') {
        console.error('SessionStorage limit exceeded!')
      } else {
        console.error('An unexpected error occurred:', e)
      }
    }
  }
}

export const retrieveFromCache = async (
  key: string,
  storageStatuses: StorageOptionsStatus
): Promise<any> => {
  const { indexedDB, local, session } = storageStatuses
  if (!indexedDB && !local && !session) return
  if (indexedDB) {
    try {
      const value = await indexedDBHelper.retrieveItem(key)
      if (value !== undefined) {
        return value
      }
    } catch (error) {
      console.error(`IndexedDB retrieval failed for key "${key}":`, error)
      // Proceed to fallback options
    }
  }
  if (local) {
    const localItem = localStorage.getItem(key)
    if (localItem) {
      const item = JSON.parse(localItem)
      return item
    }
  } else {
    //try Session
    const sessionItem = sessionStorage.getItem(key)
    if (sessionItem) {
      const item = JSON.parse(sessionItem)
      return item
    }
  }
  return null
}
