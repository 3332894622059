import React from 'react'
import { footerSections } from './footerData'
import {
  FooterContainer,
  FooterContent,
  LogoSection,
  Logo,
  FooterSection,
  SectionTitle,
  FooterLink,
} from './styles'
import { WLL_IMAGES } from '../../../Config/config'

export const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <LogoSection>
          <Logo
            src={WLL_IMAGES.wllShield}
            alt="WLL Logo"
          />
        </LogoSection>
        
        {footerSections.map((section, index) => (
          <FooterSection key={index}>
            <SectionTitle>{section.title}</SectionTitle>
            {section.items.map((item, itemIndex) => (
              <FooterLink key={itemIndex} href={item.link}>
                {item.label}
              </FooterLink>
            ))}
          </FooterSection>
        ))}
      </FooterContent>
    </FooterContainer>
  )
}

export default Footer