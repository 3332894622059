import { PLL_LINKS } from '../Config/config'

export const TEAMS_URL = '/teams'
export const PLAYER_URL = '/player'
export const PLAYERS_URL = '/players'
export const SCHEDULE_URL = '/schedule'
export const SCORES_URL = '/scores'
export const TICKETS_URL = '/tickets'
export const STANDINGS_URL = '/standings'
export const SINGLE_VIDEO_URL = '/videos'
export const VIDEOS_BY_TAG_URL = '/videos/tag'
export const SINGLE_GAME_HIGHLIGHTS_URL = '/videos/highlights'
export const CHAMPIONSHIP_SERIES = '/championship-series'
export const CHAMPIONSHIP_SERIES_SCHEDULE = '/championship-series/schedule'
export type PlayerTabs = 'profile' | 'stats' | 'media'
export type SeasonType = 'regular' | 'post' | 'allstar' | 'champseries'

export type UrlParams = {
  id?: string
  tab?: PlayerTabs
  firstName?: string
  lastName?: string
  year?: number
  seasonType?: SeasonType
  slugName?: string
}

/**
 *
 * Create UTM Link
 */

type ShopParams = {
  medium: string
  slug?: string
  campaign: string
  isFullLink?: boolean
}
export const createShopUTM = ({
  medium,
  campaign,
  slug,
  isFullLink = false,
}: ShopParams) => {
  return isFullLink
    ? `${slug}?utm_source=WLL_Web_Main&utm_medium=${medium}&utm_campaign=${campaign}`
    : `${PLL_LINKS.shop}/${
        slug && slug
      }?utm_source=WLL_Web_Main&utm_medium=${medium}&utm_campaign=${campaign}`
}

export const createPlayerShopUTM = ({ medium, campaign, slug }: ShopParams) => {
  return `${slug}?utm_source=WLL_Web_Main&utm_medium=${medium}&utm_campaign=${campaign}`
}

type TicketParams = {
  ticketId: string
  page?: string
  week?: string
  day?: string
}
export const createTicketUTM = ({ ticketId, page, week, day }: TicketParams) => {
  if (!ticketId.includes('ticketmaster')) return ticketId
  const includesQuestionMark = ticketId.includes('?')
  return `${ticketId}${includesQuestionMark ? '&' : '?'}wt.mc_id=${getWTMC(
    page,
    week,
    day
  )}&utm_source=thewll.com&utm_medium=client&utm_campaign=WLL_LEAGUE&utm_content=${getContentId(
    page,
    week,
    day
  )}`
}

const getWTMC = (page: string | undefined, week: string | undefined, day?: string) => {
  if (!page || !week) return ''
  switch (page.toLowerCase()) {
    case 'schedule':
      return `WLL_${day ? `SINGLE_DAY` : 'LEAGUE'}_SCHEDULE_PAGE_WEEK${
        week ? week : ''
      }${day ? `_DAY${day}` : ''}`
    case 'tickets':
      return `WLL_${day ? `SINGLE_DAY` : 'LEAGUE'}_TICKETS_PAGE_WEEK${week ? week : ''}${
        day ? `_DAY${day}` : ''
      }`
    case 'ticker':
      return `WLL_${day ? `SINGLE_DAY` : 'LEAGUE'}_GAME_TICKER_WEEK${week ? week : ''}${
        day ? `_DAY${day}` : ''
      }`
    case 'team':
      return `WLL_LEAGUE_TEAM_PAGE`
    default:
      return ''
  }
}

const getContentId = (
  page: string | undefined,
  week: string | undefined,
  day?: string
) => {
  if (!page || !week) return ''
  switch (page.toLowerCase()) {
    case 'schedule':
      return `SCHEDULE_PAGE_WEEK${week ? week : ''}${day ? `_DAY${day}` : ''}`
    case 'tickets':
      return `TICKETS_PAGE_WEEK${week ? week : ''}${day ? `_DAY${day}` : ''}`
    case 'ticker':
      return `GAME_TICKER_WEEK${week ? week : ''}${day ? `_DAY${day}` : ''}`
    case 'team':
      return `TEAM_PAGE`
    default:
      return ''
  }
}