import { EventGQL } from '../Api'
import { allYearsLabel } from '../Config/config'
import {
  CURRENT_YEAR,
  EVENTS_YEAR,
  WLL_FIRST_YEAR,
  STANDINGS_YEAR,
  STATS_YEAR,
} from '../Config/League/league.config'
import { SEASON_SELECTOR_TYPES, SeasonSelectorType } from '../Constants/season'
import { SeasonType } from '../Context/SeasonContext/SeasonContext'

// Options for season segments
export const SEASON_SEGMENT_NAMES: { [key: string]: SeasonType } = {
  regular: 'regular',
  post: 'post',
  allstar: 'allstar',
  champseries: 'champseries',
}

/**
 *
 * Returns a list of years to be used in the year dropdown
 * @param seasonSelectorType - main or standings
 */
export const createYearDropdownList = (
  yearSelectType: SeasonSelectorType,
  requireAllYear: boolean,
  sortBy: 1 | -1 //ASC or DEC
) => {
  let yearList = []
  if (yearSelectType === SEASON_SELECTOR_TYPES.STANDINGS) {
    for (let i = WLL_FIRST_YEAR; i <= STANDINGS_YEAR; i++) {
      yearList.push({ value: i, label: i.toString() })
    }
  } else {
    for (let i = WLL_FIRST_YEAR; i <= CURRENT_YEAR; i++) {
      yearList.push({ value: i, label: i.toString() })
    }
  }
  const sorted = sortBy < 0 ? yearList.reverse() : yearList
  return requireAllYear ? [allYearsLabel, ...sorted] : sorted
}

export const determineSeasonStatsStatus = (events: EventGQL[]) => {
  let yearDiff = EVENTS_YEAR - STATS_YEAR
  if (yearDiff === 0) {
    // Reg season just started
    if (events.length > 0 && events[0].eventStatus > 0) {
      //Season is in progress
      return 'Current'
    }
    return 'Pending'
  }
  return 'Previous'
}
