import React, { useState, useEffect } from 'react'
import { Menu, MenuItem } from '@mui/material'
import { SubMenuItem } from '../../../Constants/nav'
import { colors } from '../../consts'
import { SubNavCon } from './styles'
import { Img } from '../../Image/Img'

interface SubMenuProps {
  menuText: string
  options: SubMenuItem[]
  anchorEl: HTMLElement | null
  onClose: () => void
  onNavigate: (path: string) => void
  onMouseLeave: (event: React.MouseEvent<HTMLElement>) => void
}

export const SubMenu: React.FC<SubMenuProps> = ({
  menuText,
  options,
  anchorEl,
  onClose,
  onNavigate,
  onMouseLeave,
}) => {
  const [isHovering, setIsHovering] = useState(false)

  useEffect(() => {
    if (!anchorEl && isHovering) {
      setIsHovering(false)
    }
  }, [anchorEl])

  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>) => {
    setIsHovering(false)
    onMouseLeave(event)
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl) || isHovering}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': `teams-button-${menuText}`,
        onMouseLeave: handleMouseLeave,
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      sx={{
        '& .MuiMenu-paper': {
          background: colors.black,
          color: colors.white,
          marginTop: 1,
          borderRadius: `0 0 10px 10px`,
          minWidth: 200,
        },
      }}
    >
      {options.map((itm) => (
        <SubNavCon key={itm.path}>
          <Img src={itm.icon} alt={itm.name} />
          <MenuItem onClick={() => onNavigate(itm.path)}>{itm.name}</MenuItem>
        </SubNavCon>
      ))}
    </Menu>
  )
}
