/* global google */
import React, { useEffect, useState, createContext, useContext } from 'react'
import { SignInModal } from '../../Components/Auth'
import {
  getFirAuth,
  getGoogleAuthCredential,
  getActionCodeSettings,
  getGoogleProvider,
} from './firebaseConnect'
import { getAuth, type ConfirmationResult } from 'firebase/auth'
import { getCurrentUser, NO_USER, setUpdatedUserData } from './AuthData'
import type { FavType } from './AuthData'
import type { User, UserFavorites } from '../Users/types'
import type { GCredential } from './firebaseConnect'
import {
  checkIfEmbededInNativeApp,
  isLocalStorageEnabled,
  logBrazeEvent,
  logEvent,
  resetWindowLocationWithoutParams,
  useCookieStorage,
  useQuery,
} from '../../Utils'
import { GOOGLE_CLIENT_ID } from '../../Config/Firebase/config'
import { BRAZE_EVENTS } from '../../Constants'
import { LinkContext } from '../../Context'
import { ANALYTICS_TAGS } from '../../Constants/analytics'
import { initBraze } from '../../Config/Braze/braze.config'
import { createUser, getUser } from '../Users'

type AuthContextType = User & {
  authLoading: boolean
  brazeInitialized: boolean
  login: (extraFields?: string[]) => void
  handleToggleLogin: () => void
  logout: () => void
  updateFavsInState: (favType: FavType, favorites: string[], favTeam?: string) => void
  updateProfileInState: (userItem: any) => void
  updateUserFieldInState: (fieldName: keyof User, value: any) => void
  clearTeamFavorite: () => void
  getAuthToken: () => Promise<string | null | undefined>
  sendPhoneVerification: (phoneNumber: string) => Promise<ConfirmationResult | null>
  getMissingUserFields: (fields: string[]) => string[]
}
const defaultFavs: UserFavorites = {
  topics: [],
  favPlayers: [],
  favTeam: null,
  favWLLTeam: null,
}

export const AuthContext = createContext<AuthContextType | null>(null)

type AuthProviderProps = { allowAnonymous?: boolean; children?: React.ReactNode }

export const AuthProvider: React.FC<AuthProviderProps> = ({
  allowAnonymous = false,
  children,
}) => {
  let query = useQuery()
  const { getCookie, setCookie, removeCookie } = useCookieStorage()
  const { getMasterLinkParamsFromLocal } = useContext(LinkContext)!
  const [user, setUser] = useState<User>(NO_USER)
  const [authLoading, setAuthLoading] = useState<boolean>(true)
  const [showLogin, setShowLogin] = useState<{
    show: boolean
    extraFields?: string[]
  }>({ show: false, extraFields: [] })
  const [userFavorites, setUserFavorites] = useState<UserFavorites>(defaultFavs)
  const [brazeInitialized, setBrazeInitialized] = useState<boolean>(false)

  useEffect(() => {
    const handleAuthChange = async () => {
      await onAuthChange()
    }
    //Check if pending redirect
    getAuthRedirectResult()

    handleAuthChange()
    //Cleanup
    return () => {
      onAuthChange()
    }
  }, [])

  const onAuthChange = async () => {
    const auth = await getFirAuth()
    if (auth) {
      //Check custom token query param
      let fbctQuery = query.get('fbct')
      if (fbctQuery) {
        handleCustomToken(fbctQuery)
      }
      const { isSignInWithEmailLink, onAuthStateChanged } = await import('firebase/auth')
      if (isSignInWithEmailLink(auth, window.location.href)) {
        //Email link signup
        handleEmailLink()
      }
      initializeGSI() //init Google One Tap

      onAuthStateChanged(auth, async (userRes) => {
        setAuthLoading(true)
        if (userRes) {
          await handleUserChange({
            ...userRes,
            isAnonymous: userRes.isAnonymous,
            isLoggedIn: true,
            favTeam: '',
          })
        } else {
          // User is logged out
          //Decide to show Google One Tap or not
          handleGoogleOneTapPrompt(NO_USER)

          if (allowAnonymous) {
            handleAnonymousSignIn()
          }
          setAuthLoading(false)
        }
      })
    } else {
      setUser(NO_USER)
      setAuthLoading(false)
      setShowLogin({ show: false, extraFields: [] })
      if (checkIfEmbededInNativeApp()) {
        logEvent(`firebase_not_initialized_onAuthChange_embeded`)
      } else {
        logEvent(`firebase_not_initialized_onAuthChange`)
      }
    }
  }

  const handleUserChange = async (userRes: User) => {
    const usr = await getCurrentUser() //Firebase Auth user
    if (userRes.isAnonymous) {
      handleGoogleOneTapPrompt(usr) //Decide to show Google One Tap or not
    }
    if (!usr.isAnonymous && usr.isLoggedIn) {
      //Create or update user in PLL database
      let firUsr = usr
      //Append master Link params
      let mParams = getMasterLinkParamsFromLocal()
      if (mParams && Object.keys(mParams).length > 0) {
        firUsr = { ...firUsr, ...mParams }
      }
      const isNewUser = checkFirstTimeUser()
      const dbUser = isNewUser ? await createUser(firUsr) : await getUser(firUsr)
      if (dbUser) {
        setUserDataInState(dbUser)
        //lazy load braze
        initBraze(usr.uid!).then((success) => {
          if (success) {
            setBrazeInitialized(true)
          }
        })
      }
    } else {
      setUser(usr as User)
      setUserFavorites(defaultFavs)
      setShowLogin({ show: false, extraFields: [] })
      setAuthLoading(false)
    }
  }

  const checkFirstTimeUser = () => {
    let user = getAuth()?.currentUser
    if (!user?.metadata?.creationTime || !user?.metadata?.lastSignInTime) return false
    const lastSignInTime = new Date(user?.metadata?.lastSignInTime).getTime()
    const creationTime = new Date(user?.metadata?.creationTime).getTime()
    return lastSignInTime - creationTime < 5000
  }

  const setUserDataInState = async (userRes: User) => {
    const userAuth = await setUpdatedUserData(userRes)
    setUser(userAuth)
    const userFavs = { ...userFavorites, favTeam: userRes?.favTeam }
    setUserFavorites(userFavs)
    setAuthLoading(false)
  }

  const updateUserFieldInState = (fieldName: keyof User, value: any) => {
    setUser({ ...user, [fieldName]: value })
  }

  const handleGoogleOneTapPrompt = (usr: User) => {
    let isEmbedded = checkIfEmbededInNativeApp()
    if (isEmbedded) {
      //Don't show one tap in native app
      window.google.accounts.id.cancel()
      return
    }
    if (window?.google?.accounts && window.google.accounts?.id && GOOGLE_CLIENT_ID) {
      if (!usr.isLoggedIn || usr.isAnonymous) {
        //Show one click
        window.google.accounts.id.prompt()
        return
      }
      if (usr.isLoggedIn && !usr.isAnonymous) {
        //Hide one click after log in
        window.google.accounts.id.cancel()
      }
    }
    return
  }

  /**
   * Loading the google one tap signup
   */
  const initializeGSI = () => {
    let isEmbedded = checkIfEmbededInNativeApp()
    if (!isEmbedded && window?.google && window.google?.accounts && GOOGLE_CLIENT_ID) {
      window.google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: (token: GCredential) => {
          handleToken(token)
        },
      })
    }
  }

  /**
   * Handle token response from google one click
   */
  const handleToken = async (token: any) => {
    const auth = await getFirAuth()
    let cred = await getGoogleAuthCredential(token.credential)
    if (auth) {
      const { signInWithCredential } = await import('firebase/auth')
      let result = await signInWithCredential(auth, cred)
      if (result?.user?.uid) {
        if (!brazeInitialized) {
          const userId = result.user.uid
          const success = await initBraze(userId)
          if (success) {
            setBrazeInitialized(true)
            logBrazeEvent(BRAZE_EVENTS.log_in)
          }
        } else {
          logBrazeEvent(BRAZE_EVENTS.log_in)
        }
      }
    }
  }

  const login = (extraFields?: string[]) => {
    if (extraFields) {
      setShowLogin({ show: true, extraFields })
      return
    }
    setShowLogin({ show: true, extraFields: [] })
  }

  const handleToggleLogin = () => {
    setShowLogin({ show: !setShowLogin, extraFields: [] })
  }

  const logout = async () => {
    const fba = await getFirAuth()
    if (fba) {
      setUser(NO_USER)
      setUserFavorites(defaultFavs)
      fba.signOut()
      setBrazeInitialized(false)
      setShowLogin({ show: false, extraFields: [] })
      setAuthLoading(false)
    } else {
      console.log('No auth loaded.')
    }
  }

  const updateFavsInState = (
    favType: FavType,
    favorites: string[],
    favWLLTeam?: string
  ) => {
    const uFavs: UserFavorites = { ...userFavorites }
    if (favType === 'favPlayers') {
      uFavs.favPlayers = favorites
    }
    if (favType === 'topics') {
      uFavs.topics = favorites
    }
    if (favType === 'favWLLTeam' && favWLLTeam) {
      uFavs.favWLLTeam = favWLLTeam
    }
    setUserFavorites(uFavs)
  }

  /**
   * Update profile in state
   */
  const updateProfileInState = (userItem: any) => {
    const currentAuth = { ...user }
    const currentFavorites = { ...userFavorites }
    if (userItem.favWLLTeam) {
      setUser({ ...currentAuth, ...userItem })
      setUserFavorites({ ...currentFavorites, favWLLTeam: userItem.favWLLTeam })
      return
    }
    setUser({ ...currentAuth, ...userItem })
    return
  }

  /**
   * Clear team favorite
   */
  const clearTeamFavorite = () => {
    setUserFavorites({ ...userFavorites, favTeam: null })
  }

  /**
   * Get fields user has not filled in on profile
   */
  const getMissingUserFields = (fields: string[]): string[] => {
    if (fields.length === 0) return []
    return fields.filter((field) => field in user && !user[field as keyof User])
  }

  /**
   * Sign user in anonymously
   */
  const handleAnonymousSignIn = async () => {
    const auth = await getFirAuth()
    if (!auth) return
    const { signInAnonymously } = await import('firebase/auth')
    await signInAnonymously(auth)
  }

  /**
   * Handle user coming from email link
   */
  const handleEmailLink = async () => {
    const auth = await getFirAuth()
    const hasLocalStorage = isLocalStorageEnabled()
    if (auth) {
      let email = ''
      if (hasLocalStorage) {
        email = window.localStorage.getItem('wll_temp_em') || ''
      }
      if (!email) {
        email = tryEmFromCookie()
      }
      try {
        const { signInWithEmailLink } = await import('firebase/auth')
        const result = await signInWithEmailLink(auth, email!, window.location.href)
        if (result?.user?.uid) {
          loginWithBraze(result.user.uid)
          hasLocalStorage && window.localStorage.removeItem('wll_temp_em')
          removeCookie('wll_temp_em')
        }
      } catch (err) {
        console.log(err)
        logEvent('loginError_handleEmailLink', {
          provider: 'email',
          error: err,
          product: 'wll-web',
        })
      }
    }
  }

  /**
   * Check temp email from cookie
   */
  const tryEmFromCookie = () => {
    let email = ''
    const cookieEm = getCookie('wll_temp_em')

    if (cookieEm) {
      email = cookieEm.toLowerCase()
    } else {
      const em = window.prompt('Please confirm your email')
      if (em) {
        email = em.toLowerCase()
      }
    }
    return email
  }

  /**
   * Handle if custom token is passed in
   */
  const handleCustomToken = async (tk: string) => {
    const auth = await getFirAuth()
    if (!tk) return
    if (auth) {
      try {
        const { signInWithCustomToken } = await import('firebase/auth')
        const result = await signInWithCustomToken(auth, tk)
        if (result?.user?.uid) {
          loginWithBraze(result.user.uid)
          resetWindowLocationWithoutParams(['fbct'])
        }
      } catch (err) {
        console.log(err)
        resetWindowLocationWithoutParams(['fbct'])
      }
    }
  }

  /**
   * Handle social logins, Google uses signInWithPopup, Twitter and Facebook use signInWithRedirect
   */
  const signInWithSocial = async (provider: string) => {
    const auth = await getFirAuth()
    if (auth) {
      if (provider !== 'google') return
      try {
        await signInWithGooglePopup(auth)
      } catch (err: any) {
        console.log(err)
        logEvent('loginError_signInWithSocial', {
          provider: provider,
          error: err,
          product: 'wll-web',
        })
        setShowLogin({ show: !setShowLogin, extraFields: [] })
        throw new Error(err)
      }
    }
  }

  /**
   * Use sign up with popup for just Gmail
   */
  const signInWithGooglePopup = async (auth: any) => {
    const p = await getGoogleProvider()
    try {
      if (!p) return
      const { signInWithPopup } = await import('firebase/auth')
      let result = await signInWithPopup(auth, p)
      if (result?.user?.uid) {
        loginWithBraze(result.user.uid)
        setShowLogin({ show: !setShowLogin, extraFields: [] })
      }
    } catch (err: any) {
      logEvent('loginError_signInWithGooglePopup', {
        provider: 'google',
        error: err,
        product: 'wll-web',
      })
      p && p.credentialFromError(err)
    }
  }

  const getAuthRedirectResult = async () => {
    const auth = await getFirAuth()
    if (auth) {
      try {
        const { getRedirectResult } = await import('firebase/auth')
        let result = await getRedirectResult(auth)
        if (result?.user?.uid) {
          loginWithBraze(result.user.uid)
        }
      } catch (err: any) {
        if (!err.credential) return
        const { signInWithCredential } = await import('firebase/auth')
        await signInWithCredential(auth, err.credential)
      }
    }
  }

  const signInWithEmLink = async (redirectTo: string, email: string) => {
    const auth = await getFirAuth()
    if (auth) {
      let actionCodeSettings = getActionCodeSettings(
        redirectTo || window.location.hostname
      )
      try {
        const { sendSignInLinkToEmail } = await import('firebase/auth')
        sendSignInLinkToEmail(auth, email, actionCodeSettings)
        window.localStorage.setItem('wll_temp_em', email)
        setCookie('wll_temp_em', email)
        return true
      } catch (err: any) {
        return false
      }
    }
    return false
  }

  // PHONE LOGIN //

  const sendPhoneVerification = async (phoneNumber: string) => {
    const auth = await getFirAuth()
    const { signInWithPhoneNumber } = await import('firebase/auth')
    const verifier = await getAppVerifier('phone-signin-btn', auth)
    if (verifier && auth) {
      try {
        const confirmationResult: ConfirmationResult = await signInWithPhoneNumber(
          auth,
          phoneNumber,
          verifier
        )
        return confirmationResult
      } catch (err) {
        console.log(err)
        logEvent('loginError_sendPhoneVerification', {
          provider: 'phone',
          error: err,
          product: 'wll-web',
        })
        return null
      }
    } else {
      return null
    }
  }

  const getAppVerifier = async (
    verifierId: string | HTMLElement,
    auth: any | undefined
  ) => {
    if (auth) {
      const { RecaptchaVerifier } = await import('firebase/auth')
      return new RecaptchaVerifier(auth, verifierId, {
        size: 'invisible',
      })
    } else {
      return null
    }
  }

  const getAuthToken = async () => {
    const auth = await getFirAuth()
    const usr = await getCurrentUser()
    if (usr.isLoggedIn && auth) {
      return await auth.currentUser?.getIdToken()
    }
    return null
  }

  const loginWithBraze = async (userId: string, shouldSendEvent: boolean = true) => {
    //shouldSendEvent false for logging in through embeded browser
    if (!brazeInitialized) {
      const success = await initBraze(userId)
      if (success) {
        setBrazeInitialized(true)
        shouldSendEvent && logBrazeEvent(BRAZE_EVENTS.log_in)
      }
    } else {
      shouldSendEvent && logBrazeEvent(BRAZE_EVENTS.log_in)
    }
  }

  return (
    <AuthContext.Provider
      value={{
        ...user,
        ...userFavorites,
        authLoading,
        brazeInitialized,
        login,
        handleToggleLogin,
        logout,
        updateFavsInState,
        updateProfileInState,
        clearTeamFavorite,
        sendPhoneVerification,
        getAuthToken,
        updateUserFieldInState,
        getMissingUserFields,
      }}
    >
      {showLogin.show && (
        <SignInModal
          onClose={() => {
            logEvent(ANALYTICS_TAGS.login_cancelled)
            handleToggleLogin()
          }}
          signInWithSocial={signInWithSocial}
          signInWithEmLink={signInWithEmLink}
          extraFields={showLogin.extraFields}
        />
      )}
      {children}
    </AuthContext.Provider>
  )
}
