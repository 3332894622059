import { styled } from '@mui/system'
import { colors, fontSizes } from '../consts'

type ButtonStyleProps = {
  buttonType: 'primary' | 'secondary' | 'outlined'
  disabled?: boolean
}

type ButtonDropdownStyleProps = {
  fontSize?: string
  color?: string
  disabled?: boolean
}

export const PrimaryBtn = styled('button')<ButtonStyleProps>(
  ({ theme, disabled = false }) => ({
    display: 'inline-block',
    padding: theme.spacing(2.5, 4),
    borderRadius: 12,
    border: 'none',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: colors.black,
    backgroundColor: disabled ? colors.gray.gray20 : colors.yellow,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: disabled ? colors.gray.gray20 : colors.blue,
      cursor: disabled ? 'not-allowed' : 'pointer',
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      padding: theme.spacing(2, 3),
    }
  })
)

export const SecondaryBtn = styled('button')<ButtonStyleProps>(
  ({ theme, disabled = false }) => ({
    display: 'inline-block',
    padding: theme.spacing(1.5, 3),
    borderRadius: 12,
    border: 'none',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: colors.black,
    backgroundColor: disabled ? colors.gray.gray20 : colors.lightBlue,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.blue,
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      padding: theme.spacing(1, 2),
    }
  })
)

export const LinkStyle = styled('a')(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'none',
}))

export const DropdownBtnStyle = styled('button')<ButtonDropdownStyleProps>(
  ({ theme, disabled = false, fontSize, color }) => ({
    border: 'none',
    fontSize: fontSize ?? fontSizes.medium,
    fontWeight: 500,
    color: color ?? colors.white,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover': {
      cursor: disabled ? 'not-allowed' : 'pointer',
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('md')]: {
      fontWeight: 500,
      fontSize: fontSizes.small,
    }
  })
)

export const DropdownBtnInner = styled('div')<{ withIcon: boolean }>(
  ({ theme, withIcon }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    '& p': {
      whiteSpace: 'nowrap',
      margin: 0,
    },
    '& .endIcon': {
      display: withIcon ? 'block' : 'none',
      cursor: 'pointer',
      lineHeight: 0,
    },
  })
)

export const OutlinedBtn = styled('button')<ButtonStyleProps>(
  ({ theme, disabled = false }) => ({
    display: 'inline-block',
    padding: theme.spacing(1.5, 3),
    borderRadius: 12,
    border: `solid 1px ${colors.text}`,
    fontSize: '1rem',
    fontWeight: 'bold',
    color: colors.text,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      padding: theme.spacing(1, 2),
    }
  })
)

export const BlackBtnLink = styled('a')(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'none',
  '& button': {
    cursor: 'pointer',
    display: 'inline-block',
    padding: theme.spacing(1.5, 3),
    borderRadius: 12,
    border: 'none',
    fontWeight: 'bold',
    color: colors.white,
    backgroundColor: colors.black,
    '&:hover': {
      backgroundColor: '#333',
    },
    '&:focus': {
      outline: 'none',
    },
  },
}))

type ShareStyleProps = {
  size: string
  border: boolean
  circular?: boolean
  background?: string
  sTheme?: 'light' | 'dark'
}

export const ShareBtnCon = styled('div')<ShareStyleProps>(
  ({ sTheme, size, border, circular, background, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1, 2),
    cursor: 'pointer',
    border: border ? `1px solid ${colors.lightestGrey}` : 'none',
    borderRadius: circular ? '50%' : 12,
    padding: theme.spacing(1, 0.5, 0),
    backgroundColor: background
      ? background
      : sTheme === 'dark'
      ? colors.darkBackground
      : colors.white,
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0.5, 1),
    },
    '& .icon': {
      width: size === 'lg' ? 30 : 20,
      color: sTheme === 'dark' ? colors.white : colors.text,
      marginBottom: 7,
      fontSize: size === 'lg' ? fontSizes.large : fontSizes.medium,
      [theme.breakpoints.down('sm')]: {
        marginBottom: 5,
        fontSize: size === 'lg' ? fontSizes.semiLarge : fontSizes.small,
      },
    },
    '& .label': {
      color: sTheme === 'dark' ? colors.white : colors.text,
      margin: theme.spacing(0.25, 1.5),
      fontSize: '0.8rem',
      fontWeight: 600,
      maxWidth: 100,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.6rem',
        margin: theme.spacing(0.25, 1),
      },
    },
  })
)

export const WhiteBtnLink = styled('a')(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'none',
  '& button': {
    display: 'inline-block',
    padding: theme.spacing(1.5, 3),
    borderRadius: 12,
    border: `solid 2px ${colors.text}`,
    fontWeight: 'bold',
    color: colors.text,
    cursor: 'pointer',
    backgroundColor: colors.white,
    '&:hover': {
      color: '#777',
      border: `solid 2px ${colors.lightGrey}`,
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem',
      padding: theme.spacing(0.5, 1),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      padding: theme.spacing(0.5, 1),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
      padding: theme.spacing(0.25, 0.5),
    },
  },
}))

export const GameLinkOutlined = styled('a')(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'none',
  '& button': {
    display: 'inline-block',
    padding: theme.spacing(0.5, 2),
    borderRadius: 12,
    border: `solid 1px ${colors.text}`,
    fontSize: '1rem',
    fontWeight: 'bold',
    color: colors.text,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '&:hover': {
      borderColor: '#333',
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1rem',
      padding: theme.spacing(0.5, 1),
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.8rem',
      padding: theme.spacing(0.5, 1),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7rem',
      padding: theme.spacing(0.25, 0.5),
    },
  },
}))

export const GameLinkYellow = styled('a')(({ theme }) => ({
  cursor: 'pointer',
  textDecoration: 'none',
  '& button': {
    display: 'inline-block',
    padding: theme.spacing(0.5, 2),
    borderRadius: 12,
    border: 'none',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    color: colors.black,
    backgroundColor: colors.yellow,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.blue,
      color: colors.white,
    },
    '&:focus': {
      outline: 'none',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1rem',
      padding: theme.spacing(0.5, 1),
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.9rem',
      padding: theme.spacing(0.5),
    },
  },
}))

export const Reset = styled('button')(({ theme }) => ({
  border: 'none',
  background: colors.lightestGrey,
  borderRadius: 10,
  cursor: 'pointer',
  marginLeft: 10,
  height: 20,
}))
