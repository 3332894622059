import {
  SHOP_GRAPHQL_URL,
  SHOP_STOREFRONT_TOKEN_PUBLIC,
} from '../../Config/Shop/shop.config'
import { API_ROOT_URL, GRAPHQL_ROOT_URL, GRAPHQL_ACCESS_KEY } from '../../Config/config'
import { getFirAuth } from '../firebase'

export type Methods = 'POST' | 'GET' | 'PUT'
export type QueryParams = { [key: string]: string | number | boolean | undefined }

export type APIRequestParams = {
  method?: Methods
  apiVersion?: string
  suffix?: string
  headerParams?: QueryParams
  queryParams?: QueryParams
  body?: BodyInit
}

export type PreparedParams = {
  url: string
  opts: RequestInit
}

export type APIRes = {
  json: any
  error: string | null
  status: number | null
}
export type APIResResult = {
  data: any
  error: {
    code?: number
    message?: string
    errors: {
      id?: string | number
      message?: string
    }[]
  } | null
}

export function applyParams(url: URL, params?: QueryParams) {
  if (params !== undefined) {
    for (const [key, value] of Object.entries(params)) {
      if (value !== undefined) {
        if (typeof value === 'number' || typeof value === 'boolean') {
          url.searchParams.append(key, value.toString())
        } else {
          url.searchParams.append(key, value)
        }
      }
    }
  }
}

function prepareApiRequest(
  endpoint: string,
  { method, apiVersion, suffix, headerParams, queryParams, body }: APIRequestParams
): PreparedParams {
  const url = new URL(
    `${API_ROOT_URL}/v${apiVersion}/${endpoint}${suffix ? `${suffix}` : ''}`
  )
  const headers = {
    'content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${process.env.REACT_APP_API_ACCESS_KEY}` || '',
    authSource: 'wll-web',
    ...headerParams,
  }
  applyParams(url, queryParams)
  const opts: RequestInit = { method: method, headers: headers }
  if (body) opts.body = body //For POST requests
  return {
    url: url.toString(),
    opts: opts,
  }
}

export async function callEndpoint(url: string, opts: RequestInit): Promise<APIRes> {
  if (opts.method === undefined) {
    return { json: null, error: 'Request method is invalid', status: null }
  }

  // fetch options
  try {
    const response = await fetch(url, opts)
    if (!response.ok) {
      return {
        json: null,
        error: `Request failed with status ${response.status}: ${response.statusText}`,
        status: response.status,
      }
    }
    let json: any = undefined
    try {
      json = await response.json()
    } catch {
      return {
        json: null,
        error: `Failed to parse response JSON`,
        status: null,
      }
    }
    return { json: json, error: null, status: response.status }
  } catch (error: any) {
    return {
      json: null,
      error: `Failed to fetch data: ${error?.message}`,
      status: null,
    }
  }
}

export async function makeAPIRequest<T>(
  endpoint: string,
  {
    method = 'GET',
    apiVersion = '2',
    suffix,
    queryParams,
    headerParams,
    body,
  }: APIRequestParams,
  overwriteURL?: string
): Promise<APIResResult> {
  const { url, opts } = prepareApiRequest(endpoint, {
    method,
    apiVersion,
    suffix,
    queryParams,
    headerParams,
    body,
  })
  try {
    const res = await callEndpoint(overwriteURL ? overwriteURL : url, opts)
    if (!res.json || res.error) {
      return {
        data: null,
        error: {
          message: res?.error ?? `Returned with no data`,
          errors: [],
        },
      }
    }
    if (parseInt(apiVersion) >= 4) {
      const { data, error } = res.json
      if (data?.items) {
        return {
          data: data.items,
          error,
        }
      }
      return {
        data,
        error
      }
    }
    return {
      data: res.json,
      error: null,
    }
  } catch (error: any) {
    return {
      data: null,
      error: {
        message: `Failed to make API request: ${error?.message}`,
        errors: [],
      },
    }
  }
}

export async function callGraphQL<T>(
  query: string,
  variables: any = {},
  gamification: number = 0,
  token?: string
): Promise<T> {
  const body = { operationName: null, variables: variables, query: query }
  let headerItems: HeadersInit = {
    'content-type': 'application/json',
    authSource: 'wll-web',
    gamification: gamification.toString(),
  }
  headerItems['Authorization'] = token
    ? `Bearer ${token}`
    : `Bearer ${GRAPHQL_ACCESS_KEY}`
  const usr = (await getFirAuth())?.currentUser
  if (usr?.uid) {
    headerItems['firebaseId'] = usr.uid
  }

  const opts: RequestInit = {
    method: 'POST',
    headers: headerItems,
    body: JSON.stringify(body),
  }
  try {
    const response = await fetch(GRAPHQL_ROOT_URL, opts)
    if (response.status === 200) {
      try {
        const data = await response.json()
        if (data?.data) {
          return data.data as T
        }
        return {} as T
      } catch (err) {
        throw new Error('Invalid graphQL response body')
      }
    } else {
      throw new Error(`Invalid response status ${response.status}`)
    }
  } catch (e) {
    console.log(e)
    throw new Error(`Error calling server ${e}`)
  }
}

export async function callPLLApi(
  method: string,
  version: number,
  endpoint: string,
  headerParams?: object,
  body?: BodyInit
) {
  const headers: HeadersInit = {
    'content-type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_API_ACCESS_KEY}` || '',
    authSource: 'wll-web',
    ...headerParams,
  }
  const usr = (await getFirAuth())?.currentUser
  if (usr?.uid) {
    headers['firebaseId'] = usr.uid
  }

  const opts: RequestInit = {
    method,
    headers,
  }
  if (body) opts.body = body
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_ROUTE}/api/v${version}/${endpoint}`,
      opts
    )
    return response
  } catch (e) {
    console.log(e)
    return null
  }
}

export async function callShopGraphQL<T>(query: string): Promise<T> {
  const body = { query: query }
  const opts: RequestInit = {
    method: 'POST',
    headers: {
      'X-Shopify-Storefront-Access-Token': SHOP_STOREFRONT_TOKEN_PUBLIC,
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  }
  const response = await fetch(SHOP_GRAPHQL_URL, opts)
  if (response.status === 200) {
    try {
      const data = await response.json()
      if (data) {
        return data as T
      }
    } catch (err) {
      throw new Error('Invalid graphQL response body')
    }
  }
  throw new Error(`Invalid response status ${response.status}`)
}
