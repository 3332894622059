import { colors, fontSizes } from '../consts'
import { styled } from '@mui/system'

type HeaderProps = {
  justify?: 'left' | 'center' | 'right'
  colorTheme?: 'light' | 'dark'
  textColor?: string
  fontSize?: number
  sideMargins?: number
  vertMargins?: number
}

export const HeadXL = styled('h1')<HeaderProps>(
  ({ justify, fontSize, colorTheme, textColor, sideMargins, vertMargins, theme }) => ({
    fontSize: fontSize ? fontSize : fontSizes.xlarge,
    color: colorTheme === 'dark' ? colors.white : textColor ? textColor : colors.text,
    textAlign: justify || 'left',
    fontWeight: 'bold',
    margin: theme.spacing(vertMargins || 2, sideMargins || 0),
    [theme.breakpoints.down('sm')]: {
      fontSize: fontSize ? fontSize * 0.7 : fontSizes.medium,
      margin: theme.spacing(vertMargins ? vertMargins - 1 : 1, sideMargins ? sideMargins - 1 : 0),
    },
  })
)

export const Head = styled('h1')<HeaderProps>(
  ({ justify, fontSize, colorTheme, textColor, sideMargins, vertMargins, theme }) => ({
    fontSize: fontSize ? fontSize : fontSizes.large,
    color: colorTheme === 'dark' ? colors.white : textColor ? textColor : colors.text,
    textAlign: justify || 'left',
    fontWeight: 'bold',
    margin: theme.spacing(vertMargins || 2, sideMargins || 0),
    [theme.breakpoints.down('sm')]: {
      fontSize: fontSize ? fontSize * 0.7 : fontSizes.medium,
      margin: theme.spacing(vertMargins ? vertMargins - 1 : 1, sideMargins ? sideMargins - 1 : 0),
    },
  })
)

export const Head2 = styled('h2')<HeaderProps>(
  ({ justify, fontSize, colorTheme, textColor, sideMargins, vertMargins, theme }) => ({
    fontSize: fontSize ? fontSize : fontSizes.semiLarge,
    color: colorTheme === 'dark' ? colors.white : textColor ? textColor : colors.text,
    textAlign: justify || 'left',
    fontWeight: 'bold',
    margin: theme.spacing(vertMargins || 1.5, sideMargins || 0),
    [theme.breakpoints.down('sm')]: {
      fontSize: fontSize ? fontSize * 0.7 : fontSizes.medium,
      margin: theme.spacing(vertMargins ? vertMargins - 0.5 : 1, sideMargins ? sideMargins - 0.5 : 0),
    },
  })
)

export const Head3 = styled('h3')<HeaderProps>(
  ({ justify, fontSize, colorTheme, textColor, sideMargins, vertMargins, theme }) => ({
    fontSize: fontSize ? fontSize : fontSizes.medium,
    color: colorTheme === 'dark' ? colors.white : textColor ? textColor : colors.text,
    textAlign: justify || 'left',
    fontWeight: 'bold',
    margin: theme.spacing(vertMargins || 1, sideMargins || 0),
    [theme.breakpoints.down('sm')]: {
      fontSize: fontSize ? fontSize * 0.7 : fontSizes.small,
      margin: theme.spacing(vertMargins ? vertMargins - 0.25 : 1, sideMargins ? sideMargins - 0.25 : 0),
    },
  })
)

export const Desc = styled('p')<HeaderProps>(
  ({ justify, fontSize, colorTheme, textColor, vertMargins, sideMargins, theme }) => ({
    fontSize: fontSize ? fontSize : fontSizes.medium,
    color: colorTheme === 'dark' ? colors.white : textColor ? textColor : colors.helperText,
    textAlign: justify || 'left',
    fontWeight: 500,
    margin: theme.spacing(vertMargins || 1, sideMargins || 0),
    [theme.breakpoints.down('sm')]: {
      fontSize: fontSize ? fontSize * 0.8 : fontSizes.small,
      margin: theme.spacing(1, 0),
    },
  })
)

export const smDesc = styled('p')<HeaderProps>(
  ({ justify, fontSize, colorTheme, textColor, theme }) => ({
    fontSize: fontSize ? fontSize : fontSizes.small,
    color: colorTheme === 'dark' ? colors.white : textColor ? textColor : colors.helperText,
    textAlign: justify || 'left',
    fontWeight: 500,
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down('sm')]: {
      fontSize: fontSize ? fontSize * 0.8 : fontSizes.xSmall,
      margin: theme.spacing(1, 0),
    },
  })
)
