import React, { Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import {
  TEAMS_URL,
  PLAYER_URL,
  SCHEDULE_URL,
  SCORES_URL,
  TICKETS_URL,
  SINGLE_VIDEO_URL,
  SINGLE_GAME_HIGHLIGHTS_URL,
  STANDINGS_URL,
  VIDEOS_BY_TAG_URL,
  PLAYERS_URL,
} from './urls'
import { VideoGroupProvider } from '../Context'
import {
  LeagueContextLayout,
  PlayerProfileContextLayout,
  TeamContextLayout,
} from './ContextLayouts'
import { Error, Loading as PageLoading } from '../Pages'

const Homepage = lazy(() => import('../Pages/Home/Homepage'))
const Login = lazy(() => import('../Pages/Login/Login'))
const Schedule = lazy(() => import('../Pages/Schedule/Schedule'))
const Standings = lazy(() => import('../Pages/Standings/Standings'))
const Team = lazy(() => import('../Pages/Team/Team'))
const Roster = lazy(() => import('../Pages/Roster/Roster'))
const Player = lazy(() => import('../Pages/Player/Player'))
const PlayerStats = lazy(() => import('../Pages/Player/PlayerStats'))
const PlayerMedia = lazy(() => import('../Pages/Player/PlayerMedia'))
const SingleVideo = lazy(() => import('../Pages/Video/SingleVideo/SingleVideo'))
const VideoGroup = lazy(() => import('../Pages/Video/VideoGroup/VideoGroup'))

export const MainRoutes: React.FC<React.PropsWithChildren<unknown>> = () => (
  <Routes>
    <Route
      path="/login"
      element={
        <Suspense fallback={<PageLoading />}>
          <Login />
        </Suspense>
      }
    />
    <Route element={<LeagueContextLayout />}>
      <Route
        path="/"
        element={
          <Suspense fallback={<PageLoading />}>
            <Homepage />
          </Suspense>
        }
      />
      <Route
        path={`${SCHEDULE_URL}`}
        element={
          <Suspense fallback={<PageLoading />}>
            <Schedule />
          </Suspense>
        }
      />
      <Route
        path={`${SCHEDULE_URL}-et`}
        element={
          <Suspense fallback={<PageLoading />}>
            <Schedule />
          </Suspense>
        }
      />
      <Route
        path={`${TICKETS_URL}`}
        element={
          <Suspense fallback={<PageLoading />}>
            <Schedule />
          </Suspense>
        }
      />
      <Route
        path={`${SCORES_URL}`}
        element={
          <Suspense fallback={<PageLoading />}>
            <Schedule />
          </Suspense>
        }
      />
      <Route
        path={`${STANDINGS_URL}`}
        element={
          <Suspense fallback={<PageLoading />}>
            <Standings />
          </Suspense>
        }
      />
      <Route element={<TeamContextLayout />}>
        <Route
          path={`${TEAMS_URL}/:teamName`}
          element={
            <Suspense fallback={<PageLoading />}>
              <Team />
            </Suspense>
          }
        />
        <Route
          path={`${TEAMS_URL}/:teamName/roster`}
          element={
            <Suspense fallback={<PageLoading />}>
              <Roster />
            </Suspense>
          }
        />
      </Route>

      <Route element={<PlayerProfileContextLayout />}>
        <Route
          path={`${PLAYER_URL}/:slug`}
          element={
            <Suspense fallback={<PageLoading />}>
              <Player />
            </Suspense>
          }
        />
        <Route
          path={`${PLAYER_URL}/:slug/profile`}
          element={
            <Suspense fallback={<PageLoading />}>
              <Player />
            </Suspense>
          }
        />
        <Route
          path={`${PLAYER_URL}/:slug/stats`}
          element={
            <Suspense fallback={<PageLoading />}>
              <PlayerStats />
            </Suspense>
          }
        />
        <Route
          path={`${PLAYER_URL}/:slug/media`}
          element={
            <Suspense fallback={<PageLoading />}>
              <PlayerMedia />
            </Suspense>
          }
        />
        <Route
          path={`${PLAYERS_URL}/:slug`}
          element={
            <Suspense fallback={<PageLoading />}>
              <Player />
            </Suspense>
          }
        />
        <Route
          path={`${PLAYERS_URL}/:slug/profile`}
          element={
            <Suspense fallback={<PageLoading />}>
              <Player />
            </Suspense>
          }
        />
        <Route
          path={`${PLAYERS_URL}/:slug/stats`}
          element={
            <Suspense fallback={<PageLoading />}>
              <PlayerStats />
            </Suspense>
          }
        />
        <Route
          path={`${PLAYERS_URL}/:slug/media`}
          element={
            <Suspense fallback={<PageLoading />}>
              <PlayerMedia />
            </Suspense>
          }
        />
      </Route>
    </Route>

    <Route
      path={`${VIDEOS_BY_TAG_URL}/:tag`}
      element={
        <VideoGroupProvider>
          <Suspense fallback={<PageLoading />}>
            <VideoGroup />
          </Suspense>
        </VideoGroupProvider>
      }
    />
    <Route
      path={`${SINGLE_GAME_HIGHLIGHTS_URL}/:eventSlug`}
      element={
        <VideoGroupProvider>
          <Suspense fallback={<PageLoading />}>
            <VideoGroup />
          </Suspense>
        </VideoGroupProvider>
      }
    />
    <Route
      path={`${SINGLE_VIDEO_URL}/:mediaId`}
      element={
        <Suspense fallback={<PageLoading />}>
          <SingleVideo />
        </Suspense>
      }
    />
    <Route path="*" element={<Error errorNo={404} />} />
  </Routes>
)
