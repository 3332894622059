import React, { useEffect, useState } from 'react'
import { useTeamName } from '../utils'
import { getIdByTeamName } from '../../Utils'
import { fetchWLLTeamRoster, PlayerData } from '../../Api'
import { CURRENT_YEAR } from '../../Config/League/league.config'

export const PlayerContext = React.createContext<{
  teamName: string
  isRosterLoading: boolean
  roster: PlayerData[]
  champSeriesRoster: PlayerData[]
  getTeamCaptains: () => PlayerData[]
} | null>(null)

export const PlayerProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const teamName = useTeamName()
  const teamCode = getIdByTeamName(teamName)
  const [isRosterLoading, setIsRosterLoading] = useState(true)
  const [roster, setRoster] = useState<PlayerData[]>([])
  const [champSeriesRoster, setChampSeriesRoster] = useState<PlayerData[]>([])

  useEffect(() => {
    if (teamCode) {
      fetchRostersData(teamCode)
    }
  }, [teamCode])

  const fetchRostersData = async (teamId: string) => {
    try {
      const rosters = await fetchWLLTeamRoster(teamId, CURRENT_YEAR)
      if (!rosters || rosters.length < 1) return
      setRoster(rosters)
      setChampSeriesRoster(rosters)
      setIsRosterLoading(false)
    } catch (e) {
      console.log(e)
      setIsRosterLoading(false)
    }
  }

  const getTeamCaptains = (): PlayerData[] => {
    return roster.filter((p) => p.isCaptain)
  }

  return (
    <PlayerContext.Provider
      value={{
        teamName,
        roster,
        champSeriesRoster,
        isRosterLoading,
        getTeamCaptains,
      }}
    >
      {children}
    </PlayerContext.Provider>
  )
}
