import React from 'react'
import { ModalOverlay } from './styles'

type OverlayProps = {
  type: 'dialog' | 'video' | 'popup' | 'slideup'
  orientation?: 'center' | 'bottom'
  onClick?: () => void
}

export const Overlay: React.FC<React.PropsWithChildren<OverlayProps>> = ({
  type,
  orientation = 'center',
  children,
  onClick,
}) => {
  return (
    <ModalOverlay type={type} orientation={orientation}>
      <div className="closeX" onClick={onClick}>
        X
      </div>
      {children}
    </ModalOverlay>
  )
}
