import {
  PlayerInfoKeys,
} from '../../Constants/player'
import { PlayerData, PlayersOnTeam } from '../../Api'
import { CURRENT_YEAR } from '../../Config/League/league.config'

export const createPlayerUrl = (params: any) => {
  return `/player/${params.slugName}${params.tab ? `/${params.tab}` : ''}`
}

export const sortPlayersByPosition = (
  players: PlayerData[],
  sortBy: PlayerInfoKeys,
  ascDesc: -1 | 1
): PlayerData[] => {
  return players.sort((a, b) => {
    const A = a[sortBy]
    const B = b[sortBy]
    if (sortBy === 'lastName') {
      if (ascDesc < 0) {
        return b.lastName.localeCompare(a.lastName)
      } else {
        return a.lastName.localeCompare(b.lastName)
      }
    }
    const numA = typeof B === 'number' ? B : 0
    const numB = typeof A === 'number' ? A : 0
    if (ascDesc < 0) {
      return numA - numB
    } else {
      return numB - numA
    }
  })
}

export const mapInjuryStatus = (status: string) => {
  if (!status) return ''
  const lowerCase = status.toLowerCase()
  switch (lowerCase) {
    case 'o':
      return 'Out'
    case 'q':
      return 'Questionable'
    case 'ir':
      return 'Injured Reserve'
    case 'na':
      return 'Unavailable to travel'
    case 'utt':
      return 'Unavailable to travel'
    default:
      return ''
  }
}

/**
 * Returns player name formatted as "F. Last"
 */
export const formatPlayerName = (first?: string, last?: string) => {
  if (!first) return last || ''
  let firstInitial = first.charAt(0)
  return `${firstInitial}. ${last}`
}

/**
 * Returns player's years in the league
 */
export const getPlayerExperience = (player: PlayersOnTeam | PlayerData) => {
  return CURRENT_YEAR - player.expFromYear + 1
}
