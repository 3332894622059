import { useState, useRef, useEffect } from 'react'

export const useHoverMenu = (delay = 200) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const handleMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }
    setAnchorEl(event.currentTarget)
  }

  const handleMouseLeave = () => {
    timerRef.current = setTimeout(() => {
      setAnchorEl(null)
    }, delay)
  }

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    }
  }, [])

  return {
    anchorEl,
    setAnchorEl,
    handleMouseEnter,
    handleMouseLeave,
  }
}
