// VIDEO CONFIG //

import { FeedItem } from '../../Api'
import { WLL_INTRO_VID_ID } from '../League/league.config'

//Bightcove account ID
export const PLL_VIDEO_ACCOUNT_ID = process.env.REACT_APP_VIDEO_ACCOUNT_ID
//Brightcove player ID
export const DEFAULT_VIDEO_PLAYER_ID = 'FajRXawD0'
//Brightcove player scripts
export const VIDEO_PLAYER_SCRIPT = `//players.brightcove.net/${PLL_VIDEO_ACCOUNT_ID}/${DEFAULT_VIDEO_PLAYER_ID}_default/index.min.js`
export const VIDEO_PLAYER_SCRIPT_ID = `bc-player-${PLL_VIDEO_ACCOUNT_ID}-${DEFAULT_VIDEO_PLAYER_ID}`

export const HOME_FEATURED_VIDEO: FeedItem = {
  id: 'home-video-callout',
  aspectRatio: '16:9',
  date: '2021-01-01',
  duration: 45,
  source: 'youtube',
  tags: ['WLL', 'Video'],
  type: 'video',
  mediaId: WLL_INTRO_VID_ID,
  previewImage:
    'https://premierlacrosseleague.com/wp-content/uploads/2024/11/WLL_16x9_2.jpg',
}
