import React, { useState } from 'react'
import { teamsDataArray } from '../../../../Constants/team'
import { colors } from '../../../consts'
import { SelectorStyle } from '../../../Layout/Selectors/Selectors.styles'
import type { SelectChangeEvent } from '@mui/material/Select'
import { Select } from '../../../LazyComponents'
import { MenuItem } from '../../../LazyComponents/Menus/Menus'

type TypeSelectProps = {
  current: string | undefined
  onChangeTeam: (favTeam: string) => void
  color?: string
}

/**
 * Select year from allowed range
 */
export const TeamSelect: React.FC<React.PropsWithChildren<TypeSelectProps>> = ({
  current,
  onChangeTeam,
  color = colors.yellow,
}) => {
  const startVal = current
    ? current.charAt(0).toUpperCase() + current.slice(1)
    : 'Archers'
  const [value, setValue] = useState<string>(startVal)

  const onChange = (event: SelectChangeEvent<any>) => {
    const v = event.target.value
    onChangeTeam(v)
    setValue(v)
  }

  const renderValue = (value: any): string | null => {
    //Capitalize first letter
    const v = value.charAt(0).toUpperCase() + value.slice(1)
    let ty = teamsDataArray.find((tm) => tm.fullName === v)?.fullName
    if (!ty) return null
    ty = ty.charAt(0).toUpperCase() + ty.slice(1)
    return ty
  }

  return (
    <SelectorStyle color={color}>
      <Select
        value={value}
        onChange={onChange}
        renderValue={renderValue}
        defaultValue={'Archers'}
      >
        {teamsDataArray.map((ty) => (
          <MenuItem key={ty.officialId} value={ty.fullName}>
            {ty.fullName}
          </MenuItem>
        ))}
      </Select>
    </SelectorStyle>
  )
}
