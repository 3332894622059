import { months, shortDays } from '../Config/League/league.config'
import { timezonesMap } from './timezoneMappings'
/**
 * Convert fractional value 0-1 to percentage representation
 */
export function Percent(value: number): string {
  let pctStr = `${(value * 100).toFixed()}`
  let cleaned = parseFloat(pctStr)
  return `${cleaned}`
}

/**
 * Convert fractional value 0-1 to percentage representation as number
 */
export function PercentNum(value: number): string {
  return `${(value * 100).toFixed()}%`
}

/**
 * Convert string decimal to number, then return percentage representation
 */
export function PercentFromStr(value: string): string {
  let num = parseFloat(value)
  let pctStr = `${(num * 100).toFixed()}`
  return `${pctStr}%`
}

/**
 * Convert fractional value 0-1 to percentage representation with two digits precision
 */
export function Percent2Digits(value: number): string {
  return `${(Math.round(value * 10000) / 100).toFixed(2)}%`
}

/**
 * Convert value with decimal to representation with one digit precision
 */
export function Decimal1Digit(value: number): string {
  return `${Math.round(value * 10) / 10}`
}

/**
 * Convert value with decimal to representation with one digit precision
 */
export function Decimal1DigitNum(value: number): number {
  return Math.round(value * 10) / 10
}

/**
 * Convert value to percent if valueName ends with 'pct' and is numeric type
 */
export function ConvertStatValue<T>(value: T, valueName: string): T | string {
  if (valueName.toLowerCase().endsWith('pct')) {
    if (typeof value === 'number') {
      return Percent(value)
    }
    if (typeof value === 'string') {
      return PercentFromStr(value)
    }

    if (value === null || value === undefined) return '-'
  } else if (valueName.toLowerCase().endsWith('aa') || valueName.endsWith('PG')) {
    if (typeof value === 'number') {
      return Decimal1Digit(value)
    }
    if (value === null || value === undefined) return '-'
  } else if (valueName === 'foRecord') {
    if (value === null || value === undefined) return '-'
    return value
  } else {
    if (value === null || value === undefined) return '0'
  }
  return value
}

/**
 * Convert value to percent if valueName ends with 'pct' and is numeric type
 */
export function ConvertDecimals(value: number, valueName: string): number {
  if (
    valueName.toLowerCase().endsWith('pct') ||
    valueName.toLowerCase().endsWith('aa')
  ) {
    if (typeof value === 'number') {
      // return Percent(value)
      return Decimal1DigitNum(value)
    }
  }
  return value
}

export const concatCollege = (college: string, collegeYear: number) => {
  if (!collegeYear) return college
  const shortYear = collegeYear.toString().slice(-2)
  const collegeAndYear = `${college} '${shortYear}`
  return collegeAndYear
}

/**
 *
 * Converting UTC timestamp to shortened date from timestamp ie June 7
 */
export const getShortDate = (timeStamp: any, includeDay: boolean = true) => {
  const s = new Date(timeStamp * 1000)
  const dayOfWeek = includeDay ? `${getShortDayString(s)}, ` : ''
  const dateString = `${dayOfWeek}${months[s.getMonth()]} ${s.getDate()}`
  return dateString
}

/**
 *
 * Specifically for incomplete Championship weekend Converting UTC timestamp to shortened date from timestamp ie June 7 & adding 2
 */
export const getDayRange = (timeStamp: any, includeDay: boolean = true) => {
  const s = new Date(timeStamp * 1000)
  const dayOfWeek = includeDay ? `${getShortDayString(s)}, ` : ''
  const dateString = `${dayOfWeek}${months[s.getMonth()]} ${s.getDate()} - ${
    s.getDate() + 2
  }`
  return dateString
}

/**
 *
 * Converting UTC timestamp to shortened date from timestamp with year ie June 7, 2021
 */
export const getShortDateWithYear = (timeStamp: any) => {
  const s = new Date(timeStamp * 1000)
  const dateString = `${months[s.getMonth()]} ${s.getDate()}, ${s.getFullYear()}`
  return dateString
}

/**
 *
 * Converting UTC timestamp and returning just the event's day of the month ie 7
 */
export const getDayOfMonth = (timeStamp: any) => {
  const s = new Date(timeStamp * 1000)
  return s.getDate()
}

/**
 *
 * Returning long date from timestamp ie Thursday, May 1 7pm ET
 */
export const timeStampToDate = (timeStamp: any) => {
  const s = new Date(timeStamp * 1000)
  const dateStr = s.toLocaleTimeString('en-US')

  // get just the time
  const tm = dateStr.slice(0, -3)

  // remove extra :00 if exists
  let st = tm.includes(':00') ? tm.split(':00')[0] : tm

  // 3am is the time set for a game that doesn't have a time, return TBD
  if (st.includes(':23')) {
    st = 'TBA'
  }

  //check if am or pm
  const ampm = dateStr.includes('PM') ? 'pm' : 'am'

  const dateAndTime = {
    date: `${shortDays[s.getDay()]}, ${months[s.getMonth()]} ${s.getDate()}`,
    time: `${st}${ampm}`,
  }
  return dateAndTime
}

/**
 *
 * Returning game time in eastern time
 */
export const getGameTime = (timeStamp: any) => {
  const s = new Date(timeStamp * 1000)
  const est = s.toLocaleTimeString('en-US', {
    timeZone: 'America/New_York',
    hour: 'numeric',
    minute: 'numeric',
  })

  // get just the time
  const tm = est.slice(0, -3)

  // remove extra :00 if exists
  const st = tm.includes(':00') ? tm.split(':00')[0] : tm

  // :23 is the time set for a game that doesn't have a time, return TBD
  if (st.includes(':23')) return 'TBA'

  //check if am or pm
  const ampm = est.includes('PM') ? 'pm' : 'am'

  return `${st}${ampm}`
}

/**
 *
 * Returning game time in user's local time
 */
export const getGameTimeLocal = (timeStamp: any) => {
  const s = new Date(timeStamp * 1000)
  const dateStr = s.toLocaleTimeString('en-US')

  // get just the time
  const tm = dateStr.slice(0, -3)

  // remove extra :00 if exists
  const st = tm.includes(':00') ? tm.split(':00')[0] : tm

  // :23 is the time set for a game that doesn't have a time, return TBD
  if (st.includes(':23')) return 'TBA'

  //check if am or pm
  const ampm = dateStr.includes('PM') ? 'pm' : 'am'

  return `${st}${ampm}`
}

export const getYearMonthDay = (timeStamp: any) => {
  const s = new Date(timeStamp * 1000)
  return [s.getFullYear(), s.getMonth() + 1, s.getDate()]
}

/**
 *
 * Converts date from YYYY/MM/DD to Month Day, Year
 */
export const convertYMDDate = (date: string) => {
  const s = new Date(date)
  return `${s.getMonth() + 1}/${s.getDate()}/${s.getFullYear()}`
}

export const calcPercent = (value: number, total: number) => {
  return ((value / total) * 100).toFixed(1)
}

/**
 *
 * Converts a day number to a string.
 */
export const getShortDayString = (s: Date) => {
  return shortDays[s.getDay()]
}

/**
 *
 * Converts date from YYYY/MM/DD to Month Day, Year
 */
export const convertHourMinSec = (duration: number) => {
  // Hours, minutes and seconds
  let hrs = ~~(duration / 3600)
  let mins = ~~((duration % 3600) / 60)
  let secs = ~~duration % 60

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = ''

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '')
  ret += '' + secs
  return ret
}

/**
 * Returns the number ordinal of the number passed in
 */
export const formatNumberOrdinal = (number: number, justOrdinal?: boolean) => {
  let j = number % 10
  let k = number % 100

  if (j === 1 && k !== 11) {
    return justOrdinal ? 'st' : number + 'st'
  }

  if (j === 2 && k !== 12) {
    return justOrdinal ? 'nd' : number + 'nd'
  }

  if (j === 3 && k !== 13) {
    return justOrdinal ? 'rd' : number + 'rd'
  }

  return justOrdinal ? 'th' : number + 'th'
}

/**
 * Returns the timezone the user is currently in
 */
export const getUserTimezone = (): string => {
  const tZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  if(!tZone) return ''
  return timezonesMap[tZone] || tZone
}

/**
 * Decodes a string from URI encoding
 */
export const decodeString = (str?: string | null) => {
  if(!str) return ''
  return decodeURIComponent(str)
}

/**
 * Return abbreviated number based on value
 */
export const abbreviateNumber = (value: number) => {
  let newValue = `${value}`
  if (value >= 1000) {
    let suffixes = ['', 'k', 'm']
    let suffixNum = Math.floor(('' + value).length / 3)
    let shortValue
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum !== 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(3)
      )
      let dotLessShortValue = (shortValue + '').replace(
        /[^a-zA-Z 0-9]+/g,
        ''
      )
      if (dotLessShortValue.length <= 2) {
        break
      }
    }
    if (!shortValue) return
    if (shortValue % 1 !== 0)
      shortValue = shortValue.toFixed(2).toString()
    newValue = shortValue.toString() + suffixes[suffixNum]
  }
  return newValue
}

/**
 * Return the number with added commas if > 999
 */
export const addCommas = (number: number): string => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

