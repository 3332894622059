const SHOP_NAME = 'thewll'
export const SHOP_STOREFRONT_URL = `https://${SHOP_NAME}.myshopify.com`
export const SHOP_API_VERSION = '2024-10'
export const SHOP_STOREFRONT_TOKEN_PUBLIC =
  process.env.REACT_APP_SHOPIFY_STOREFRONT_KEY || ''
export const SHOP_GRAPHQL_URL = `${SHOP_STOREFRONT_URL}/api/${SHOP_API_VERSION}/graphql.json`

export const SHOP_COLLECTIONS: { [key: string]: string } = {
  MAIN: 'gid://shopify/Collection/492463194392',
  WCHA: 'gid://shopify/Collection/492463161624',
  WCAR: 'gid://shopify/Collection/492463096088',
  WPAL: 'gid://shopify/Collection/492463063320',
  WGUA: 'gid://shopify/Collection/492463030552',
}
