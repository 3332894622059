import React, { createContext, ReactNode, useState } from 'react'

export type SlideupProps = {
  component: ReactNode
  onClose: () => void
  title: string
}

type SlideupContextType = {
  openSlideup: (id: string) => void
  closeSlideup: (id: string) => void
  activeSlideup: string | null
}

export const SlideupContext = createContext<SlideupContextType | null>(null)

export const SlideupProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [activeSlideup, setActiveSlideup] = useState<string | null>(null)

  const openSlideup = (id: string) => setActiveSlideup(id)
  const closeSlideup = (id: string) => {
    if (activeSlideup === id) setActiveSlideup(null)
  }

  return (
    <SlideupContext.Provider value={{ openSlideup, closeSlideup, activeSlideup }}>
      {children}
    </SlideupContext.Provider>
  )
}
