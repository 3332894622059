import React from 'react'
import { styled } from '@mui/system'
import { FOOTER_HEIGHT } from './MobileMenuContainer'
import { colors } from '../consts'
import { NavComponent } from './Navbar/NavComponent'

type PageContainerProps = {
  backgroundColor?: string
  sidePadding?: number
  useFooter?: boolean
  sponsor?: boolean
  fullScreen?: boolean
  disableMaxWidth?: boolean
}

export const MainPageStyle = styled('div')<PageContainerProps>(
  ({ backgroundColor, useFooter, sidePadding, disableMaxWidth, theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minHeight: '90vh',
    background: backgroundColor,
    padding: theme.spacing(
      0,
      sidePadding ? sidePadding : 6,
      8,
      sidePadding ? sidePadding : 6
    ),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4, 4, 4),
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0, 4, 0),
      marginBottom: useFooter ? FOOTER_HEIGHT : 0,
      width: '100%',
    },
    '& .inner': {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      width: '100%',
      maxWidth: !disableMaxWidth && 1400,
    },
  })
)

export const PageContainerMain: React.FC<
  React.PropsWithChildren<PageContainerProps>
> = ({ backgroundColor = colors.pageBackground, sidePadding, useFooter, children }) => {
  return (
    <NavComponent>
      <MainPageStyle
        backgroundColor={backgroundColor}
        useFooter={useFooter}
        sidePadding={sidePadding}
      >
        <div className="inner">{children}</div>
      </MainPageStyle>
    </NavComponent>
  )
}
