//Years to have flexible control over different league info
export const WLL_FIRST_YEAR: number = 2025
export const YEAR_MIN = 2025
export const YEAR_MAX = 2025
export const CURRENT_YEAR: number = 2025
export const STANDINGS_YEAR: number = 2025
export const EVENTS_YEAR: number = 2025
export const STATS_YEAR = 2025

//Controls if we show Champ Series specific components
export const isChampSeriesLive = false
//Ability to manual set without knowing events - used in Standings context
export const hasChampSeriesStarted = true

export const WLL_INTRO_VID_ID = '6364616584112'

type CSDateRanges = {
  [key: number]: {
    startTime: number
    endTime: number
  }
}

//For controlling if we show champ series on player profiles
export const champSeriesDateRanges: CSDateRanges = {
  2025: {
    startTime: 1707638400,
    endTime: 1708581600,
  },
}

export const addToCalendarIds: { [key: string]: string } = {
  league: '',
}

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
]
export const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

export const shortDays = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']
