import {
  pllDefaultTeam,
  TEAM_LOGO_IMAGES,
  teamsDataArray,
  champSeriesTeams,
  fullNameTeamStatLabels,
} from '../../Constants'
import { MAIN_PLL_URL, MAIN_WLL_URL } from '../../Config/config'
import type { TeamGQL, EventGQL, TeamStanding, TeamFrontData } from '../../Api'
import { SEASON_SEGMENT_NAMES } from '../seasonHelpers'
import { CURRENT_YEAR } from '../../Config/League/league.config'

export const teamStatCompareList = [
  'scoresPG',
  'saa',
  'scores',
  'onePointGoals',
  'shotPct',
  'shotsPG',
  'faceoffPct',
  'savePct',
]
export const previousMatchupList = [
  'scores',
  'saa',
  'onePointGoals',
  'shotPct',
  'faceoffPct',
  'savePct',
]

export function getIdByTeamName(teamName: string): string {
  const name = teamName.toLowerCase().trim()
  let tm: TeamFrontData | undefined
  if (name.includes('-')) {
    //with location
    tm = teamsDataArray.find((team) =>
      name?.includes(team.fullName.toLowerCase().trim())
    )
  } else if (name.length === 4) {
    //abbreviation ie WGUA
    tm = teamsDataArray.find((team) => team.officialId.toLowerCase() === name)
  } else {
    //team name ie guard
    tm = teamsDataArray.find((team) => team.fullName.toLowerCase().trim() === name)
  }
  if (tm?.officialId) {
    return tm.officialId
  }
  throw new Error(`unknown team ${teamName}`)
}

export function getAdditionalTeamData(teamId: string): TeamFrontData {
  const team = teamsDataArray.find((td) => td.officialId === teamId)
  if (!team) {
    console.log(`unknown team ${teamId}`)
    return pllDefaultTeam
  }
  const result: TeamFrontData = {
    ...team,
    teamBanner: team.teamBanner,
    teamLogo: ``,
    shopImg: ``,
    twitter: `https://twitter.com/${team.twTag}`,
    instagram: `https://www.instagram.com/${team.igTag}`,
  }
  return result
}

export function getFullTeamName(teamId: string): string | undefined {
  const team = teamsDataArray.find((td) => td.officialId === teamId)
  return team?.fullName
}

/**
 * Converts stat field to Human readable full stat name using `fullTeamNameStatLabels`
 */
export function mapTeamStat(stat: string): string {
  return fullNameTeamStatLabels[stat] || ''
}

export const getTeamColor = (teamId: string) => {
  const team = teamsDataArray.find((team) => team.officialId === teamId)
  if (!team) return '#000'
  return team.teamColor
}

export const getTeamColorByFullName = (teamName: string) => {
  const team = teamsDataArray.find(
    (team) => team.fullName.toLowerCase() === teamName.toLowerCase()
  )
  if (!team) return ''
  return team.teamColor
}

export const getTeamLogo = (teamId: string) => {
  const team = teamsDataArray.find((team) => team.officialId === teamId)
  if (!team) return getGeneralTeamIc()
  return team.teamLogo || getGeneralTeamIc()
}

export const getTeamLocation = (teamId: string) => {
  const team = teamsDataArray.find((team) => team.officialId === teamId)
  if (!team) return
  return team.location
}

export const getTeamLocationCode = (teamId: string) => {
  const team = teamsDataArray.find((team) => team.officialId === teamId)
  if (!team) return
  return team.locationCode
}

export const getTeamIc = (teamName: string) => {
  if (!teamName) {
    return getGeneralTeamIc()
  }
  const lowerCase = teamName.toLowerCase()
  if (CURRENT_YEAR < 2024) {
    return `https://${MAIN_PLL_URL}/${lowerCase}_primary_fullcolor`
  }
  if (lowerCase in TEAM_LOGO_IMAGES) {
    return TEAM_LOGO_IMAGES[lowerCase]
  }

  return `https://${MAIN_PLL_URL}/wp-content/uploads/2024/03/shield-bg.png`
}

export function getTeamSeed(teamId: string): number | undefined {
  const team = teamsDataArray.find((td) => td.officialId === teamId)
  if (!team) return
  return
}

export const getGeneralTeamIc = () => {
  return `https://${MAIN_PLL_URL}/wp-content/uploads/2024/03/shield-bg.png`
}

export function getTeamRecord(
  team: TeamGQL,
  segment: string = 'regular'
): string | undefined {
  let wins = 0
  let losses = 0
  let ties = 0
  switch (segment) {
    case SEASON_SEGMENT_NAMES.regular:
      wins = team.teamWins
      losses = team.teamLosses
      ties = team.teamTies
      break
    case SEASON_SEGMENT_NAMES.post:
      wins = team.teamWins + (team.teamWinsPost || 0)
      losses = team.teamLosses + (team.teamLossesPost || 0)
      ties = team.teamTies + (team.teamTiesPost || 0)
      break
    case SEASON_SEGMENT_NAMES.champseries:
      wins = team.champSeries?.teamWins || 0
      losses = team.champSeries?.teamLosses || 0
      ties = team.champSeries?.teamTies || 0
      break
    default:
      break
  }
  return `${wins}-${losses}${ties > 0 ? `-${ties}` : ''}`
}

export const getTeamEvents = (events: EventGQL[], team: string): EventGQL[] => {
  const teamEvents = events.filter(
    (event) => event.homeTeam?.officialId === team || event.awayTeam?.officialId === team
  )
  return teamEvents
}

export const filterChampSeriesTeams = (teams: TeamGQL[], year: number) => {
  let csTeams = champSeriesTeams[year]
  if (!csTeams || csTeams.length < 1) return []
  let teamList = teams.filter((tm) => isChampSeriesTeam(tm, csTeams))
  return teamList
}

export const filterChampSeriesStandings = (teams: TeamStanding[], year: number) => {
  let csTeams = champSeriesTeams[year]
  if (!csTeams || csTeams.length < 1) return []
  let teamList = teams.filter((tm) => isChampSeriesTeam(tm, csTeams))
  return teamList
}

export const isChampSeriesTeam = (
  team: TeamGQL | TeamStanding,
  csTeams: string[]
): boolean => {
  const teamId = 'officialId' in team ? team.officialId : team.teamId
  return csTeams.some((csTeam) => csTeam.toLowerCase() === teamId.toLowerCase())
}

/**
 * Creates the url for teams by passing in data
 */
export const createTeamLink = (team: string): string => {
  return `${window.location.origin ?? MAIN_WLL_URL}/teams/${team}`
}
