import { createTheme } from '@mui/material/styles'

export const globalTheme = () => {
  return createTheme({
    spacing: 4,
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      },
    },
  })
}


export const useTheme = async () => {
  const { default: styles } = await import('@mui/material/styles')
  const { useTheme } = styles
  return useTheme
}